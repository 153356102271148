import {Alert, CircularProgress, Dialog, Popover, Snackbar} from "@mui/material";
import styled from "styled-components";
import {useForm} from "../../../toolbox";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../../../query/userQuery";
import {authAction} from "../../../redux/authReducer";
import {SERVER_ADDRESS} from "../../../index";
import axios from "axios";
import {MyTag} from "../../vehicle/component/MyTagEdit";
import {toNumberPlateFormat, toPhoneFormat} from "../../../toolbox/format";
import {VehicleThumbnail} from "../../vehicle/component/VehicleThumbnail";
import {useNavigate} from "react-router-dom";
import {toastAction} from "../../../redux/toastReducer";
import HelpIcon from "@mui/icons-material/Help";

const MyTagEdit = styled(MyTag)`
  position: relative;
  display: block;
  top: unset;
  left: unset;
  transform: unset;
  width: unset;
`

const Line = styled.div`
  border-bottom: 1px solid #CCCCCC;
  margin-left: 24px;
  margin-right: 24px;
`

const MyThumbnail = styled(VehicleThumbnail)`
  width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  margin-bottom: 2px;
`

const MyVehicleThumbnail = styled(VehicleThumbnail)`
  box-sizing: border-box;
  width: 100%;
  height: 280px;
  border-radius: 0;
  margin: 4px;
  cursor: pointer;
`

export const ProfileEditModal = ({open, onClose, onSuccess}) => {
    return <Dialog open={open} onClose={onClose} PaperProps={{style: {borderRadius: '12px'}}}>
        <ProfileEdit onClose={onClose} onSuccess={onSuccess}/>
    </Dialog>
}

export const ProfileEdit = ({onClose, onSuccess}) => {
    const navigate = useNavigate();
    const [updateUser, {loading}] = useMutation(UPDATE_USER);
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const jwt = useSelector(({auth}) => auth.token);
    const { form, onChange, resetValue } = useForm();
    const dispatch = useDispatch();

    const fileRef = useRef();

    const [uploading, setUploading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [guide, setGuide] = useState(false);
    const guideIcon = useRef();

    useEffect(() => {
        if (userInfo) {
            onChange([
                {id: 'profile_image', value: userInfo?.profile_image},
                {id: 'nickname', value: userInfo?.nickname},
                {id: 'contact', value: userInfo?.contact},
            ])
        }
    }, [userInfo])

    const handleImage = (e) => {
        if (e.target.files.length === 0) {
            return;
        }
        const data = new FormData();
        data.append('files', e.target.files[0]);

        setUploading(true)
        axios.post(SERVER_ADDRESS + '/api/upload', data, {
            onUploadProgress: progressEvent => {

            }
        }).then((res) => {
            setUploading(false);
            setSnackbarOpen(true);
            onChange({id: 'profile_image', value: res.data[0]})
        });
    }

    const save = async () => {
        if (loading || uploading) {
            return;
        }
        const payload = {
            variables: {
                id: tokenInfo.id,
                data: {
                    nickname: form.nickname,
                    profile_image: form?.profile_image?.id ?? null,
                    contact: form.contact ?? '',
                }
            }
        };
        updateUser(payload).then(res => {
            dispatch(authAction.refresh());
            dispatch(toastAction.toast({severity: 'success', 'message': '프로필 정보가 저장되었습니다.'}));
            onClose();
            onSuccess();
        });
    }

    return <_ProfileEdit>
        <div className="content">
            {/*<h4 className="title">내 프로필 수정하기</h4>*/}
            <div className="img-box">
                <div className="image_wrap" onClick={() => fileRef.current.click()}>
                    <img src={form?.profile_image?.url ?? '/layout/char_doori.svg'}></img>
                    <input style={{display: 'none'}} type="file" accept="image/jpeg, image/png" onChange={handleImage} ref={fileRef}/>
                    <div className="edit">
                        <img src="/my/edit.svg" />
                    </div>
                </div>
            </div>
            <FormComponent type="text" id="nickname" title="닉네임" onChange={onChange} form={form} />
            {/*<FormComponent type="text" id="contact" title="오픈연락처" placeholder="https://open.kakao.com/o/abcdefgh" onChange={onChange} form={form} />*/}
            <_FormComponent>
                <div className="title">오픈연락처
                    <HelpIcon ref={guideIcon} fontSize="16px" onMouseEnter={() => setGuide(true)} onMouseLeave={() => setGuide(false)} />
                    <Popover open={guide} anchorEl={guideIcon.current} style={{pointerEvents: "none"}}>
                        <div style={{width: "200px", overflow: "hidden", padding: "8px", lineHeight: "22px"}}>
                            오픈채팅 링크 가이드<br/><br/>
                            1. 카카오톡 실행<br/>
                            2. 채팅 우상단 ‘새로운 채팅’ 클릭(+모양)<br/>
                            3. 오픈채팅 선택<br/>
                            4. 1:1채팅방 선택<br/>
                            5. 채팅방이름입력 후 우상단 ‘완료’ 선택<br/>
                            6. 링크공유 선택해서 URL 복사 후 이곳에 붙여넣기
                        </div>
                    </Popover>
                </div>
                <div className="content">
                    <div className="text-input">
                        <input id="contact" value={form["contact"]} placeholder="https://open.kakao.com/o/abcdefgh" onChange={(e) => onChange({id: e.target.id, value: e.target.value})}/>
                    </div>
                </div>
            </_FormComponent>
            <_FormComponent>
                <div className="title">소개 태그</div>
                <div className="content">
                    <MyTagEdit />
                </div>
            </_FormComponent>
            <div className="btn-box">
                <div className="btn" onClick={save}>
                    {loading? '저장 중 입니다...': '프로필 정보 저장하기'}
                </div>
            </div>
            <Line/>
            <div>
            </div>
        </div>
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} open={uploading}>
            <Alert icon={false} severity="info" >
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <CircularProgress sx={{marginRight: '8px'}} size={14}/>
                    <span>업로드 중 입니다.</span>
                </div>
            </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} autoHideDuration={4000} open={snackbarOpen} onClose={() => setSnackbarOpen(() => false)} >
            <Alert severity="success">업로드가 완료되었습니다.</Alert>
        </Snackbar>
    </_ProfileEdit>
}

const _ProfileEdit = styled.div`
  > .wrap {
    outline: none;
    vertical-align: middle;
    display: table-cell;
  }

  > .content {

    box-sizing: border-box;
    outline: none;
    background: #fff;
    //border-radius: 12px;
    position: relative;
    //overflow: hidden;
    //overflow-y: scroll;

    margin: 0 auto;
    padding: 16px 12px 58px;
    //width: 300px;
    //height: 390px;

    //top: 25%;
    //height: 50%;
    > .title {
      display: block;
      font-size: 22px;
      //border-bottom: 1px solid #303030;
      word-break: break-all;
      line-height: 28px;
      margin-bottom: 10px;
      //padding: 30px 0 14px;
      padding-bottom: 14px;
      padding-top: 10px;
    }


    > .input-box {
      position: relative;
      border-radius: 6px;
      border: 1px solid rgba(212, 216, 229, 0.3);
      background-color: rgba(212, 216, 229, 0.12);
      display: flex;
      align-items: center;
      margin-top: 4px;
      margin-bottom: 4px;
      width: 100%;
      > .input {
        background-color: rgba(0, 0, 0, 0);
        padding: 9px 11px 9px 11px;
        display: block;
        width: 100%;
        height: 36px;
        font-size: 14px;
        line-height: 16px;
        box-sizing: border-box;
        border: none;
      }
    }


    > .img-box {
      display: flex;
      justify-content: center;
      > .image_wrap {
        position: relative;
        cursor: pointer;
        width: 120px;
        height: 120px;
        padding: 14px;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        > .edit {
          background-color: #33CCC1;
          border-radius: 50%;
          border: 2px solid white;
          position: absolute;
          right: 11px;
          bottom: 11px;
          width: 22px;
          height: 22px;
          padding: 6px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }


    > .btn-box {
      margin-top: 12px;
      margin-bottom: 12px;
      //position: absolute;
      //bottom: 0;
      //left: 0;
      //right: 0;
      display: flex;
      text-align: center;
      justify-content: center;
      > .btn {
        padding: 19px 0 18px;
        //border-bottom-left-radius: 12px;
        width: 180px;
        color: white;
        font-size: 18px;
        font-weight: 700;
        //background-color: #959EA0;
        background-color: #33CCC1;
        cursor: pointer;
        border-radius: 4px;
      }
      //> .btn:last-child {
      //  width: 65%;
      //  //border-bottom-right-radius: 12px;
      //  //border-bottom-left-radius: 0;
      //  background-color: #33CCC1;
      //}
    }
  }
`

const _FormComponent = styled.div`
  padding: 6px 12px;
  > .title {
    padding-left: 4px;
    padding-top: 12px;
    padding-bottom: 4px;
    font-size: 16px;
  }

  > .content {

    > .image {
      max-width: 100%;
      max-height: 200px;
      img {
        max-width: 100%;
        max-height: 200px;
        object-fit: contain;
      }
    }
    > .text-input {
      padding: 4px;
      border: 1px solid #ededed;
      input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 18px;
        ::placeholder {
          color: #BBBBBB
        }
      }
      textarea {
        width: 100%;
        border: none;
        outline: none;
        resize: none;
        font-size: 16px;
        ::placeholder {
          color: #BBBBBB
        }
      }
    }
    
    .vehicles {
      display: flex;
      flex-wrap: wrap;
      padding: 12px 0;

      > .vehicle {
        box-sizing: border-box;
        max-width: calc(50% - 8px);
        flex-basis: 140px;
        flex-grow: 1;
        height: 120px;
        margin: 4px 4px 36px 4px;
        text-align: center;
        cursor: pointer;
        position: relative;
      }
    }
    
    > .upload-btn {
      cursor: pointer;
      margin-top: 8px;
      margin-bottom: 8px;
      border-radius: 19px;
      //width: 114px;
      height: 37px;
      line-height: 37px;
      border: 1px solid #ededed;
      text-align: center;
      color: #EEEEEE;
      background-color: #666666;
      font-weight: 500;
    }
  }
`;

const FormComponent = ({id, title, form, onChange, onClick, ...props}) => {
    const InputComponent = () => {
        if (id === 'phone') {
            return <input {...props} id={id} value={form[id]} onChange={(e) => onChange({id: e.target.id, value: toPhoneFormat(e.target.value)})}/>;
        } else if (id === 'numberPlate') {
            return <input {...props} id={id} value={form[id]} onChange={(e) => onChange({id: e.target.id, value: toNumberPlateFormat(e.target.value)})}/>;
        } else if (id === 'address') {
            // return <input {...props} id={id} value={form[id]} onClick={(e) => openAddress((address) => onChange({id: e.target.id, value: address}))} />;
        }
        switch (props.type) {
            case "textarea":
                return <textarea {...props} id={id} value={form[id]} onChange={(e) => onChange({id: e.target.id, value: e.target.value})}/>
        }
        return <input id={id} value={form[id]} {...props} onChange={(e) => onChange({id: e.target.id, value: e.target.value})}/>;
    }

    return <_FormComponent>
        {title && <div className="title">{title}</div>}
        {/*<div className="title">{title}</div>*/}
        <div className="content" onClick={onClick}>
            <div className="text-input">
                {InputComponent()}
                {/*<input id={id} type="text" value={form[id]} onChange={(e) => onChange({id: e.target.id, value: e.target.value})}/>*/}
            </div>
        </div>
    </_FormComponent>
}
