import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../../../query/userQuery";
import {GUEST_TAGS} from "../../../toolbox/guestTag";
import {filter, find, isEmpty} from "lodash";
import {pathAction} from "../../../redux/pathReducer";
import {openAddress, userHasPath} from "../../../toolbox/logic";
import {loadingAction} from "../../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../../index";
import {authAction} from "../../../redux/authReducer";
import {Dialog} from "@mui/material";
import styled from "styled-components";
import {toastAction} from "../../../redux/toastReducer";


export const TagDialog = ({open, onClose}) => {
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(userInfo?.mapMarker ?? true);
    const [selectedTags, setSelectedTags] = useState(userInfo?.introduce ?? '');
    const [toManager, setToManager] = useState(userInfo?.userMemo?.매니저에게한마디 ?? '');
    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        setVisible(userInfo?.mapMarker);
    }, [userInfo?.mapMarker]);

    useEffect(() => {
        if (userInfo?.introduce) {
            let tags = userInfo.introduce.split(',');

            const allTagIds = GUEST_TAGS.reduce((acc, cur) => [...acc, ...cur.tags.map(t => t.id)], []);
            tags = filter(tags, t => allTagIds.includes(t));

            for (let guestTag of GUEST_TAGS) {
                if (!find(guestTag.tags, t => tags.includes(t.id))) {
                    const defaultTag = find(guestTag.tags, t => t.default);
                    tags.push(defaultTag.id);
                }
            }
            setSelectedTags(tags.join(','));
        }
    }, [userInfo?.introduce]);

    useEffect(() => {
        if (userInfo?.userMemo?.매니저에게한마디) {
            setToManager(userInfo.userMemo.매니저에게한마디);
        }
    }, [userInfo?.userMemo?.매니저에게한마디]);

    const callbackAddress = (address, longitude, latitude) => {
        dispatch(pathAction.update({
            address: address,
            longitude: longitude,
            latitude : latitude,
            isGps : false,
        }));
        if (!isEmpty(tokenInfo)) {
            const payload = {
                variables: {
                    id: tokenInfo.id,
                    data: {
                        lng: longitude,
                        lat: latitude,
                        address: address,
                        introduce: getAllTags().join(','),
                        userMemo: {...userInfo?.userMemo, 매니저에게한마디: toManager},
                        mapMarker: visible,
                    }
                }
            };
            save(payload).then(() => {});
        }
    }

    const submit = async () => {
        if (!userHasPath(userInfo)) {
            alert("태그를 설정하려면 태그를 표시할 위치 설정이 필요합니다.")
            openAddress((address) => callbackAddress(address));
        } else {
            const payload = {
                variables: {
                    id: tokenInfo.id,
                    data: {
                        introduce: getAllTags().join(','),
                        mapMarker: visible,
                        userMemo: {...userInfo?.userMemo, 매니저에게한마디: toManager},
                    }
                }
            };
            await save(payload)
        }
        window.fbq('track', 'Schedule');
    }

    const save = async (payload) => {
        dispatch(loadingAction.loading(true));
        await updateUser(payload);

        await axios.post(SERVER_ADDRESS + '/api/used-vehicles/setGuestTags', {}, {});

        // if (userInfo?.mapMarker !== payload?.variables?.data?.mapMarker) {
        //     navigate(0);
        // }
        dispatch(authAction.refresh());
        dispatch(loadingAction.loading(false));
        dispatch(toastAction.toast({severity: 'success', 'message': '작성하신 요청이 이수빈 매칭매니저에게 전달되었습니다. 최대한 빠르게 연락드리겠습니다. 감사합니다.'}));
        onClose();
    }

    const onToggle = (tag) => {
        let tags = [];
        if (selectedTags) {
            tags = selectedTags.split(',');
        }

        const guestTag = find(GUEST_TAGS, gt => find(gt.tags, t => t.id === tag));

        if (tags.includes(tag)) {
            tags = filter(tags, (o) => o !== tag);
            if (guestTag) {
                const defaultTag = find(guestTag.tags, t => t.default);
                if (defaultTag) {
                    if (!find(guestTag.tags, t => tags.includes(t.id))) {
                        tags.push(defaultTag.id);
                    }
                }
            }
        } else {
            if (guestTag) {
                const uniqueTags = filter(guestTag.tags, t => t.unique).map(t => t.id);
                const defaultTag = find(guestTag.tags, t => t.default);

                if (defaultTag) {
                    if (tag === defaultTag.id) {
                        for (let victim of guestTag.tags) {
                            tags = filter(tags, (o) => o !== victim.id);
                        }
                    } else {
                        tags = filter(tags, (o) => o !== defaultTag.id);
                    }
                }

                if (uniqueTags.includes(tag)) {
                    for (let victim of guestTag.tags) {
                        tags = filter(tags, (o) => o !== victim.id);
                    }
                } else {
                    for (let victim of uniqueTags) {
                        tags = filter(tags, (o) => o !== victim);
                    }
                }
            }
            tags.push(tag);
        }
        setSelectedTags(tags.join(','));
        return tags.join(',');
    }

    const getClassName = (tag) => {
        return getAllTags().includes(tag)? 'selected': 'not-selected';
    }

    const getAllTags = () => {
        if (selectedTags) {
            return selectedTags.split(',');
        }
        return [];
    }

    return <Dialog open={open} onClose={onClose} fullWidth={true}>
        <_TagDialog>
            <div className="content">
                <div className="header">
                    <div className="title">
                        <img src="/vehicle/tag.svg"/>
                        <span>차량 추천받기</span>
                    </div>
                    {/*<div className="toggle" onClick={() => setVisible(!visible)}>*/}
                    {/*    <Switch checked={visible} />지도에 표시*/}
                    {/*</div>*/}
                    {/*<div className="right-btn" onClick={onClose}>*/}
                    {/*    닫기*/}
                    {/*</div>*/}
                </div>
                {/*<div className="description">*/}
                {/*    지도에 게스트 태그를 표시하여 내 주변 호스트의 매칭요청과 새로운 차량 알림을 받을 수 있습니다.*/}
                {/*</div>*/}
                {GUEST_TAGS.map((question, idx) =>
                    <div key={`question${idx}`} className="tag-select">
                        <div className="item">
                            <div className="title">
                                {question.title}
                            </div>
                            <div className="tags">
                                {question.tags.map((t) => <div key={t.id} className={getClassName(t.id)} onClick={() => onToggle(t.id)}>{t.tag}</div>)}
                            </div>
                        </div>
                    </div>)}

                <div className="tag-select" style={{marginTop: "8px"}}>
                    <div className="item">
                        <div className="title">
                            매칭매니저에게 자유롭게 한마디 (생략가능)
                        </div>
                        <div className="tags">
                            <input type="text" value={toManager} onChange={(e) => setToManager(e.target.value)}/>
                        </div>
                    </div>
                </div>


                {/*<div className="tag-select">*/}
                {/*    <div className="item">*/}
                {/*        <div className="title">*/}
                {/*        </div>*/}
                {/*        <div className="tags">*/}
                {/*            <MyTagEdit form={form} onChange={onChange} onAdd={onAdd} onDelete={onDelete} getAllTags={getAllTags}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className="action-box">
                <div className="submit" onClick={submit}>차량 추천받기</div>
                <div className="cancel" onClick={onClose}>취소</div>
            </div>
        </_TagDialog>
    </Dialog>
}

const _TagDialog = styled.div`
  user-select: none;
  > .content {
    display: flex;
    gap: 12px;
    flex-direction: column;
    padding: 16px 16px 0;
    > .tag-select {
      :last-child {
        margin-bottom: 0;
      }
      > .item {
        //display: flex;
        > .title {
          //width: 60px;
          //min-width: 60px;
          //padding-top: 10px;
          font-weight: 500;
          font-size: 17px;
          line-height: 130%;
          color: #444444;
        }

        > .tags {
          margin-top: 8px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          //flex-wrap: wrap;
          
          > input {
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #E0E0E0;
            font-size: 14px;
            line-height: 120%;
          }

          > .selected {
            border-radius: 10px;
            padding: 6px 10px;
            line-height: 120%;
            cursor: pointer;
            background: #5ECDC0;
            color: white;
          }
          
          > .not-selected {
            border-radius: 10px;
            padding: 6px 10px;
            line-height: 120%;
            cursor: pointer;
            color: #878787;
            background: #F7F7F7;
            //border: 1px solid #FFFFFF;
          }
        }
      }
    }
    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      //margin-bottom: 12px;
      > .title {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        > img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        > span {

        }
      }

      > .toggle {
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px solid #F7F7F7;
        border-radius: 999px;
        padding-right: 8px;
      }
      
      > .right-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 6px 10px;
        color: #878787;
        background: #F7F7F7;
        border: 1px solid #FFFFFF;
        border-radius: 999px;
      }
    }
    
    > .description {
      margin-top: 4px;
      margin-bottom: 12px;
      word-break: keep-all;
      color: #808080;
    }
  }
  
  > .action-box {
    //width: 260px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    > .submit {
      cursor: pointer;
      background: #5ECDC0;
      border: 1px solid #5ECDC0;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      color: #FFFFFF;
      padding: 12px;
      flex-grow: 1;
      margin-right: 8px;
    }
    
    > .cancel {
      cursor: pointer;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      padding: 12px;
      flex-grow: 1;
      color: #FFFFFF;
      background: #5ECDC0;
      border: 1px solid #5ECDC0;
      //color: #878787;
      //background: #F7F7F7;
      //border: 1px solid #FFFFFF;
    }
  }
`;
