import {useEffect, useState} from "react";
import {FormComponent, RadioComponentSimple} from "../vehicle/VehicleAdd2/FormComponent";
import {commaNum, useForm} from "../../toolbox";
import moment from "moment-timezone";
import {UseTypeDrawer} from "../vehicle/VehicleAdd2/MatchingInfo";
import BottomDrawer from "../vehicle/VehicleAdd2/BottomDrawer";
import {PositiveBtn} from "../vehicle/VehicleAdd2/BottomNavigator";
import {useQuery} from "@apollo/client";
import {GET_DOORI_VEHICLE} from "../../query/vehicleQuery";
import {GET_USER} from "../../query/userQuery";
import {flatEntity} from "../../toolbox/query";
import {get, find, cloneDeep} from "lodash";
import {convertDooricar} from "../../toolbox/format";
import {calcFirstMonthlyCharge} from "../../toolbox/calculate";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {isAdmin} from "../../toolbox/logic";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {store} from "../../redux/store";
import {loadingAction} from "../../redux/loadingReducer";

const ManualMatchPage = () => {
    const {form, onChange, reset} = useForm({
        vehicleId: '',
        guestId: '',
        startDate: '',
        endDate: '',
        useType: '',
        isSocarEvent: false,
        originMonthlyCharge: '',
        monthlyCharge: '',
        firstMonthlyCharge: '',
        deposit: '',
        hostKmCharge: '',
        guestKmCharge: '',
        overChargeKm: '',
        overCharge: '',
        hostDaySelect: '',
        guestDaySelect: '',
        hostTokenAmount: '',
        guestTokenAmount: '',
        tokenPrice: '',
        memo: '',
    });

    const [hostUseTypeOpen, setHostUseTypeOpen] = useState(false);
    const [guestUseTypeOpen, setGuestUseTypeOpen] = useState(false);
    const [vehicleId, setVehicleId] = useState(null);
    const [guestId, setGuestId] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [guest, setGuest] = useState(null);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const navigate = useNavigate();
    const {data: vehicleData} = useQuery(GET_DOORI_VEHICLE, {
        variables: {id: vehicleId},
        skip: !vehicleId,
    });
    const {data: guestData} = useQuery(GET_USER, {
        variables: {id: guestId},
        skip: !guestId
    });

    const onClickFind = (e) => {
        setVehicleId(form.vehicleId);
        setGuestId(form.guestId);
    }

    const onClickSubmit = async (e) => {
        let _form = cloneDeep(form);
        for (let key in _form) {
            if (_form[key] === '0') {
                _form[key] = 0;
            } else if (_form[key] && typeof(_form[key]) === 'string' && Number(_form[key])) {
                _form[key] = Number(_form[key]);
            } else if (_form[key] === '') {
                _form[key] = null;
            }
        }
        console.log(_form);

        console.log({
            vehicleId: Number(form.vehicleId),
            guestId: Number(form.guestId),
            payload: _form,
        });
        store.dispatch(loadingAction.loading(true));
        const res = await axios.post(SERVER_ADDRESS + '/api/contracts/createGuest', {
            vehicleId: Number(form.vehicleId),
            guestId: Number(form.guestId),
            payload: _form,
        });
        store.dispatch(loadingAction.loading(false));

        if (res.data) {
            alert('생성되었습니다. strapi에서 생성된 데이터를 확인해주세요.');
        }
    }

    useEffect(() => {
        if (!isAdmin(tokenInfo?.id)) {
            navigate('/');
        }
    }, [])

    useEffect(() => {
        const payload = flatEntity(get(vehicleData, "vehicle.data", null));
        if (payload) {
            let v = convertDooricar(payload, false, true);
            if (v) {
                setVehicle(v);
            } else {
                alert('차량이 존재하지 않습니다.');
            }
        } else {
        }
    }, [vehicleData]);

    useEffect(() => {
        let payload = flatEntity(get(guestData, "usersPermissionsUser.data", null));
        if (guestId) {
            if (payload) {
                setGuest(payload);
            } else {
                alert('유저가 존재하지 않습니다.');
            }
        }
    }, [guestData]);

    useEffect(() => {
        setVehicleId(null);
        setGuestId(null);
        setVehicle(null);
        setGuest(null);
    }, [form.vehicleId, form.guestId]);

    useEffect(() => {
        if (vehicle) {
            const vehicleId = form.vehicleId;
            const guestId = form.guestId;
            // reset();
            onChange({id: 'vehicleId', value: vehicleId});
            onChange({id: 'guestId', value: guestId});
            onChange({id: 'guestKmCharge', value: vehicle.kmCharge});
            onChange({id: 'hostDaySelect', value: vehicle.daySelect});
            console.log(vehicle);
        } else {

        }
    }, [vehicle]);

    useEffect(() => {
        if (form.startDate && form.isSocarEvent) {
            let startMoment = moment.tz(form.startDate, 'Asia/Seoul');
            let endMoment;
            if (startMoment.date() !== 1) {
                endMoment = startMoment.clone().add(6, 'months').endOf('month');
            } else {
                endMoment = startMoment.clone().add(5, 'months').endOf('month');
            }
            onChange({id: 'endDate', value: endMoment.format('YYYY-MM-DD')});
        }
    }, [form.startDate, form.isSocarEvent]);

    useEffect(() => {
        if (form.useType) {
            const useType = find(vehicle?.useTypes, (u) => u.id === form.useType);
            if (useType) {
                onChange({id: 'originMonthlyCharge', value: useType.price});
            } else {
                alert('호스트가 선택하지 않은 유형입니다. 월요금을 수동 입력해주세요.');
                onChange({id: 'monthlyCharge', value: ''});
                onChange({id: 'deposit', value: ''});
            }

            if (form.useType === 'DAY_SELECT' && useType) {
                onChange({id: 'hostDaySelect', value: vehicle?.daySelect});
            } else {
                onChange({id: 'hostDaySelect', value: ''});
            }

            if (form.useType === 'DAY_SELECT') {
                onChange({id: 'hostTokenAmount', value: 15});
                onChange({id: 'guestTokenAmount', value: 15});
                onChange({id: 'guestKmCharge', value: vehicle.kmCharge});
                onChange({id: 'hostKmCharge', value: 0});
            } else if (form.useType === 'PRIORITY_HOST') {
                onChange({id: 'hostTokenAmount', value: 100});
                onChange({id: 'guestTokenAmount', value: 10});
                onChange({id: 'guestKmCharge', value: vehicle.kmCharge});
                onChange({id: 'hostKmCharge', value: 0});
            } else if (form.useType === 'PRIORITY_GUEST') {
                onChange({id: 'hostTokenAmount', value: 0});
                onChange({id: 'guestTokenAmount', value: 100});
                onChange({id: 'guestKmCharge', value: 0});
                onChange({id: 'hostKmCharge', value: vehicle.kmCharge});
            }
        }
    }, [form.useType, form.isSocarEvent]);

    useEffect(() => {
        let isSocarEvent = form.isSocarEvent;
        let price = Number(form.originMonthlyCharge);
        let deposit = Number(form.originMonthlyCharge) * 3;
        if (isSocarEvent) {
            price = Number(form.originMonthlyCharge) * 0.9;
            isSocarEvent = false;
        }
        let startMoment = moment.tz(form.startDate, 'Asia/Seoul');
        let endMoment = moment.tz(form.endDate, 'Asia/Seoul');
        let guestTxt = [
            `게스트 월이용료 납부일정`,
            '',
            // '최초 결제필요금액',
            // `${startMoment.month() + 1}월 이용료: ${commaNum(firstMonthCharge)}원 (${startMoment.format('YY. M. D.')} ~ ${startMoment.clone().endOf('month').format('YY. M. D.')})`,
            // ...(form.isSocarEvent ? ['첫달할인금액: -100,000원']: []),
            // `${form.isSocarEvent? "선납금": "보증금"} ${commaNum(deposit)}원`,
            // ...(form.isSocarEvent ?
            //     [`합계: ${commaNum(firstMonthCharge + deposit - 100000)}원`]:
            //     [`합계: ${commaNum(firstMonthCharge + deposit)}원`]),
            // '',
        ];

        let hostTxt = [
            `호스트 수익금 적립일정`,
            '',
        ];

        let guestSum = 0;
        let hostSum = 0;

        let startEvent;
        if (startMoment.date() === 1) {
            startEvent = startMoment.clone();
        } else {
            startEvent = startMoment.clone().add(1, 'month').startOf('month');
        }
        let endEvent = startEvent.clone().add(4, 'month').endOf('month');

        let endDuration = startMoment.clone().add(3, 'month').endOf('month');
        if (endMoment.isValid()) {
            endDuration = endMoment.clone();
        }

        let i = startMoment.clone();
        while (i < endDuration) {
            let end = i.clone().endOf('month');
            if (end > endDuration) {
                end = endDuration;
            }

            let charge = calcFirstMonthlyCharge(price, i.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));

            // if (isSocarEvent && (startEvent.format('YYYY-MM-DD') > i.format('YYYY-MM-DD') || endEvent.format('YYYY-MM-DD') < i.format('YYYY-MM-DD'))) {
            //     charge = calcFirstMonthlyCharge(Number(form.originMonthlyCharge), i.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
            // }

            guestTxt = guestTxt.concat([
                `${i.clone().subtract(1, 'month').format('M')}월 결제예정금액`,
                `${i.month() + 1}월 이용료: ${commaNum(charge)}원 (${i.format('YY. M. D.')} ~ ${end.format('YY. M. D.')})`,
            ]);

            if (startMoment.format('YYYY-MM-DD') === i.format('YYYY-MM-DD')) {
                if (form.isSocarEvent) {
                    guestTxt = guestTxt.concat([
                        `보증금: ${commaNum(deposit)}원`,
                        '장기계약할인금액: -100,000원',
                        `합계: ${commaNum(charge + deposit - 100000)}원\n`,
                    ]);
                    guestSum += charge + deposit - 100000;
                } else {
                    onChange({id: 'firstMonthlyCharge', value: charge});
                    guestTxt = guestTxt.concat([
                        `보증금: ${commaNum(deposit)}원`,
                        `합계: ${commaNum(charge + deposit)}원\n`,
                    ]);
                    guestSum += charge + deposit;
                }
            } else {
                guestTxt = guestTxt.concat([
                    `합계: ${commaNum(charge)}원\n`,
                ]);
                guestSum += charge;
            }

            // if (form.isSocarEvent && endEvent.format('YYYY-MM-DD') >= i.format('YYYY-MM-DD') && startEvent.format('YYYY-MM-DD') <= i.format('YYYY-MM-DD')) {
            //     hostTxt = hostTxt.concat([
            //         `${i.month()}월 수익금 적립 예정금액`,
            //         `${i.month() + 1}월 이용료: ${commaNum(charge)}원 (${i.format('YY. M. D.')} ~ ${end.format('YY. M. D.')})`,
            //         `수수료(7%): -${commaNum(charge - (Math.round(charge * 0.93 / 10 ) * 10))}원`,
            //         `선납금분할액: ${commaNum(deposit / 5)}원`,
            //         `수수료(7%): -${commaNum(deposit / 5 - (Math.round(deposit / 5 * 0.93 / 10 ) * 10))}원`,
            //         `합계: ${commaNum((Math.round(charge * 0.93 / 10 ) * 10) + (Math.round(deposit / 5 * 0.93 / 10 ) * 10))}원`,
            //         '',
            //     ]);
            //     hostSum += (Math.round(charge * 0.93 / 10 ) * 10) + (Math.round(deposit / 5 * 0.93 / 10 ) * 10);
            // } else {
            // }

            hostTxt = hostTxt.concat([
                `${i.clone().subtract(1, 'month').format('M')}월 수익금 적립 예정금액`,
                `${i.month() + 1}월 이용료: ${commaNum(charge)}원 (${i.format('YY. M. D.')} ~ ${end.format('YY. M. D.')})`,
                `수수료(7%): -${commaNum(charge - (Math.round(charge * 0.93 / 10 ) * 10))}원`,
                `합계: ${commaNum(Math.round(charge * 0.93 / 10 ) * 10)}원`,
                '',
            ]);
            hostSum += Math.round(charge * 0.93 / 10 ) * 10;
            i = i.add(1, 'month').startOf('month');
        }

        let txt = [
            ...guestTxt,
            ...(isSocarEvent ? [
                `총 이용료: ${commaNum(guestSum)}원`,
            ]: [
                `총 이용료: ${commaNum(guestSum - deposit)}원`,
            ]),
            '\n\n',
            ...hostTxt,
            `총 수익: ${commaNum(hostSum)}원`,
            '',
            `호스트 무료예약권 갯수: ${form.hostTokenAmount == 100? '무제한': `${form.hostTokenAmount}개`}`,
            `게스트 무료예약권 갯수: ${form.guestTokenAmount == 100? '무제한': `${form.guestTokenAmount}개`}`,
            `유료예약권 1개당 비용: ${commaNum(form.tokenPrice)}원`
        ].join('\n');
        onChange({id: 'monthlyCharge', value: price});
        onChange({id: 'deposit', value: deposit});
        onChange({id: 'priceTxt', value: txt});
    }, [form.originMonthlyCharge, form.isSocarEvent, form.startDate, form.endDate, form.tokenPrice, form.hostTokenAmount, form.guestTokenAmount]);

    useEffect(() => {
        let price = Number(form.originMonthlyCharge);
        if (form.isSocarEvent) {
            price = Number(form.originMonthlyCharge) * 0.9;
        }

        let tokenPrice;
        if (form.useType === 'DAY_SELECT') {
            tokenPrice = price / 15;
        } else if (form.useType === 'PRIORITY_HOST') {
            tokenPrice = price / 10;
        } else if (form.useType === 'PRIORITY_GUEST') {
            tokenPrice = price / 10;
        }
        onChange({id: 'tokenPrice', value: Math.round(tokenPrice / 10) * 10});
    }, [form.originMonthlyCharge, form.isSocarEvent, form.useType]);

    console.log(form);

    return <div style={{gap:'8px', display: 'flex', flexDirection: 'column'}}>
        <h1 style={{padding: "12px", marginTop: '20px', marginBottom: '20px'}}>관리자용 매칭 페이지~~</h1>
        <div style={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '0 8px'}}>
            <div style={{lineHeight: '120%', borderRadius: '4px', border: '1px solid black', padding: '4px'}}>
                <p style={{padding: '4px 0'}}>사용방법</p>
                <p>1. 차량ID와 게스트ID를 입력한다음 조회 버튼을 눌러서 대상을 확인합니다.</p>
                <p>2. 나머지 정보를 입력후 생성합니다.</p>
            </div>
            <FormComponent type="tel" id="vehicleId" title="차량ID" form={form} onChange={onChange}/>
            <FormComponent type="tel" id="guestId" title="게스트ID" form={form} onChange={onChange}/>
            <div onClick={onClickFind} style={{padding: '16px', textAlign: 'center', cursor: 'pointer', border: '1px solid black', borderRadius: '4px'}}>조회하기</div>

            <FormComponent type="tel" id="vehicleInfo" title="차량 정보" form={({vehicleInfo: vehicle? `${vehicle.brand} ${vehicle.model} (${vehicle.user.nickname}, ${vehicle.user.phone})`: ''})} onChange={onChange} disabled/>
            <FormComponent type="tel" id="guestInfo" title="게스트 정보" form={({guestInfo: guest? `${guest.nickname}, ${guest.phone}`: ''})} onChange={onChange} disabled/>

            <FormComponent type="date" id="startDate" title="시작일" form={form} onChange={onChange}/>
            <FormComponent type="date" id="endDate" title="종료일" form={form} onChange={onChange}/>
            <RadioComponentSimple id="useType" isColumn={true} form={form} onChange={onChange} values={[
                {id: 'PRIORITY_GUEST', label: `게스트우선형 ${find(vehicle?.useTypes, (u) => u.id === 'PRIORITY_GUEST')? '(활성)':'(비활성)'}`},
                {id: 'PRIORITY_HOST', label: `호스트우선형 ${find(vehicle?.useTypes, (u) => u.id === 'PRIORITY_HOST')? '(활성)':'(비활성)'}`},
                {id: 'DAY_SELECT', label: `사전예약형 ${find(vehicle?.useTypes, (u) => u.id === 'DAY_SELECT')? '(활성)':'(비활성)'}`},
            ]}/>
            <div onClick={() => onChange({id: 'isSocarEvent', value: !form.isSocarEvent})}>
                <input type='checkbox' checked={form.isSocarEvent} onChange={() => onChange({id: 'isSocarEvent', value: !form.isSocarEvent})}/> 6개월 이상 장기 할인 적용 (월요금 10% 할인, 첫달 월요금 -10만)
            </div>
            <FormComponent type="tel" id="originMonthlyCharge" title="정상 월요금" form={form} onChange={onChange} />
            <FormComponent type="tel" id="monthlyCharge" title="적용 월요금" form={form} onChange={onChange} />
            <FormComponent type="tel" id="firstMonthlyCharge" title="첫달 월요금" form={form} onChange={onChange} />
            <FormComponent type="tel" id="deposit" title={form.isSocarEvent?'보증금': '보증금'} form={form} onChange={onChange} />
            <FormComponent type="textarea" id="priceTxt" title="가격 상세" form={form} onChange={onChange} rows={30} disabled/>
            <FormComponent type="tel" id="hostKmCharge" title="호스트 거리당요금" form={form} onChange={onChange} />
            <FormComponent type="tel" id="guestKmCharge" title="게스트 거리당요금" form={form} onChange={onChange} />
            <FormComponent type="tel" id="overChargeKm" title="초과주행거리" form={form} onChange={onChange} />
            <FormComponent type="tel" id="overCharge" title="초과주행요금" form={form} onChange={onChange} />
            <FormComponent type="text" id="hostDaySelect" title="호스트 자동예약요일" form={form} onChange={onChange} onClick={() => setHostUseTypeOpen(true)} />
            <FormComponent type="text" id="guestDaySelect" title="게스트 자동예약요일" form={form} onChange={onChange} onClick={() => setGuestUseTypeOpen(true)} />
            <FormComponent type="tel" id="hostTokenAmount" title="호스트 무료예약권 갯수" form={form} onChange={onChange} />
            <FormComponent type="tel" id="guestTokenAmount" title="게스트 무료예약권 갯수" form={form} onChange={onChange} />
            <FormComponent type="tel" id="tokenPrice" title="유료예약권 1개당 비용" form={form} onChange={onChange} />
            <FormComponent type="textarea" id="memo" title="메모" form={form} onChange={onChange} />

            <div onClick={onClickSubmit} style={{padding: '16px', textAlign: 'center', cursor: 'pointer', border: '1px solid black', borderRadius: '4px'}}>생성하기</div>
        </div>
        <BottomDrawer open={hostUseTypeOpen} onClose={() => setHostUseTypeOpen(false)}>
            <UseTypeDrawer id="hostDaySelect" form={form} onChange={onChange} onClose={() => setHostUseTypeOpen(false)}/>
            <PositiveBtn style={{marginTop: '24px', marginBottom: '14px'}} onClick={() => setHostUseTypeOpen(false)}>확인</PositiveBtn>
        </BottomDrawer>
        <BottomDrawer open={guestUseTypeOpen} onClose={() => setGuestUseTypeOpen(false)}>
            <UseTypeDrawer id="guestDaySelect" form={form} onChange={onChange} onClose={() => setGuestUseTypeOpen(false)}/>
            <PositiveBtn style={{marginTop: '24px', marginBottom: '14px'}} onClick={() => setGuestUseTypeOpen(false)}>확인</PositiveBtn>
        </BottomDrawer>
    </div>
}

export default ManualMatchPage;
