import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {calcDistance} from "../../toolbox";
import {cloneDeep, filter, get, isEmpty, orderBy, sortBy} from "lodash";
import {calcTimeDiff, distanceFormat, getLoginLink} from "../../toolbox/format";
import {useQuery} from "@apollo/client";
import ReactMarkdown from "react-markdown";
import moment from "moment-timezone";
import {GET_DAILY_UPDATES} from "../../query/NotificationQuery";
import {pathSelector} from "../../toolbox/logic";
import remarkGfm from 'remark-gfm'
import {flatEntity} from "../../toolbox/query";

const _NotificationRow = ({className, data, onChange}) => {
    const createdAt = moment.tz(data?.createdAt, 'Asia/Seoul').add(-1, 'day');
    const pathInfo = useSelector(({path}) => path);
    const userInfo = useSelector(({user}) => user.user);
    const path = pathSelector(userInfo, pathInfo);

    let payload = data?.body;
    if (payload && data?.longitude && data?.latitude && path?.latitude && path?.longitude) {
        payload = distanceFormat(calcDistance(data.latitude, data.longitude, path.latitude, path.longitude)) + ' 거리의 ' + payload;
    }

    return <div className={className}>
        <a href={data?.link}>
            <div className="guest">
                <div className="image_wrap">
                    <img src={data?.sender?.profile_image?.url ?? '/layout/char_doori.svg'}></img>
                </div>
                <div className="second_box">
                    <div className="name">
                        <ReactMarkdown remarkPlugins={[remarkGfm]} linkTarget="_blank" unwrapDisallowed={true} disallowedElements={["ul", "li"]} children={payload?.replace(/\n/gi, "&nbsp;\n\n")}/>
                    </div>
                    <div className="time">{calcTimeDiff(createdAt)}</div>
                </div>
            </div>
        </a>
    </div>
}

const NotificationRow = styled(_NotificationRow)`
  //border-radius: 8px;
  padding-bottom: 6px;
  background-color: white;
  border-bottom: 1px solid #DDDDDD;
  position: relative;
  //margin-bottom: 20px;
  &:hover {
    background-color: #EEEEEE;
  }
  &.unread {
    background-color: #E7F3FF;
    &:hover {
      background-color: #D7E3EF;
    }
  }
  
  > .manner {
    position: absolute;
    top: 8px;
    right: 4px;
  }

  .guest {
    cursor: pointer;
    padding-top: 20px;
    padding-right: 20px;
    display: flex;
    > .image_wrap {
      margin-left: 20px;
      margin-right: 12px;
      padding-bottom: 10px;
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    > .second_box {
      width: 100%;
      margin-top: 4px;
      > .name {
        word-break: keep-all;
        padding-right: 62px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
      }

      > .title {
        display: flex;
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 8px;
        > span {
          padding: 4px 6px;
          background-color: gray;
          border-radius: 8px;
          white-space: pre-wrap;
          color: white;
          line-height: 18px;
        }
      }

      > .time {
        text-align: right;
        font-size: 12px;
      }
    }
  }

  > .select {
    display: flex;
    flex-grow: 1;
    //border-top: 1px solid #DDDDDD;
    align-items: center;
    min-height: 40px;
    font-size: 14px;
    font-weight: 600;
    padding-left: 12px;
    padding-right: 12px;

    > div {
      padding: 8px;
      flex-grow: 1;
      flex-basis: 80px;
      text-align: center;
      border-radius: 12px;
      color: white;
      margin: 4px;
    }
    
    > .text {
      color: black;
    }

    > .decline {
      cursor: pointer;
      background-color: gray;
    }

    > .accept {
      cursor: pointer;
      background-color: #007aff;
    }

    > .action {
      cursor: pointer;
    }
  }
`
const _NotificationList = ({className, limit}) => {
    const [dailyUpdates, setDailyUpdates] = useState(null);
    const pathInfo = useSelector(({path}) => path);
    const userInfo = useSelector(({user}) => user.user);
    const path = pathSelector(userInfo, pathInfo);
    const [distanceLimit, setDistanceLimit] = useState(false);

    const {data} = useQuery(GET_DAILY_UPDATES);

    useEffect(() => {
        let payload = cloneDeep(get(data, "dailyUpdates.data"));
        if (payload) {
            payload = payload.map((d) => flatEntity(d));
            for (let dailyUpdate of payload) {
                dailyUpdate.createdDate = moment.tz(dailyUpdate.createdAt, 'Asia/Seoul').format('YYYY-MM-DD');
                let distance = 9999999999;
                if (dailyUpdate?.longitude && dailyUpdate?.latitude && path?.latitude && path?.longitude) {
                    distance = calcDistance(dailyUpdate.latitude, dailyUpdate.longitude, path.latitude, path.longitude);
                }
                dailyUpdate.distance = distance;
            }

            if (distanceLimit) {
                const distanceFilter = (update) => {
                    if (update?.longitude && update?.latitude && path?.latitude && path?.longitude) {
                        return 2 > calcDistance(update.latitude, update.longitude, path.latitude, path.longitude)
                    }
                    return false;
                }
                setDailyUpdates(orderBy(filter(payload, distanceFilter), ['createdDate', 'distance'], ['desc', 'asc']));
            } else {
                setDailyUpdates(orderBy(payload, ['createdDate', 'distance'], ['desc', 'asc']));
            }
        }
    }, [data, distanceLimit, pathInfo, userInfo])

    return <div className={className}>
        <input type={"checkbox"} checked={distanceLimit} onChange={() => setDistanceLimit((prev) => !prev) }/> 2km 이내의 소식만 보기 ({path.address ?? '주소 설정 필요'})
        {dailyUpdates && dailyUpdates.map((n) =>
            <NotificationRow data={n} />
        )}
        {dailyUpdates && dailyUpdates.length === 0 && <div style={{padding: '20px'}}>
            표시할 알림이 없습니다.
        </div>}
    </div>
}

export const DailyUpdateList = styled(_NotificationList)`
  > input {
    margin-left: 20px;
    margin-bottom: 10px;
  }
  //box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
  //background-color: #F5F5F5;
`

const DailyUpdatePage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    // const [distanceLimit, setDistanceLimit] = useState(false);


    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    return <_DailyUpdatePage>
        <h2>내 주변 알림</h2>
        {/*<input type={"checkbox"} checked={distanceLimit} onChange={() => setDistanceLimit((prev) => !prev) }/> 2km 이내의 업데이트만 보기*/}
        <DailyUpdateList />
    </_DailyUpdatePage>
}

const _DailyUpdatePage = styled.div`
  font-family: 'Pretendard',sans-serif;
  padding-left: 10px;
  padding-right: 10px;

  > h2 {
    padding: 16px;
  }
`
const MyDivider = styled.div`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-left: 22px;
  margin-right: 22px;
`

export default DailyUpdatePage;
