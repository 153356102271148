import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {calcDistance, commaNum, parseJwt, useForm} from "../../toolbox";
import {cloneDeep, filter, find, get, isEmpty, reverse, sortBy} from "lodash";
import {pathAction} from "../../redux/pathReducer";
import {distanceFormat, filterVehicles} from "../../toolbox/format";
import {Mannerbox} from "./component/Mannerbox";
import {useWindowSize} from "react-use";
import {VehicleMap} from "./component/VehicleMap";
import {isCollision} from "../../toolbox/calculate";
import {VehicleThumbnail} from "./component/VehicleThumbnail";
import {Dialog, Divider, IconButton} from "@mui/material";
import {EventSwipe} from "../event/component/EventSwipe";
import {ThumbSwipe2} from "./component/ThumbSwipe2";
import {ListVehicle} from "./component/ListVehicle";
import {openAddress, pathSelector} from "../../toolbox/logic";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../../query/userQuery";
import {MB16} from "../../toolbox/UtilComponent";
import CloseIcon from "@mui/icons-material/Close";


const MyThumbnail = styled(VehicleThumbnail)`
  width: 100%;
  min-width: 100%;
  height: 270px;
  margin: 0 0 8px 0;
`

const MyDivider = styled.div`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-left: 22px;
  margin-right: 22px;
  margin-bottom: 10px;
`

const Vehicles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const _SolocarListPage = ({className}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const _vehicles = useSelector(({vehicles}) => vehicles.payload);
    const vehicles = filterVehicles(_vehicles, 'solo');
    const pathInfo = useSelector(({path}) => path);
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [open, setOpen] = useState(false);
    const [updateUser] = useMutation(UPDATE_USER);

    const sortVehicle = (vs) => {
        return reverse(vs);
        // if (path.longitude && path.latitude) {
        //     return sortBy(vs, (v) => calcDistance(v.latitude, v.longitude, path.latitude, path.longitude));
        // }
        // return vs;
    }

    return <div className={className}>
        <MB16/>
        {/*<EventBanner>*/}
        {/*    <EventSwipe images={[{url: '/event/rent_banner2.png', onClick: () => {*/}
        {/*        setOpen(true)*/}
        {/*    }}]} />*/}
        {/*</EventBanner>*/}
        <Vehicles>
            {vehicles && sortVehicle(vehicles).map((v) =>
                // <StyledVehicle key={v.id} vehicle={v}/>
                <ListVehicle key={v.id} vehicle={v} isSolo={true}/>
            ).reduce((acc, x, idx2) => acc === null ? [x] : [acc,
                <MyDivider />,
                x], null)}
        </Vehicles>
        <GuideDialog open={open} onClose={() => setOpen(false)}/>
    </div>
}

const SolocarListPage = styled(_SolocarListPage)`
  font-family: 'Pretendard',sans-serif;
  > .map-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 12px;
    background-color: black;
    color: white;
    cursor: pointer;

    padding: 10px 20px;

    position: fixed;
    bottom: 60px;
    z-index: 50;
    left:50%;
    transform:translateX(-50%);
  }
`

export default SolocarListPage;

const EventBanner = styled.div`
    margin: 0 22px;
`

const GuideDialog = ({open, onClose}) => {
    return <Dialog open={open} onClose={onClose}>
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
        <Guide>
            <p>
                {
`두리렌터카는 두리카에서 제공하는 공식 렌터카입니다.
월단위로 위약금 걱정없이,
다양한 차종을 합리적인 가격으로 이용해보세요!

두리렌터카를 이용하는 두가지 방법

1. 호스트 신청하기
• 게스트와 같이 사용할 주차장을 제공하고 차량 정비 등 전반적인 관리를 책임집니다.
• 게스트로부터 매월 ‘고정비용’과 ‘거리당비용’을 받아 수익을 만들 수 있습니다.
• 매칭없이 혼자 이용하는 것도 가능해요.

2. 게스트 신청하기
• 호스트가 제공하는 차와 주차장을 공유하면서 비용을 지급합니다.
• 원하는 차량에 하트를 클릭해보세요. 지도에 내 마커가 표시되어 매칭요청을 기다릴 수 있습니다.`}
            </p>
        </Guide>
    </Dialog>
}

const Guide = styled.div`
  white-space: pre-wrap;
  word-break: keep-all;
  user-select: none;
  padding: 18px;
  line-height: 150%;
`;
