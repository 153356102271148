import styled, {css} from "styled-components";
import {Drawer} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {calcDistance, commaNum} from "../../../toolbox";
import {filter, find, findIndex, forEach, sortBy} from "lodash";
import {isCollision} from "../../../toolbox/calculate";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {VehicleThumbnail} from "./VehicleThumbnail";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper-bundle.css';
import qs from "query-string";


import SwiperCore, {Navigation} from "swiper";
import {useSelector} from "react-redux";
import {pathSelector} from "../../../toolbox/logic";
import {getResponseTimeTxt, getUseTypeTxt, manNum} from "../../../toolbox/format";
import Header from "../../../layout/Header";
import {Mannerbox} from "./Mannerbox";

SwiperCore.use([Navigation]);

const MyDrawer = styled(Drawer)`
    pointer-events: none;
`

const _ThumbSwiper = ({className, children}) => {
    return <>
        <Swiper
            className={className}
            spaceBetween={10}
            slidesPerView="auto"
        >
            {children.map((element, idx) => {
                return (
                    <SwiperSlide key={idx}>
                        {element}
                    </SwiperSlide>
                )
            })}
        </Swiper>
    </>
}

const _MapSwiper = ({className, children, onChange, boundObjects, selected}) => {
    const navigationPrevRef = React.useRef()
    const navigationNextRef = React.useRef()
    const [mySwiper, setMySwiper] = useState(null);

    useEffect(() => {
        if (mySwiper && selected) {
            const idx = findIndex(boundObjects, (v) => v.mapId === selected.mapId);
            if (idx !== -1) {
                mySwiper.slideTo(Number(idx), 0);
            }
        }
    }, [mySwiper, selected, boundObjects])

    return <>
        {/*<div className="prev-btn" ref={navigationPrevRef}>{"<"}</div>*/}
        {/*<div className="next-btn" ref={navigationNextRef}>{">"}</div>*/}
        <div className={className}>
            <Swiper
                // className={className}
                slidesPerView="1"
                spaceBetween={8}
                onSlideChange={(swiper) => {
                    onChange(swiper.realIndex);
                }}
                // navigation
                // navigation={{
                //     prevEl: navigationPrevRef.current,
                //     nextEl: navigationNextRef.current,
                // }}
                onSwiper={setMySwiper}
                onInit={(swiper) => {
                    // swiper.params.navigation.prevEl = navigationPrevRef.current;
                    // swiper.params.navigation.nextEl = navigationNextRef.current;
                    swiper.navigation.init();
                    swiper.navigation.update();
                }}
            >
                {children.map((element, idx) => {
                    return (
                        <SwiperSlide key={idx}>
                            {element}
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    </>
}

const MapSwiper = styled(_MapSwiper)`
  //width: 70vw;
  //padding-left: 10px;
  //padding-right: 10px;
  width: 100%;
  max-width: 400px;
  //max-width: 450px;
  z-index: 2;
  position: relative;
  overflow: hidden;
  > .swiper {
    width: 92%;
    overflow: visible;

    .swiper-slide {
      overflow: hidden;
    }
    .swiper-wrapper {
      align-items: center;
    }
    .swiper-button-prev {
      //bottom: -40px;
    }
    .swiper-button-next {
      //right: -40px;
    }
    .btn {
      position: absolute;
      left: 0;

      max-width: 60px;
      text-align: center;
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 12px;
      background-color: white;
      padding: 6px;
      font-size: 20px;
      pointer-events: fill;
      cursor: pointer;
      flex-grow: 1;
    }
  }
`

const NewUserPlate = ({user}) => {
    let u = user;
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);

    const getEmoji = () => {
        if (tokenInfo?.id == user.id) {
            return '나';
        } else {
            return '게스트';
        }
    }
    const getTags = () => {
        if (tokenInfo?.id == user.id) {
            if (userInfo?.introduce) {
                return userInfo.introduce.split(',').map((tag) => '#' + tag).join(' ');
            }
            return ''
        } else {
            if (user?.introduce) {
                return user.introduce.split(',').map((tag) => '#' + tag).join(' ');
            }
            return ''
        }
    }

    return <_NewVehiclePlate guestid={true}>
        <div className="top">
            <div className="model">
                {u?.nickname}
                <span className="user-tag">{getEmoji()}</span>
            </div>
            <div className="manner-liter">
                <Mannerbox liter={36}/>
            </div>
        </div>
        <div className="bottom">
            <div className="image-box">
                <NewVehicleThumbnail image={({url: (u?.profile_image?.url? u.profile_image.url: '/layout/char_doori.svg')})} />
            </div>
            <div className="vehicle">
                {/*<div className="user-box">*/}
                {/*    <div className="profile">*/}
                {/*        /!*<div className="--image_wrap">*!/*/}
                {/*        /!*    <img src={u?.profile_image?.url ?? '/layout/char_doori.svg'}></img>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*        <div className="name">*/}
                {/*            {u?.nickname}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="manner-liter">*/}
                {/*        <Mannerbox liter={36}/>*/}
                {/*        <div className="text">매너리터</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="guest-title">{getTags()}</div>
            </div>
        </div>
    </_NewVehiclePlate>
    return <_NewVehiclePlate guestid={true}>
        <div className="image-box">
            <NewVehicleThumbnail image={({url: (u?.profile_image?.url? u.profile_image.url: '/layout/char_doori.svg')})} />
        </div>
        <div className="vehicle">
            {/*<div className="model">*/}
            {/*    {u?.nickname}*/}
            {/*    <span className="user-tag">{getEmoji()}</span>*/}
            {/*</div>*/}
            <div className="title">{getTags()}</div>
            {/*<div className="price">*/}
            {/*    {isRent ? <>*/}
            {/*        월 <span className="number">{manNum(v?.priceSolo)}</span>만원*/}
            {/*    </>: <>*/}
            {/*        월 <span className="number">{manNum(v?.priceDuo)}</span>만원*/}
            {/*    </>}*/}
            {/*    /!*월 <span className="number">{manNum(v?.priceDuo)}</span>만원*!/*/}
            {/*</div>*/}
        </div>
    </_NewVehiclePlate>
}
const NewVehiclePlate = ({vehicle}) => {
    let v = vehicle;
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const m = vehicle?.hostUser;
    // const isGuest = vehicle?.guestId;
    // const isRent = v?.vehicleType === 'solo' && !v?.guestId;
    const isGuest = false;
    const isRent = false;
    let tag = isGuest? '게스트': '호스트';
    if (tokenInfo?.id === m?.id) {
        tag = '나'
    }

    if (vehicle.guestUser) {
        tag = '매칭완료';
    }

    if (v?.hostContract?.useType === "TEMP") {
        tag = '예비호스트';
    }

    const getContent = () => {
        if (isGuest) {
            return v?.description? v?.description: v?.title;
        } else {
            return v?.title? v?.title: v?.description;
        }
    }

    return <_NewVehiclePlate guestid={vehicle?.guestId} matched={vehicle?.guestContract}>
        <div className="top">
            <div className="model">
                <div style={{wordBreak: 'keep-all'}}>
                    {v?.brand} {v?.model}
                </div>
                <div>
                    {tag !== '호스트' && <span className="user-tag">{tag}</span>}
                    <span className="response-time-tag">{getResponseTimeTxt(vehicle?.hostUser?.hostStat)? `평균응답${getResponseTimeTxt(vehicle?.hostUser?.hostStat)}`: '응답시간보통'}</span>
                    {v.hostContract.useTypes && v.hostContract.useTypes.map((u) => <span className="match-tag">{getUseTypeTxt(u.id)}</span>)}
                </div>
                {/*{tag !== '예비호스트' && getUseTypeTxt(v.useType, v.useTypeDetail) && <span className="user-tag">{getUseTypeTxt(v.useType, v.useTypeDetail)}</span>}*/}
            </div>
            {tag === '나' &&
                <Link to={`/vehicle_modify?id=${v.hostContract.id}`}>
                    <div className="right" style={{borderRadius: '4px', border: '1px solid black', padding: '2px 4px'}}>
                        {/*<img src="/my/edit_black.svg"/>*/}
                        정보 수정하기
                    </div>
                </Link>}
        </div>
        <div className="bottom">
            <div className="image-box">
                <NewVehicleThumbnail image={v?.thumbnail} />
            </div>
            <div className="vehicle">
                <div className="user-box">
                    <div className="profile">
                        <div className="--image_wrap">
                            <img src={v?.hostUser?.profile_image?.url ?? '/layout/char_doori.svg'}></img>
                        </div>
                        <div className="name">
                            {v?.hostUser?.nickname}
                        </div>
                    </div>
                    <div className="manner-liter">
                        <Mannerbox liter={36}/>
                    </div>
                </div>
                {(v?.hostContract?.dooriveTitle) && <div className="title">{v.hostContract.dooriveTitle}</div>}
                <div className="price">
                    {/*월 <span className="number">{manNum(v?.price)}</span>만원*/}
                    {v.eventPrice === false ?
                        <span>월 {commaNum(v?.price)}원</span>:
                        <>
                            <span style={{textDecoration: 'line-through'}}>월 {commaNum(v?.price)}원</span><span> 월 {commaNum(v?.price * 0.9)}원</span><br/>
                            <span style={{color: '#2eb0e5', fontSize: '16px'}}>첫 달 {commaNum(v?.price * 0.9 - 100000)}원</span>
                        </>}
                    {/*{isRent ? <>*/}
                    {/*    월 <span className="number">{manNum(v?.priceSolo)}</span>만원*/}
                    {/*</>: <>*/}
                    {/*    월 <span className="number">{manNum(v?.price)}</span>만원*/}
                    {/*</>}*/}
                    {/*월 <span className="number">{manNum(v?.priceDuo)}</span>만원*/}
                </div>
            </div>
        </div>
    </_NewVehiclePlate>
}

const _NewVehiclePlate = styled.div`
  border-radius: 8px;
  background: white;
  padding: 16px;
  pointer-events: fill;
  box-sizing: border-box;
  user-select: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  
  > .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .model {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
    }
    
    .right {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 14px;
      
      img {
        width: 18px;
        height: 18px;
      }
    }
    padding-bottom: 12px;
    border-bottom: 1px solid #F1F1F1;
  }


  .manner-liter {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    color: #878787;
  }
  
  > .bottom {
    padding-top: 12px;
    display: flex;
    > .vehicle {
      padding-left: 14px;
      width: 100%;
      display: flex;
      flex-direction: column;

      > .user-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        > .profile {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 700;
          
          > .--image_wrap {
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
            margin-right: 6px;
            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 999px;
            }
          }
          
          > .name {
          }
        }
      }

      .title {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        color: #AFAFAF;
        height: 32px;
        overflow: hidden;
        word-break: keep-all;
      }
      
      .guest-title {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        color: #AFAFAF;
        height: 100px;
        overflow: hidden;
        word-break: keep-all;
      }

      .price {
        margin-top: auto;
        text-align: right;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        > .number {
          color: #FF6754;
          margin-right: 2px;
        }
      }
    } 
  }
  
  .match-tag {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    padding: 3px 5px;
    margin-left: 6px;
    border-radius: 4px;
    white-space: nowrap;
    color: #F16767;
    background: #FFEAEA;
  }
  
  .response-time-tag {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    padding: 3px 5px;
    margin-left: 6px;
    border-radius: 4px;
    white-space: nowrap;
    color: #4dc361;
    background: #E0FFE0;
  }

  .user-tag {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    padding: 3px 5px;
    margin-left: 6px;
    border-radius: 4px;
    white-space: nowrap;
  }
  ${props => props.guestid ? css`
    .user-tag {
      color: #698AFF;
      background: #ECF1FF;
    }
  `: props.matched ? css`
    .user-tag {
      color: #4dc361;
      background: #E0FFE0;
    }
  `: css`
    .user-tag {
      color: #F16767;
      background: #FFEAEA;
    }
  `}
`

const NewVehicleThumbnail = styled(VehicleThumbnail)`
  border: none;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  margin: 0;
  padding: 0;
  border-radius: 8px;
`

const getVehicleLink = (v) => {
    const mode = v.vehicleType === ''
    const params = {
        // mode: v.vehicleType,
        mode: 'doori',
    }
    return `/vehicle/${v.id}?${qs.stringify(params)}`;
}
const VehicleMarker = ({className, id, v, selected, onClick}) => {
    const link = useRef();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);

    const handleClick = () => {
        if (selected) {
            link.current.click();
        } else {
            onClick();
        }
        // if (selected && !v?.guestContract) {
        //     link.current.click();
        // } else {
        //     onClick();
        // }
    }

    let emoji = "호스트";
    if (v?.guestUser) {
        emoji = "매칭완료";
    } else if (v?.hostUser?.id === tokenInfo?.id) {
        emoji = "나"
    } else if (v?.guestId) {
        emoji = "게스트"
    } else if (v?.hostContract?.useType === 'TEMP') {
        emoji = "예비호스트";
    }

    const getModel = () => {
        if (["기아", "현대", "제네시스"].includes(v?.brand)) {
            return v.model;
        } else {
            if (v.brand) {
                return `${v?.brand} ${v?.model}`;
            }
            return v.model;
        }
    }

    return <div>
        <_VehicleMarker matched={v?.guestContract} guestid={v?.guestId} selected={selected} id={id} onClick={handleClick}>
            <span className="user-tag" selected={selected} guestid={v?.guestId}>{emoji}</span>
            {getModel()}
            {v.eventPrice === false ? <span> {manNum(v.price)}만원 </span>: <>
                <span> {manNum(v.price * 0.9)}만원 </span><span style={{textDecoration: 'line-through'}}>{manNum(v.price)}만원</span>
            </>}
            <Link to={getVehicleLink(v)} ref={link} />
        </_VehicleMarker>
    </div>
}

const NewUserMarker = ({className, id, u, selected, onClick}) => {
    const link = useRef();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const handleClick = () => {
        if (selected) {
            link.current.click();
        } else {
            onClick();
        }
    }

    const getEmoji = () => {
        if (tokenInfo?.id == u.id) {
            return '나';
        } else {
            return '게스트';
        }
    }
    const getContent = () => {
        return u.nickname;
        // if (u.introduce) {
        //     const tags = u.introduce.split(',').map((tag) => '#' + tag);
        //     if (tags.length > 2) {
        //         const overLength = tags.length - 2;
        //         tags.length = 2;
        //         return tags.join(' ') + ` +${overLength}`;
        //     } else {
        //         return tags.join(' ');
        //     }
        // } else {
        //     return u.nickname;
        // }
    }
    return <div>
        <_VehicleMarker guestid={true} selected={selected} id={id} onClick={handleClick}>
            <span className="user-tag" selected={selected} guestid={true}>{getEmoji()}</span>{getContent()}
            <Link to={"/user/" + u.id} ref={link} />
        </_VehicleMarker>
    </div>
}

const _VehicleMarker = styled.div`
  //box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: white;
  color: black;
  padding: 8px;
  border-radius: 4px;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
  white-space: pre;
  //box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, .2), 0px 4px 5px 0px rgba(0, 0, 0, .14), 0px 1px 10px 0px rgba(0, 0, 0, .12);
  cursor: pointer;

  font-weight: 700;
  font-size: 14px;
  line-height: 130%;

  &:hover {
    background: black;
    color: white;
    //border: 1px solid black;
    //background: #195F56;

    //&::before {
    //  border-top: 10px solid #195F56;
    //  border-top: 10px solid white;
    //}
  }

  ${props => props.selected && css`
    background: black;
    color: white;
    //box-shadow: 0 0 0 1px blue inset;
  `}

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid white;
    border-bottom: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;

    ${props => props.selected && css`
      border-top: 10px solid black;
  `}
  }

  //&::after {
  //  content: '';
  //  display: block;
  //  position: absolute;
  //  left: 50%;
  //  transform: translateX(-50%);
  //  bottom: -11px;
  //  width: 12px;
  //  height: 4px;
  //  border-radius: 100%;
  //  z-index: 0;
  //  background: rgba(0, 0, 0, 0.2);
  //}


  > .user-tag {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    padding: 3px 5px;
    margin-right: 6px;
    border-radius: 4px;
  }

  ${props => {
      if (props.guestid) {
          return css`
            > .user-tag {
              color: #698AFF;
              background: #ECF1FF;
            }
            &:hover {
              > .user-tag {
                background: #383D4B;
                color: #7795FF;
              }
            }
          `
      } else if (props.matched) {
        return css`
          > .user-tag {
            color: #4dc361;
            background: #E0FFE0;
          }
          &:hover {
            > .user-tag {
              background: #384B3D;
              color: #8cff9f;
              //color: #7795FF;
            }
          }`
      } else {
          return css`
            > .user-tag {
              color: #F16767;
              background: #FFEAEA;
            }
            &:hover {
              > .user-tag {
                background: #443434;
              }
            }
          `
      }
  }}
  ${props => props.selected && css`
    ${props => props.guestid ? css`
      > .user-tag {
        background: #383D4B;
        color: #7795FF;
      }
    `: props.matched? css`
      > .user-tag {
        background: #384B3D;
        color: #8cff9f;
        //color: #7795FF;
      }
    `: css`
      > .user-tag {
        background: #443434;
      }
    `}
  `}
  
`

const _Login = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  //max-width: 410px;
  //width: calc(100% - 80px);
  word-break: keep-all;
  left:50%;
  transform:translateX(-50%);
  text-align: center;
  position: absolute;
  top: 60px;
  //top: 104px;
  z-index: 3;
  cursor: pointer;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  
  color: white;
  padding: 8px 16px;
  background: #5ECDC0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  justify-content: space-between;
  
  > .tag-view {
    min-width: 210px;
    max-width: 210px;
    width: 210px;
    overflow: hidden;
    text-align: left;

    background: linear-gradient(90deg, #FFFFFF 80%, rgba(255, 255, 255, 0) 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
  > .edit {
    padding: 4px 8px;
    border: 1px solid #FFFFFF;
    border-radius: 999px;
    font-weight: 600;
    font-size: 13px;
    line-height: 130%;
    color: #FFFFFF;
  }
`;

const calcPosition = (positions, newPosition) => {
    let longitude = newPosition.longitude;
    let latitude = newPosition.latitude;
    let offsetX = 0;
    let offsetY = 0;
    while (true) {
        const overlap = find(positions, (p) => p.latitude === latitude && p.longitude === longitude && p.offsetX === offsetX && p.offsetY === offsetY );
        if (overlap) {
            offsetX = offsetX + 1;
            offsetY = offsetY + 1;
        } else {
            return { longitude, latitude, offsetX, offsetY }
        }
    }
}

const _VehicleMap = ({className, open, onClose, vehicles, filteredVehicles, guestTags, setMapBound}) => {
    const mapRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();

    const pathInfo = useSelector(({path}) => path);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    let userInfo = useSelector(({user}) => user.user);

    const [tagOpen, setTagOpen] = useState(false);
    const [kakaoObjects, setKakaoObjects] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [map, setMap] = useState();
    const [selected, setSelected] = useState();
    const [myMarker, setMyMarker] = useState();
    const [boundObjects, setBoundObjects] = useState([]);
    // const [mapUsers, setMapUsers] = useState(null);
    const path = pathSelector(userInfo, pathInfo);

    useEffect(() => {
        initMap();
    }, [open]);

    useEffect(() => {
        if (kakaoObjects.length) {
            onChangeMapBounds();
        }
    }, [kakaoObjects])

    // useEffect(() => {
    //     if (map) {
    //         const vehicle = find(vehicles, (v) => (tokenInfo?.id == v.hostUser.id))
    //         if (!vehicle && userInfo?.lng && userInfo?.lat && userInfo?.mapMarker) {
    //             if (myMarker) {
    //                 myMarker.setPosition(new window.kakao.maps.LatLng(userInfo.lat, userInfo.lng));
    //                 myMarker.setVisible(true);
    //             } else {
    //                 let markerRef = document.getElementById(`u${tokenInfo?.id}`);
    //                 if (markerRef) {
    //                     let custom = new window.kakao.maps.CustomOverlay({
    //                         zIndex: 1,
    //                         position: new window.kakao.maps.LatLng(userInfo.lat, userInfo.lng),
    //                         content: markerRef,
    //                     });
    //                     custom.setMap(map);
    //                     setMyMarker(custom);
    //                     const newKakaoObj = kakaoObjects.splice(0);
    //                     newKakaoObj.push(custom);
    //                     setKakaoObjects(newKakaoObj);
    //                 }
    //             }
    //         } else {
    //             if (myMarker) {
    //                 myMarker.setPosition(new window.kakao.maps.LatLng(37, 127));
    //                 myMarker.setVisible(false);
    //             } else {
    //                 let markerRef = document.getElementById(`u${tokenInfo?.id}`);
    //                 if (markerRef) {
    //                     let custom = new window.kakao.maps.CustomOverlay({
    //                         zIndex: 1,
    //                         position: new window.kakao.maps.LatLng(37, 127),
    //                         content: markerRef,
    //                     });
    //                     custom.setMap(map);
    //                     custom.setVisible(false);
    //                     setMyMarker(custom);
    //                     const newKakaoObj = kakaoObjects.splice(0);
    //                     newKakaoObj.push(custom);
    //                     setKakaoObjects(newKakaoObj);
    //                 }
    //             }
    //         }
    //     }
    // }, [map, userInfo?.lng, userInfo?.lat, userInfo?.introduce]);

    // useEffect(() => {
    //     if (mapUsers) {
    //         // refetch().then(() => {});
    //     }
    // }, [userInfo?.introduce, userInfo?.lng, userInfo?.lat])

    const onFocus = (objectId) => {
        forEach(kakaoObjects, (k) => {
            try {
                let kId = k.getContent().id;
                let zIndex = 1;
                if (kId === objectId) {
                    zIndex = 3;
                } else if (kId.startsWith('v')) {
                    zIndex = 2;
                }
                k.setZIndex(zIndex);
            } catch (e) {
                console.log(e);
            }
        });
    }

    useEffect(() => {
        if (selected) {
            if (window.history.replaceState) {
                window.history.replaceState(null, null, '#' + selected.mapId ?? selected.id);
            }
        }
    }, [selected]);

    const moveToCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let lat = position.coords.latitude;
                let lng = position.coords.longitude;
                let latlng = new window.kakao.maps.LatLng(lat, lng);
                map.panTo(latlng);
            }, function (error) {
                console.error(error);
            }, {
                enableHighAccuracy: false,
                maximumAge: 0,
                timeout: Infinity,
            });
        } else {
            console.log('GPS를 지원하지 않습니다: 검색 또는 화면을 움직여서 위치를 찾으십시오');
        }
    };

    // useEffect(() => {
    //     if (map && loaded) {
    //         onChangeMapBounds();
    //     }
    // }, [vehicles, kakaoObjects, map]);

    useEffect(() => {
        if (location.hash && map && vehicles.length > 0 && !loaded) {
            setLoaded(true);
            const vehicle = find(vehicles, (v) => v?.mapId === location.hash.slice(1));
            if (vehicle) {
                let latlng = new window.kakao.maps.LatLng(vehicle.latitude, vehicle.longitude);
                map.panTo(latlng);
                onChangeMapBounds();
                onFocus(vehicle.mapId);
                setSelected(vehicle);
            }

            const user = find(guestTags, (u) => u?.mapId === location.hash.slice(1));
            if (user) {
                let latlng = new window.kakao.maps.LatLng(user.latitude, user.longitude);
                map.panTo(latlng);
                onChangeMapBounds();
                onFocus(user.mapId);
                setSelected(user);
            }
        }
    }, [vehicles, map, guestTags]);

    const getBoundCar = (mapObjects, bounds) => {
        return filter(mapObjects, (v) => isCollision(bounds, v));
    }

    const onChangeMapBounds = () => {
        const bounds = map && map.getBounds();
        const center = map && map.getCenter();
        if (!bounds) {
            return;
        }
        onClickMap();
        return;
        // setMapBound(bounds);

        const boundVehicles = getBoundCar(vehicles, bounds);
        let result = boundVehicles.concat([]);

        result = result.concat(getBoundCar(filteredVehicles, bounds));

        if (guestTags) {
            result = result.concat(getBoundCar(guestTags, bounds));
        }

        const vehicle = find(vehicles, (v) => (tokenInfo?.id == v.hostUser.id))
        if (vehicle) {
            result = result.filter((v) => v.mapId != `u${tokenInfo?.id}`)
        }

        result = sortBy(result, (v) => calcDistance(center.getLat(), center.getLng(), v.latitude, v.longitude))
        if (!selected || !find(result, (v) => selected?.mapId === v.mapId)) {
            if (result.length > 0) {
                // onFocus(result[0].mapId);
                // setSelected(result[0]);
            } else {
                setSelected(undefined);
            }
        }

        if (result.length) {
            setBoundObjects(result);
        } else {
            setBoundObjects([]);
            setSelected(undefined);
        }
        // else {
        //     const solo = filterVehicles(allVehicles, 'solo');
        //     setBoundObjects(filterVehicles(allVehicles, 'solo'));
        //     setSelected(solo[0]);
        // }
    }

    const initMap = () => {
        if (!mapRef.current || map || !window?.kakao) {
            return;
        }
        const kakao = window.kakao;
        const mapContainer = mapRef.current;
        const defaultLatlng = new kakao.maps.LatLng(path?.latitude ?? 37.4923585043421, path?.longitude ?? 127.030707488212);
        const mapOption = {
            center: defaultLatlng,
            level: 5,
        };

        const overlays = [];
        const points = [];
        vehicles.forEach((v) => {
            let carRef = document.getElementById(v.mapId);
            if (carRef) {
                const point = calcPosition(points, v);
                let custom = new kakao.maps.CustomOverlay({
                    zIndex: 2,
                    position: new kakao.maps.LatLng(point.latitude, point.longitude),
                    content: carRef,
                    // yAnchor: 1.2,
                    yAnchor: 1.2 + point.offsetY * 0.8,
                    // xAnchor: 0.5 + point.offsetX * 0.2,
                });
                overlays.push(custom);
                points.push(point);
            }
        });

        filteredVehicles.forEach((v) => {
            let carRef = document.getElementById(v.mapId);
            if (carRef) {
                const point = calcPosition(points, v);
                let custom = new kakao.maps.CustomOverlay({
                    zIndex: 2,
                    position: new kakao.maps.LatLng(point.latitude, point.longitude),
                    content: carRef,
                    // yAnchor: 1.2,
                    yAnchor: 1.2 + point.offsetY * 0.8,
                    // xAnchor: 0.5 + point.offsetX * 0.2,
                });
                overlays.push(custom);
                points.push(point);
            }
        });

        let users = guestTags;

        const vehicle = find(vehicles, (v) => (tokenInfo?.id == v.hostUser.id))
        if (vehicle) {
            users = users.filter((v) => v.mapId != `u${tokenInfo?.id}`);
        }

        users.forEach((u) => {
            let userRef = document.getElementById(u.mapId);
            if (userRef) {
                const point = calcPosition(points, u);
                let custom = new window.kakao.maps.CustomOverlay({
                    zIndex: 1,
                    position: new window.kakao.maps.LatLng(u.lat, u.lng),
                    content: userRef,
                    yAnchor: 1.2 + point.offsetY * 0.8,
                });
                overlays.push(custom);
                points.push(point);
            }
        });

        let m = new kakao.maps.Map(mapContainer, mapOption);
        setMap(m);

        var zoomControl = new kakao.maps.ZoomControl();
        m.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

        for (let overlay of overlays) {
            overlay.setMap(m);
        }

        const newKakaoObj = kakaoObjects.splice(0);
        setKakaoObjects(newKakaoObj.concat(overlays));
    }

    useEffect(() => {
        if (!map) {
            return;
        }
        const kakao = window.kakao;
        map && kakao.maps.event.addListener(map, 'center_changed', onChangeMapBounds);
        return () => {
            map && kakao.maps.event.removeListener(map, 'center_changed', onChangeMapBounds);
        };
    }, [map, onChangeMapBounds]);

    const onClickMap = () => {
        setSelected(null);
    }

    useEffect(() => {
        if (!map) {
            return;
        }
        const kakao = window.kakao;
        map && kakao.maps.event.addListener(map, 'click', onClickMap);
        return () => {
            map && kakao.maps.event.removeListener(map, 'click', onClickMap);
        };
    }, [map]);

    return <MyDrawer open={open} anchor="bottom" hideBackdrop={true} ModalProps={{keepMounted: true}}>
        <div className={className}>
            <Header onLogo={() => onClose()} />
            <div className="map" ref={mapRef}/>
            <a onClick={moveToCurrentLocation}>
                <MoveToCurrent />
            </a>


            {/*{!isEmpty(tokenInfo) ?*/}
            {/*    <_Login onClick={() => {*/}
            {/*        setTagOpen(true);*/}
            {/*        window.fbq('trackCustom', 'LeeSubin');*/}
            {/*    }}>*/}
            {/*        {userInfo?.introduce ?*/}
            {/*            <>*/}
            {/*                <div className="tag-view">*/}
            {/*                    {userInfo.introduce.split(',').map(tag => '#' + tag).reduce((prev, curr) => prev + " " + curr)}*/}
            {/*                </div>*/}
            {/*                <div className="edit">편집</div>*/}
            {/*            </>*/}
            {/*            :<>*/}
            {/*            안녕하세요, 매칭매니저 이수빈입니다.<br/>제가 추천하는 차량을 확인해보세요!*/}
            {/*            </>}*/}
            {/*    </_Login>:*/}
            {/*    <_Login onClick={() => {*/}
            {/*        const to = getLoginLink(location);*/}
            {/*        navigate(to);*/}
            {/*    }}>카카오 로그인하고 빠른 매칭하기</_Login>}*/}



            <div className="bottom-box">
                <div className="close-btn" onClick={onClose}><span>내 주차장에 두고 혼자 탈 수 있는 차량?</span>
                    {/*<FormatListBulletedIcon style={{width:'22px', height: '22px', marginLeft: '4px'}}/>*/}
                </div>
                {/*<div className="close-btn" onClick={() => navigate('/rent')}>주변에 차가 없다면?</div>*/}
            </div>
            {selected &&
                <div className="selected-wrap">
                    {selected.type === 'user' && <Link style={{flexGrow: '1', maxWidth:'450px', margin: '0 16px'}} key={selected?.mapId} to={"/user/" + selected.id}>
                        <NewUserPlate user={selected} />
                    </Link>}
                    {selected.type === 'vehicle' && <Link style={{flexGrow: '1', maxWidth:'450px', margin: '0 16px'}} key={selected?.mapId ?? selected?.id} to={getVehicleLink(selected)}>
                        <NewVehiclePlate vehicle={selected} />
                    </Link>}
                    {selected.type === 'matched' && <Link style={{flexGrow: '1', maxWidth:'450px', margin: '0 16px'}} key={selected?.mapId ?? selected?.id} to={getVehicleLink(selected)}>
                        <NewVehiclePlate vehicle={selected} />
                    </Link>}
                    {/*{selected.type === 'matched' && <div style={{flexGrow: '1', maxWidth:'450px', margin: '0 16px'}} key={selected?.mapId}>*/}
                    {/*    <NewVehiclePlate key={selected?.mapId ?? selected?.id} vehicle={selected} />*/}
                    {/*</div>}*/}
                </div>}
            {/*{selected &&*/}
            {/*    <div className="selected-wrap">*/}
            {/*        <MapSwiper boundObjects={boundObjects} selected={selected} onChange={(idx) => {*/}
            {/*            if (boundObjects[idx].mapId) {*/}
            {/*                onFocus(boundObjects[idx].mapId);*/}
            {/*                setSelected(boundObjects[idx])*/}
            {/*            }*/}
            {/*        }}>*/}
            {/*            {boundObjects.map((v) => {*/}
            {/*                if (v.type === 'user') {*/}
            {/*                    return <Link key={v?.mapId} to={"/user/" + v.id}>*/}
            {/*                        <NewUserPlate user={v} />*/}
            {/*                    </Link>;*/}
            {/*                } else if (v.type === 'vehicle') {*/}
            {/*                    return <Link key={v?.mapId ?? v?.id} to={getVehicleLink(v)}>*/}
            {/*                        <NewVehiclePlate vehicle={v} />*/}
            {/*                    </Link>;*/}
            {/*                } else if (v.type === 'matched') {*/}
            {/*                    return <NewVehiclePlate key={v?.mapId ?? v?.id} vehicle={v} />*/}
            {/*                }*/}
            {/*            })}*/}
            {/*        </MapSwiper>*/}
            {/*    </div>}*/}
            {vehicles && vehicles.map((v) =>
                <VehicleMarker id={v.mapId} key={v.mapId} v={v} selected={selected?.mapId === v.mapId}
                               onClick={() => {
                                   onFocus(v.mapId);
                                   setSelected(v);
                               }}/>)}
            {filteredVehicles && filteredVehicles.map((v) =>
                <VehicleMarker id={v.mapId} key={v.mapId} v={v} selected={selected?.mapId === v.mapId}
                               onClick={() => {
                                   onFocus(v.mapId);
                                   setSelected(v);
                               }}/>)}
            {guestTags && guestTags.map((u) => {
                return <NewUserMarker id={u.mapId} key={u.mapId} u={u} selected={selected?.mapId === u.mapId} onClick={() => setSelected(u)} />;
            })}
            {/*{userInfo && <NewUserMarker my={true} id={`u${userInfo.id}`} key={`u${userInfo.id}`} u={userInfo} selected={selected?.mapId === `u${userInfo.id}`} onClick={() => setSelected({...userInfo, mapId: `u${userInfo.id}`})} />}*/}
            {/*{((userInfo?.lng && userInfo?.lat && userInfo?.introduce) || myMarker) && <UserMarker my={true} id={userInfo?.id} m={userInfo} selected={selected?.id === userInfo?.id} onClick={() => setSelected(userInfo)} />}*/}
        </div>
        {/*<TagDialog open={tagOpen} onClose={() => setTagOpen(false)} />*/}
    </MyDrawer>
}

export const VehicleMap = styled(_VehicleMap)`
  pointer-events: fill;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  > .map {
    box-shadow: 0 -6px 6px -3px rgba(0, 0, 0, 0.07);
    //box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.2);
    width: 100%;
    height: calc(100% - 50px)
  }


  .prev-btn {
    background-color: white;
    padding: 14px;
    position: absolute;
    left: calc(max(50vw - 300px, 5px));
    z-index: 20;
    cursor: pointer;
    pointer-events: fill;
    border-radius: 12px;
    border: 1px solid black;
    bottom: -45px;
  }
  .next-btn {
    background-color: white;
    padding: 14px;
    position: absolute;
    right: calc(max(50vw - 300px, 5px));
    z-index: 20;
    cursor: pointer;
    pointer-events: fill;
    border-radius: 12px;
    border: 1px solid black;
    bottom: -45px;
  }
  
  .selected-wrap {
    max-height: 200px;
    pointer-events: none;
    //z-index: 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 88px;
    //bottom: 10px;
    z-index: 50;
    left:50%;
    transform:translateX(-50%);

    .swiper-button-disabled {
      display: none;
    }

    //.selected {
    //  width: 100%;
    //  //width: 70vw;
    //  //min-width: 70vw;
    //  border-radius: 12px;
    //  background-color: white;
    //  cursor: pointer;
    //  padding-top: 10px;
    //  padding-bottom: 10px;
    //  pointer-events: fill;
    //
    //  .title {
    //    padding: 2px 20px;
    //    font-size: 16px;
    //  }
    //  .desc {
    //    font-size: 14px;
    //    color: #555555;
    //    padding: 0 20px;
    //  }
    //  .address {
    //    font-size: 14px;
    //    padding: 4px 20px;
    //    margin-bottom: 2px;
    //  }
    //  .talk {
    //    margin: 8px 8px 0 8px;
    //    padding: 4px 10px;
    //    word-break: break-all;
    //    font-size: 14px;
    //    line-height: 120%;
    //    color: #444444;
    //    border-radius: 8px;
    //    background-color: #EEEEEE;
    //  }
    //}
  }
  
  > .bottom-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    //bottom: 28px;
    top: 60px;
    z-index: 50;
    left:50%;
    transform:translateX(-50%);

    > .close-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      background: #5ECDC0;
      //background: black;
      //border: 1px solid white;
      color: white;
      cursor: pointer;
      padding: 11px 16px;
      white-space: nowrap;
      margin-right: 10px;
    }

    > :last-child {
      margin-right: 0;
    }
  }
`

const MoveToCurrent = styled.span`
  position: absolute;
  top: 242px;
  right: 1px;
  width: 38px;
  height: 38px;
  z-index: 2;
  background-image: url("/map/current_location.svg");
  &:active {
    background-image: url("/map/current_location_mouseover.svg");
  }
`
