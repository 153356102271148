import styled from "styled-components";
import {GrDocumentText} from "react-icons/gr";
import {commaNum} from "../../../toolbox";
import {calcDooriTax} from "../../../toolbox/calculate";

const ContractInfoSection = ({vehicle}) => {
    return <_ContractInfoSection>
        <p>
            {vehicle.useType === 'TEMP' && <>
                <p>예비호스트 등록시 자동입력된 값으로, 매칭 조율 과정에서 변동될 수 있습니다.</p><br/>
            </>}
            게스트가 주행한 거리만큼 호스트에게 지급하는 금액입니다. 대신, 호스트는 본인의 계산으로 연료를 항상 충분한 상태로 유지하여 게스트의 운행에 차질이 없도록 해야합니다.<br/>
            단, <span style={{textDecoration: 'underline'}}>‘게스트우선형’의 경우 반대로 호스트가 게스트에게 지급</span>하며, 게스트는 본인의 계산으로 연료를 항상 충분한 상태로 유지하여야합니다.

            {/*{!vehicle.useTypes?.length &&*/}
            {/*<>*/}
            {/*    월고정비용: {commaNum(vehicle.priceDuo)}원 / 월<br/>*/}
            {/*    거리당비용: {commaNum(vehicle.kmCharge)}원 / km<br/>*/}
            {/*</>}*/}

            {/*{calcDooriTax(vehicle) && <>*/}
            {/*    자동차세분담비용: {commaNum(calcDooriTax(vehicle))}원 / 월<br/>*/}
            {/*</>}*/}

            {/*보험료분담비용: {vehicle.priceInsurance ? commaNum(vehicle.priceInsurance)+'원': '매칭 시 확정'} / 월<br/>*/}
            {/*보증금: 월고정비용의 3배*/}

            {/*{commaNum(vehicle.priceDuo * 3)}원<br/>*/}
            {/*약정기간: 1개월 (미해지시 1개월씩 자동연장)*/}
        </p>

        {/*<p className="light">*/}
        {/*    1) 월고정비용: 게스트가 공유의 대가로 매월 고정적으로 납부하는 금액으로, 차량의 시세와 감가상각을 고려하여 계산됩니다.<br/><br/>*/}

        {/*    2) 거리당비용: 게스트가 주행한 거리만큼 발생하는 금액으로, 주유, 오일교환 등 소모품교체, 정비, 세차 등 다양한 유지비가 모두 포함되어 있습니다.<br/>*/}
        {/*    ※ 게스트는 호스트에게 거리당비용을 납부하는 대신, 주유, 소모품교체, 정비, 세차 등 비용을 따로 부담하지 않습니다. (차량마다 제공되는 ‘두리케어카드’로 무상결제 가능)<br/><br/>*/}

        {/*    3) 자동차세분담비용: 게스트와 호스트는 자동차세를 절반씩 분담합니다. (연간 총 자동차세를 12개월로 나누어 환산한 금액)<br/><br/>*/}

        {/*    4) 보험료분담비용: 게스트와 호스트는 보험료를 절반씩 분담합니다. (연간 총 보험료를 12개월로 나누어 환산한 금액) 게스트의 연령에 따라 보험료가 달라지므로, 매월 분담할 보험료 액수는 호스트에게 매칭 요청 시 확인할 수 있습니다.<br/><br/>*/}
        {/*    5) 보증금: 차량과 차키의 반납이 완료되고 1% 소유권지분등기가 호스트에게 원상복구된 후 게스트에게 반환됩니다.*/}
        {/*</p>*/}

        {/*<a href={""} target="_blank">*/}
        {/*    <GrDocumentText size={16} /> 자세한 계약 정보 확인하러가기*/}
        {/*</a>*/}
    </_ContractInfoSection>
}

export const TaxInfoSection = ({}) => {
    return <_ContractInfoSection>
        <p>
            {"<보험조건>"}<br/>
            - 대인: 무제한 (한도)<br/>
            - 대물: 3억 ~ 10억 (한도)<br/>
            - 자차(차량손해담보): 가입완료<br/>
            - 게스트 본인 외 운전 시 호스트의 사전 승인 필요<br/>
            <br/>
            {/*게스트는 호스트가 가입한 자동차보험의 보장을 받으며, 보험료를 호스트와 절반씩 분담합니다. (연간 총보험료를 12개월로 나누어 환산한 금액)<br/><br/>*/}
            게스트는 호스트가 가입한 자동차보험의 보장을 받으며, 보험료 분담액이 월이용료에 포함되어있습니다.<br/><br/>
            {/*게스트의 연령에 따라 보험료가 달라지므로, 매월 분담할 보험료 액수는 호스트에게 매칭 요청 시 확인할 수 있습니다. (대물 한도 및 자차 자기부담금도 이때 같이 확인 가능해요)*/}
            게스트의 연령이 30세 미만인 경우, 보험료 할증에 따라 추가금액이 청구될 수 있습니다.
        </p>
        <p>
            게스트는 사고 시 호스트에게 보험료 할증, 차량 감가, 수리기간 동안 차를 이용하지 못하는 손해 등에 대한 포괄적인 보상으로 ‘위로금’을 지급합니다.<br/>
            <br/>
            <p style={{backgroundColor: '#FAFAFA', padding: '8px', border: '1px solid #E0E0E0'}}>
                (위로금 산정기준)<br/>
                게스트는 보험처리 1건당 30만원의 위로금을 호스트에게 지급합니다.<br/>
                아래의 경우 위로금이 추가로 할증됩니다.<br/>
                <br/>
                ▶︎ 게스트 이용기간 6개월 이내 보험처리시 : +30만원<br/>
                ▶︎ 신차 차량가액 4,000만원 이상인 경우 : +20만원<br/>
                ▶︎ 신차 차량가액 7,000만원 이상인 경우 : +30만원<br/>
                <br/>
                (예시1) 호스트의 신차 차량가액 7,000만원 이상의 차를 이용하다가 6개월 이내에 사고를 내서 보험 1건 처리를 한 경우, 90만원의 위로금 발생<br/>
                (예시2) 호스트의 신차 차량가액 3,000만원의 차를 1년이 된 시점에 사고를 내서 보험 1건 처리를 한 경우, 30만원의 위로금 발생<br/>
                (예시3) 호스트의 신차 차량가액 4,000만원의 차를 1개월이 된 시점에 사고를 내서 보험 3건 처리를 한 경우, 110만원의 위로금 발생<br/>
                <br/>
                ※ 대인/대물/자차 각 1건으로 취급됩니다.<br/>
                ※ 이용기간과 차량가액에 따른 할증은 중복적용됩니다.<br/>
                ※ 보험처리가 필요하지 않은 경미한 사고의 경우, 30만원의 위로금을 지급합니다. (수리비는 따로 지급하지 않음)<br/>
                <br/>
                (위로금 지급시기)<br/>
                차량 수리와 상관없이 보험접수가 확정되는 때 즉시 호스트에게 지급해야합니다.
            </p>
        </p>
        {/*<a href={""} target="_blank">*/}
        {/*    <GrDocumentText size={16} /> 자세한 계약 정보 확인하러가기*/}
        {/*</a>*/}
    </_ContractInfoSection>
}


export const UseDateInfoSection = ({}) => {
    return <_ContractInfoSection>
        <p>
            약정기간은 최소 1개월(시작일이 1일이 아닌 경우 다음달의 말일까지)이며 매월 1개월씩 자동으로 연장됩니다. (만료일은 매월 말일로 고정)<br/>
            자동연장을 원치 않는 경우, 당월 24일 이내에 해지요청을 하고 다음달 말일까지 이용 후 계약을 종료할 수 있습니다.<br/>
            (예시) 2024. 8. 31. 에 계약을 해지하고 싶은 경우, 2024. 7. 24. 24:00 전 까지 해지요청이 필요합니다.<br/>
            ※ 차가 급하게 필요하신 경우 쏘카 대여요금(30%)을 지원해드립니다.
        </p>
    </_ContractInfoSection>
}

const _ContractInfoSection = styled.div`
  padding: 4px 8px;
  > p {
    padding-left: 18px;
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 12px;
  }
  .light {
    font-family: 'Pretendard',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px
  }
  > a {
    padding-left: 18px;
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: underline;
    text-underline-offset: 4px;

    svg {
      margin-right: 4px;
    }
  }
`;

export default ContractInfoSection;
