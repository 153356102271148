import {Dialog} from "@mui/material";
import styled from "styled-components";
import {useForm} from "../../../toolbox";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef} from "react";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../../../query/userQuery";
import {authAction} from "../../../redux/authReducer";
import {FormComponent, PhotoComponent} from "../../vehicle/VehicleAdd2/FormComponent";


export const UserEditModal = ({open, onClose, onSuccess}) => {
    return <Dialog fullWidth={true} open={open} onClose={onClose} PaperProps={{style: {borderRadius: '12px'}}}>
        <UserEdit onClose={onClose} onSuccess={onSuccess}/>
    </Dialog>
}

export const UserEdit = ({onClose, onSuccess}) => {
    const [updateUser, {loading}] = useMutation(UPDATE_USER);
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const jwt = useSelector(({auth}) => auth.token);
    const { form, onChange, resetValue } = useForm();
    const dispatch = useDispatch();

    const detailAddressRef = useRef();

    useEffect(() => {
        if (userInfo) {
            onChange([
                {id: 'realname', value: userInfo?.realname},
                {id: 'address', value: userInfo?.address?.split('\t')[0]},
                {id: 'detail_address', value: userInfo?.address?.split('\t')[1]},
                {id: 'phone', value: userInfo?.phone},
                {id: 'email', value: userInfo?.email},
                {id: 'longitude', value: userInfo?.lng},
                {id: 'latitude', value: userInfo?.lat},
                {id: 'licenseCard', value: userInfo?.licenseCard},
                {id: 'accountNumber', value: userInfo?.accountNumber},
            ]);
        }
    }, [userInfo])


    const save = async () => {
        if (loading) {
            return;
        }

        if (form.realname && form.realname.length <= 1) {
            alert('성명은 최소 2글자가 입력되어야합니다.');
            return;
        }

        const payload = {
            variables: {
                id: tokenInfo.id,
                data: {
                    realname: form.realname,
                    address: `${form.address}\t${form.detail_address ?? ''}`,
                    phone: form.phone,
                    email2: form.email,
                    lng: form?.longitude ? Number(form.longitude): null,
                    lat: form?.latitude ? Number(form.latitude): null,
                    licenseCard: form.licenseCard?.id ?? null,
                    accountNumber: form.accountNumber? form.accountNumber: null,
                }
            }
        };
        updateUser(payload).then(res => {
            dispatch(authAction.refresh());
            onClose();
            onSuccess();
        });
    }

    return <_UserEdit>
        <div className="wrap">
            <div className="content">
                <h4 className="title">회원 정보 수정</h4>
                <FormComponent id="realname" title="성명" form={form} onChange={onChange}/>
                <FormComponent id="address" title="거주지주소 (주민등록등본 기준)" form={form} onChange={onChange}/>
                <FormComponent id="detail_address" title="상세주소" form={form} onChange={onChange}/>
                <FormComponent type="tel" id="phone" title="휴대폰번호" form={form} onChange={onChange}/>
                <FormComponent id="email" title="이메일" form={form} onChange={onChange}/>
                <FormComponent id="accountNumber" placeholder="카카오 3333-04-3000000 홍길동" title="금액반환계좌번호 (은행명, 예금주명 기재필수)" form={form} onChange={onChange}/>
                {/*<div className="input-box">*/}
                {/*    <input className="input" placeholder="실명" type="text" value={form.realname} onChange={(e => onChange({id: 'realname', value: e.target.value}))} />*/}
                {/*</div>*/}
                {/*<div className="input-box">*/}
                {/*    <input className="input" placeholder="거주지주소" type="text" value={form.address} onClick={() => openAddress(callbackAddress)} />*/}
                {/*</div>*/}
                {/*<div className="input-box">*/}
                {/*    <input ref={detailAddressRef} className="input" placeholder="상세주소" type="text" value={form.detail_address} onChange={(e => onChange({id: 'detail_address', value: e.target.value}))} />*/}
                {/*</div>*/}
                {/*<div className="input-box">*/}
                {/*    <input className="input" placeholder="휴대폰" type="tel" value={form.phone} onChange={(e => onChange({id: 'phone', value: toPhoneFormat(e.target.value)}))} />*/}
                {/*</div>*/}
                {/*<div className="input-box">*/}
                {/*    <input className="input" placeholder="이메일" type="email" value={form.email} onChange={(e => onChange({id: 'email', value: e.target.value}))} />*/}
                {/*</div>*/}
                {/*<div className="input-box">*/}
                {/*    <input className="input" placeholder="계좌정보" type="text" value={form.accountNumber} onChange={(e => onChange({id: 'accountNumber', value: e.target.value}))} />*/}
                {/*</div>*/}
                <PhotoComponent id="licenseCard" isSingle={true} title="운전면허증 (주민등록번호 전체표시필수)" form={form} onChange={onChange} />
                <div style={{height: '8px'}}></div>
                {/*<div className="account-info_content_input-box">*/}
                {/*    <input maxLength={6} className="account-info_content_input-box_input" placeholder="주민번호 앞자리" type="text" value={residenceNumber} onChange={handleChangeResi1}/> -*/}
                {/*    <input maxLength={1} ref={resi2Ref} className="account-info_content_input-box_input" placeholder="뒷 1자리" type="text" value={residenceNumber2} onChange={handleChangeResi2}/>*/}
                {/*</div>*/}
                {/*<div className="account-info_content_input-box">*/}
                {/*    <input ref={phoneRef} className="account-info_content_input-box_input" placeholder="휴대폰 번호 (-제외)" type="text" value={phone} onChange={handleChangePhone}/>*/}
                {/*</div>*/}
                <div className="btn-box">
                    <div className="btn" onClick={onClose}>
                        취소
                    </div>
                    <div className="btn" onClick={save}>
                        {loading? '저장 중 입니다': '수정하기'}
                    </div>
                </div>
            </div>
        </div>
        {/*<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>*/}
        {/*    <Alert severity={severity}><span className="my-page_snackbar">{snackbar}</span></Alert>*/}
        {/*</Snackbar>*/}
    </_UserEdit>
}

const _UserEdit = styled.div`
  > .wrap {
    //outline: none;
    //vertical-align: middle;
    //display: table-cell;
    > .content {
      display: flex;
      flex-direction: column;
      gap: 12px;
      box-sizing: border-box;
      outline: none;
      background: #fff;
      border-radius: 12px;
      position: relative;
      //overflow-y: scroll;

      margin: 0 auto;
      padding: 16px 24px 58px;
      //width: 370px;
      //height: 500px;

      //top: 25%;
      //height: 50%;
      > .title {
        display: block;
        font-size: 22px;
        border-bottom: 1px solid #303030;
        word-break: break-all;
        line-height: 28px;
        //margin-bottom: 20px;
        //padding: 30px 0 14px;
        padding-bottom: 14px;
        padding-top: 10px;
      }


      > .input-box {
        position: relative;
        border-radius: 6px;
        border: 1px solid rgba(212, 216, 229, 0.3);
        background-color: rgba(212, 216, 229, 0.12);
        display: flex;
        align-items: center;
        margin-top: 4px;
        margin-bottom: 4px;
        width: 100%;
        > .input {
          background-color: rgba(0, 0, 0, 0);
          padding: 9px 11px 9px 11px;
          display: block;
          width: 100%;
          height: 36px;
          font-size: 14px;
          line-height: 16px;
          box-sizing: border-box;
          border: none;
        }
      }


      > .btn-box {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        text-align: center;
        > .btn {
          padding: 19px 0 18px;
          border-bottom-left-radius: 12px;
          width: 35%;
          color: white;
          font-size: 18px;
          font-weight: 700;
          background-color: #959EA0;
          cursor: pointer;
        }
        > .btn:last-child {
          width: 65%;
          border-bottom-right-radius: 12px;
          border-bottom-left-radius: 0;
          background-color: #33CCC1;
        }
      }
    }
  }
`
