import styled, {css} from "styled-components";
import {FormComponent, PhotoComponent} from "./FormComponent";

const VehicleUserInfo = ({form, onChange}) => {
    return <>
        <Wrap>
            <h1>기본 정보</h1>
            <div className="forms">
                <FormComponent id="realname" title="이름" placeholder="이름을 입력해주세요" form={form} onChange={onChange} />
                <FormComponent id="nickname" title="닉네임" form={form} onChange={onChange} />
                <FormComponent id="phone" title="휴대폰번호" form={form} onChange={onChange} />
                <FormComponent id="userAddress" title="거주지주소 (주민등록등본 기준)" form={form} onChange={onChange} />
                <FormComponent id="userDetailAddress" title="상세주소" form={form} onChange={onChange} />
                <div>
                    <PhotoComponent id="licenseCard" isSingle={true} title="운전면허증(선택)" form={form} onChange={onChange} />
                    {!form.licenseCard && <p className="big">본인 확인을 위한 용도로 사용됩니다. 나중에 업로드 가능해요.</p>}
                </div>
                {/*<div>*/}
                {/*    <PhotoComponent id="certificate" isSingle={true} title="차량등록증(선택)" form={form} onChange={onChange} />*/}
                {/*    {!form.certificate && <p className="big">본인 확인을 위한 용도로 사용됩니다. 나중에 업로드 가능해요.</p>}*/}
                {/*</div>*/}
            </div>
        </Wrap>
    </>
}

const Wrap = styled.div`
  padding: 0 20px 100px;
  > h1 {
    padding: 32px 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  
  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    p {
      color: var(--gray-scale-gray-700, #939393);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin: 4px 0;
    }
  }
`

export default VehicleUserInfo;
