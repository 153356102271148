import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

const MarkerMap = ({longitude, latitude}) => {
    const mapRef = useRef();
    const [map, setMap] = useState();
    const [marker, setMarker] = useState();

    // 지도 초기화
    useEffect(() => {
        const mapContainer = mapRef.current;
        const vehicleLatLng = new window.kakao.maps.LatLng(latitude, longitude);

        let marker = new window.kakao.maps.Marker({
            position: vehicleLatLng,
        });

        const mapOption = {
            center: vehicleLatLng,
            level: 5,
            marker: marker,
        };
        let m = new window.kakao.maps.StaticMap(mapContainer, mapOption);
        // let m = new window.kakao.maps.Map(mapContainer, mapOption);
        // marker.setMap(m);
        setMap(m);
        setMarker(marker);
    }, []);
    return <div style={{width: '100%', height: '100%', pointerEvents: 'none'}} ref={mapRef}/>
};

const _VehicleLocationSection = ({vehicle, className}) => {
    const [mapLoad, setMapLoad] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.kakao && vehicle?.longitude) {
            setMapLoad(true);
        }
    }, [window.kakao, vehicle])

    return <div className={className} onClick={(e) => {
        if (vehicle.type === 'user') {
            navigate(`/#u${vehicle.id}`);
            e.stopPropagation();
            return;
        }
        navigate(`/#v${vehicle.id}`);
        e.stopPropagation();
    }}>
        {mapLoad &&
            <MarkerMap longitude={vehicle.longitude} latitude={vehicle.latitude}/>
        }
    </div>
}

const VehicleLocationSection = styled(_VehicleLocationSection)`
  width: 100%;
  height: 200px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
`
export default VehicleLocationSection
