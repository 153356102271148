import styled from "styled-components";
import {FormComponent} from "./FormComponent";
import {useEffect} from "react";
import {DisabledBtn, PositiveBtn} from "./BottomNavigator";
import {loadingAction} from "../../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../../index";
import {useDispatch} from "react-redux";
import {toastAction} from "../../../redux/toastReducer";
import {isNumberPlateFormat} from "../../../toolbox/format";

const VehicleInfoSimple = ({form, onChange, onNext, draft}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (form.year) {
            onNext();
        }
    }, [form.year]);

    const handleHyphen = async () => {
        dispatch(loadingAction.loading(true));
        try {
            const {data} = await axios.post(SERVER_ADDRESS + '/api/used-vehicles/getHyphen2', {
                name: form.소유자명,
                numberPlate: form.numberPlate,
            });

            if (data.errYn !== 'N') {
                dispatch(toastAction.toast({severity: 'error', 'message': '소유주명과 차량번호를 확인 후 다시 시도해주세요. 실패 반복 시 차량정보를 수동으로 입력할 수 있습니다.'}));
                dispatch(loadingAction.loading(false));
                onNext();
                return;
            }

            if (data?.brand) {
                onChange({id: 'brand', value: data.brand})
            }
            if (data?.model) {
                onChange({id: 'model', value: data.model})
            }
            if (data?.year) {
                onChange({id: 'year', value: data.year})
            }
            if (data?.displacement) {
                onChange({id: 'displacement', value: Number(data.displacement)})
            }

            if (data?.mileage) {
                onChange({id: 'mileage', value: Number(data.mileage)});
            }
            if (data?.ownership) {
                onChange({id: '차량소유형태', value: data.ownership});
            }
            if (data?.isJoint) {
                onChange({id: '공동명의자여부', value: data.isJoint});
            }
            if (data?.mortCt) {
                onChange({id: '저당권건수', value: data.mortCt});
            }
            if (data?.seizCt) {
                onChange({id: '압류건수', value: data.seizCt});
            }

            if (data?.fuelType) {
                /*
                {id: '휘발유', label: '휘발유 (가솔린)'},
                {id: '경유', label: '경유 (디젤)'},
                {id: '전기', label: '전기'},
                {id: 'LPG등기타', label: 'LPG 등 기타'},
                 */
                if (data.fuelType.includes('디젤')) {
                    data.fuelType = '경유';
                } else if (data.fuelType.includes('가솔린')) {
                    data.fuelType = '휘발유';
                }

                if (!['휘발유', '경유', '전기'].includes(data.fuelType)) {
                    data.fuelType = 'LPG등기타';
                }
                onChange({id: 'fuelType', value: data.fuelType});
            }
            if (data?.response) {
                onChange({id: 'hyphenResponse', value: JSON.parse(data.response)});
            }

            if (data?.minGrade) {
                onChange([
                    {id: "하이픈차량번호", value: form.numberPlate},
                    {id: "하이픈차종", value: `${data.brand} ${data.model}`},
                    {id: "하이픈중고차가격", value: data.minGrade.중고차가격},
                    {id: "하이픈월고정비용", value: data.minGrade.월고정비용},
                    {id: "하이픈거리당비용", value: data.minGrade.거리당비용},
                ])
                onChange({id: '차량시세', value: Math.round(data.minGrade.중고차가격 / 10000 / 100) * 100});
            }
            onNext();
            dispatch(toastAction.toast({severity: 'success', 'message': '차량 조회에 성공하여 정보가 자동으로 입력되었습니다.'}));
        } catch (e) {
            dispatch(toastAction.toast({severity: 'error', 'message': '소유주명과 차량번호를 확인 후 다시 시도해주세요. 실패 반복 시 차량정보를 수동으로 입력할 수 있습니다.'}));
            console.log(e);
        }
        dispatch(loadingAction.loading(false));
    }

    const handleDisabled = () => {
        dispatch(toastAction.toast({severity: 'error', 'message': '차량 정보 조회를 해주세요.'}));
    }

    return <>
        <Wrap>
            <div className="forms">
                <h1>차량 정보</h1>
                <FormComponent id="numberPlate" title="차량번호" disabled={!draft} placeholder="차량번호를 입력해주세요" form={form} onChange={onChange} />
                <div>
                    <FormComponent id="소유자명" title="차량소유자명" placeholder="차량등록증에서 확인 가능해요" form={form} onChange={onChange} />
                    <p className="big">법인(회사) 임직원만 운전할 수 있는 차량은 호스트 등록이 불가합니다.</p>
                    <p className="big">
                        렌터카, 리스차인 경우 차량등록증 상의 회사명(소유주명)을 정확하게 입력해야 조회가 가능합니다.<br/>
                        (예시) 현대캐피탈 주식회사, 케이비캐피탈 주식회사, 신한캐피탈(주), 하나캐피탈(주), 롯데렌탈(주), 에스케이렌터카(주)
                    </p>
                </div>
                {/*<FormComponent id="numberPlate" title="차량번호" disabled={!draft} placeholder="차량번호를 입력해주세요" form={form} onChange={onChange} />*/}
                {/*<div style={{display: 'flex', justifyContent: 'center'}}>*/}
                {/*    <SearchBtn onClick={handleHyphen}><img src="/vehicle/search.svg"/>클릭해서 차량 정보 간편 입력하기</SearchBtn>*/}
                {/*</div>*/}
            </div>
        </Wrap>
        <BottomNaviWrap>
            {(form.소유자명 && isNumberPlateFormat(form.numberPlate)) ? <PositiveBtn onClick={handleHyphen}>다음</PositiveBtn>: <DisabledBtn onClick={handleDisabled}>다음</DisabledBtn>}
        </BottomNaviWrap>
    </>
}


const BottomNaviWrap = styled.div`
  display: flex;
  padding: 16px 20px 34px;
  gap: 10px;
  align-self: stretch;
  background: #FFF;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.04);
`

const Wrap = styled.div`
  padding: 32px 20px 32px;
  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  
  h2 {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  
  p {
    color: var(--gray-scale-gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin: 4px 0;
  }
  
  .underline {
    color: var(--mint, #33CCC1);
    text-decoration-line: underline;
    cursor: pointer;
  }
  
  .big {
    font-size: 14px;
  }
  
  .bold {
    color: #212121;
    font-weight: 600;
  }

  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  
  > .forms2 {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`

const SearchBtn = styled.div`
  cursor: pointer;
  gap: 4px;
  display: flex;
  align-items: center;
  padding: 14px;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  background: white;
`

export default VehicleInfoSimple;
