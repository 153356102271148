import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {ThumbSwipe2} from "./ThumbSwipe2";
import {commaNum, distanceFormat, getLoginLink, getResponseTimeTxt, getUseTypeTxt} from "../../../toolbox/format";
import {calcDistance} from "../../../toolbox";
import {Link, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Mannerbox} from "./Mannerbox";
import {pathSelector} from "../../../toolbox/logic";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_VEHICLE_LIKE, DELETE_VEHICLE_LIKE} from "../../../query/userQuery";
import {GET_DOORI_VEHICLE} from "../../../query/vehicleQuery";
import {find, get, isEmpty} from "lodash";
import {toastAction} from "../../../redux/toastReducer";
import {authAction} from "../../../redux/authReducer";
import {VehicleThumbnail} from "./VehicleThumbnail";

const _Mannerbox = styled(Mannerbox)`
  position: absolute;
  right: 0;
  top:-4px;
  background-color: white;
  padding: 2px;
`

const _ListVehicle = ({className, vehicle, key, isSolo, imageHide, useType, hideManner}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const pathInfo = useSelector(({path}) => path);
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const [_imageHide, setImageHide] = useState(imageHide ? true: false);
    const path = pathSelector(userInfo, pathInfo);
    const link = useRef();
    const bottomObserver = useRef();

    useEffect(() => {
        let observer;
        if (bottomObserver && _imageHide) {
            observer = new IntersectionObserver(onIntersect, {
                threshold: 0.1,
                rootMargin: '450px',
            });
            observer.observe(bottomObserver.current);
        }
        return () => observer && observer.disconnect();
    }, [bottomObserver, _imageHide]);

    const onIntersect = async ([entry], observer) => {
        if (entry.isIntersecting) {
            setImageHide(false);
            observer.unobserve(entry.target);
        }
    };

    const formatAddress = (address) => {
        const str = address.split('\t')[0];
        const regex = /\s\d+/;
        const match = str.match(regex);
        if (match) {
            const index = match.index;
            return str.substring(0, index);
        } else {
            return str;
        }
    }

    const getPrice = () => {
        if (useType) {
            const u = find(vehicle.hostContract.useTypes, (u) => u.id === useType);
            if (u) {
                return u.price;
            }
        }
        return Math.min(...vehicle.hostContract.useTypes.map(u => u.price));
    }

    return <div key={key} className={className}>
        <div className="header-box" ref={bottomObserver} onClick={() => {link.current.click(); }}>
            {_imageHide ?
                <VehicleThumbnail image={({url: null})} onClick={() => {link.current.click();}}/>
                :<VehicleThumbnail image={vehicle.thumbnail} onClick={() => {link.current.click();}}/>}
            <LikeButton vehicle={vehicle} />
        </div>
        <div className="content" onClick={() => {link.current.click();}}>
            {!isSolo && !hideManner &&<_Mannerbox liter={36} />}
            <p className="car">
                {vehicle.brand} {vehicle.model}
            </p>
            <p className="description">
                {vehicle.year}년식 · {formatAddress(vehicle.hostContract.address)} ({distanceFormat(calcDistance(vehicle.hostContract.latitude, vehicle.hostContract.longitude, path.latitude, path.longitude))})
            </p>
            {/*<p className="address">*/}
            {/*    {vehicle.hostContract.address.split('\t')[0]} ({distanceFormat(calcDistance(vehicle.hostContract.latitude, vehicle.hostContract.longitude, path.latitude, path.longitude))})*/}
            {/*</p>*/}
            <p className="price">
                {useType &&
                    (vehicle.eventPrice === false ?
                    <>
                        <span>월 {commaNum(getPrice())}원</span><br/>
                    </>:<>
                        <span>월 {commaNum(getPrice() * 0.9)}원</span> <span style={{textDecoration: 'line-through'}}>{commaNum(getPrice())}원</span><br/>
                        <span style={{fontSize: '14px', color: '#2eb0e5'}}>첫 달 {commaNum(getPrice() * 0.9 - 100000)}원</span>
                    </>)
                }
                {!useType && (vehicle.eventPrice === false ?
                    <>
                        <span>월 {commaNum(getPrice())}원</span>
                    </>:<>
                        <span>월 {commaNum(getPrice() * 0.9)}원</span> <span style={{textDecoration: 'line-through'}}>{commaNum(getPrice())}원</span>
                        <br/>
                        <span style={{fontSize: '14px', color: '#2eb0e5'}}>첫 달 {commaNum(getPrice() * 0.9 - 100000)}원</span>
                    </>)}
                {/*{isList ? `월 ${commaNum(find(vehicle.hostContract.useTypes, (u) => u.id === 'PRIORITY_GUEST').price)}원`: `${commaNum(Math.min(...vehicle.hostContract.useTypes.map(u => u.price)))}원 / 월`}*/}
            </p>
            <p className="tags">
                <span className="response-time-tag">{getResponseTimeTxt(vehicle?.hostUser?.hostStat)? `평균응답${getResponseTimeTxt(vehicle?.hostUser?.hostStat)}`: '응답시간보통'}</span>
                {vehicle.hostContract.useTypes.map((u) => <span className="user-tag">{getUseTypeTxt(u.id)}</span>)}
            </p>
            <a href={`/vehicle/${vehicle.id}?mode=doori`} target="_blank" ref={link} />
            {/*<Link to={`/vehicle/${vehicle.id}?mode=doori`} ref={link}/>*/}
        </div>
    </div>
}

export const ListVehicle2 = styled(_ListVehicle)`
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  padding: 12px 22px;
  display: flex;
  > .header-box {
    position: relative;
  }
  > .content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    gap: 2px;
    > a {
      display: none;
    }
    > .car {
      word-break: break-all;
      margin-right: 44px;
      line-height: 20px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      color: black;
    }
    
    > .description {
      color: #555555;
      line-height: 18px;
      margin-bottom: 4px;
      word-break: keep-all;
    }
    > .address {
      font-size: 14px;
      line-height: 16px;
      color: #555555;
    }
    > .tags {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      padding: 4px 0;
    }
    > .price {
      white-space: nowrap;
      font-size: 16px;
      line-height: 130%;
      font-weight: 600;
    }
    > .title {
      word-break: break-all;
      font-size: 16px;
      line-height: 120%;
      margin: 10px 0 6px;
      padding: 4px;
      color: #444444;
      font-weight: 600;
      background-color: #DDDDDD;
      text-align: center;
      border-radius: 4px;
    }
  }

  .user-tag {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    padding: 3px 5px;
    border-radius: 4px;
    white-space: nowrap;
    color: #F16767;
    background: #FFEAEA;
  }

  .response-time-tag {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    padding: 3px 5px;
    border-radius: 4px;
    white-space: nowrap;
    color: #4dc361;
    background: #E0FFE0;
  }
`;

export const TinyVehicle = styled(ListVehicle2)`
  > .content {
    > .tags {
      display: none;
    }
    
    > .price {
      display: none;
    }
  }
`

const _LikeButton = ({className, vehicle}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const [vehicleLike, {loading: loading1}] = useMutation(CREATE_VEHICLE_LIKE);
    const [deleteLike, {loading: loading2}] = useMutation(DELETE_VEHICLE_LIKE);
    const [myLikeId, setMyLikeId] = useState(null);

    useEffect(() => {
        if (vehicle) {
            const myLike = find(vehicle?.likes, (l) => l.user.id == tokenInfo?.id);
            if (myLike) {
                setMyLikeId(myLike.id);
            }
        }
    }, [vehicle]);

    const isLike = () => {
        if (loading1) {
            return true;
        } else if (loading2) {
            return false;
        }
        return myLikeId;
    }

    const handleLike = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isEmpty(tokenInfo)) {
            alert("로그인이 필요합니다.")
            const to = getLoginLink(location);
            navigate(to);
            return;
        }
        if (loading1 || loading2) {
            return;
        }
        if (isLike() && myLikeId) {
            await deleteLike({
                variables: {
                    id: myLikeId,
                },
                onCompleted: data => {
                    setMyLikeId(null);
                    vehicle.likes = vehicle.likes.filter((v) => v.user.id != tokenInfo.id);
                }
            });
        } else if (!isLike() && !myLikeId){
            dispatch(toastAction.toast({severity: 'success', 'message': '관심 차량 목록에 추가되었습니다.'}));
            await vehicleLike({
                variables: {
                    data: {
                        user: tokenInfo.id,
                        vehicle: vehicle.id,
                    },
                },
                onCompleted: data => {
                    let likeId = get(data, "createVehicleLike.data.id");
                    setMyLikeId(likeId);
                    vehicle.likes.push({
                        id: likeId,
                        user: {id: tokenInfo.id}
                    })
                }
            });
        }
    }

    return <img className={className} src={(!isEmpty(tokenInfo) && isLike())? "/vehicle/liked.png": "/vehicle/like.png"} onClick={handleLike} />
}

export const LikeButton = styled(_LikeButton)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  right: 12px;
`
