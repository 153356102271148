import styled from "styled-components";

const _Footer = ({className}) => {
    return <div className={className}>
        <span>Copyright ⓒ 원투유니콘 주식회사. All Rights Reserved</span><br/>
        상호 : 원투유니콘 주식회사 | 사업자등록번호 544-87-01418<br/>
        대표자명 : 허재창 | 개인정보보호 책임자 : 허재창<br/>
        대표전화 : 1661-0488<br/>
        서울특별시 강남구 강남대로 382 메리츠타워 18층<br/>
        통신판매업신고번호 : 제2021-서울서초-3774호
    </div>
}

const Footer = styled(_Footer)`
  padding-bottom: 60px;
  margin-top: 60px;
  margin-bottom: 20px;
  color: #7E8298;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  & > span {
    color: #B5B5C3;
  }
`

export default Footer;
