import styled from "styled-components";
import {FormComponent, RadioComponentSimple} from "../VehicleAdd2/FormComponent";

const UserInfo = ({form, onChange}) => {
    return <>
        <Wrap>
            <h1>내 정보 입력하기</h1>
            <div className="forms">
                <FormComponent id="nickname" title="닉네임" form={form} onChange={onChange} />
                <FormComponent type="tel" id="생년월일" placeholder="ex) 940214" title="생년월일 (주민번호 앞자리)" form={form} onChange={onChange} />
                <RadioComponentSimple id="성별" title="성별" form={form} onChange={onChange} values={[
                    {id: '남', label: '남'},
                    {id: '여', label: '여'},
                ]}/>
                <RadioComponentSimple id="흡연여부" title="흡연여부" form={form} onChange={onChange} values={[
                    {id: '비흡연자', label: '비흡연자'},
                    {id: '흡연자', label: '흡연자'},
                    {id: '전자담배', label: '전자담배'},
                ]}/>
                <RadioComponentSimple id="반려동물 탑승 방법" title="반려동물 탑승 방법" form={form} onChange={onChange} values={[
                    {id: '없음', label: '없음'},
                    {id: '케이지 탑승', label: '케이지 탑승'},
                    {id: '그냥 탑승', label: '그냥 탑승'},
                ]}/>
                <FormComponent type="tel" id="예상 운행거리" placeholder="ex) 500" title="예상 월주행거리 (km)" description="월 2,000km 초과 주행 시 km당 100원 내외의 초과주행료가 발생합니다." onChange={onChange} form={form} />
            </div>
        </Wrap>
    </>
}

const Wrap = styled.div`
  padding: 0 20px 40px;
  > h1 {
    padding: 32px 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  
  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    p {
      color: var(--gray-scale-gray-700, #939393);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin: 4px 0;
    }
  }
`

export default UserInfo;