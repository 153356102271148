import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {cloneDeep, find, get, isEmpty} from "lodash";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useForm} from "../../toolbox";
import {useMutation, useQuery} from "@apollo/client";
import {MY_CONTRACTS_FOR_VEHICLE_MODIFY, UPDATE_USER} from "../../query/userQuery";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {getLoginLink, isPhoneFormat} from "../../toolbox/format";
import {useInterval, useTimeout} from "react-use";
import VehicleUserInfo from "./VehicleAdd2/VehicleUserInfo";
import BottomNavigator from "./VehicleAdd2/BottomNavigator";
import VehicleInfo from "./VehicleAdd2/VehicleInfo";
import MatchingInfo from "./VehicleAdd2/MatchingInfo";
import InsuranceSet from "./VehicleAdd2/InsuranceSet";
import PriceSet from "./VehicleAdd2/PriceSet";
import Safety from "./VehicleAdd2/Safety";
import LoadingIndicator from "../../layout/LoadingIndicator";
import {toastAction} from "../../redux/toastReducer";
import moment from "moment-timezone";
import {flatEntity} from "../../toolbox/query";
import VehicleInfoSimple from "./VehicleAdd2/VehicleInfoSimple";
import queryString from "query-string";
import {CREATE_CONTRACT, CREATE_VEHICLE, UPDATE_CONTRACT, UPDATE_VEHICLE} from "../../query/vehicleQuery";
import {isNullish} from "../../toolbox/logic";
import {calcTax} from "../../toolbox/calculate";

const VehicleWritePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const user = useSelector(({user}) => user.user);

    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const parsedId = parsed?.id;


    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    const [isReady, cancel, reset] = useTimeout(10000);
    const [snackbar, setSnackbar] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [doorive, setDoorive] = useState(true);
    const [draft, setDraft] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [vehicleId, setVehicleId] = useState(null);
    const [contractId, setContractId] = useState(null);
    const [updatedAt, setUpdatedAt] = useState(null);

    const {data: _contracts, refetch} = useQuery(MY_CONTRACTS_FOR_VEHICLE_MODIFY, {variables: {id: tokenInfo?.id}});

    const getContract = (data) => {
        let contracts = get(data, "usersPermissionsUser.data.attributes.contracts.data", null);
        if (contracts) {
            contracts = contracts.map(c => flatEntity(c));
            let contract;
            if (parsedId) {
                contract = find(contracts, (c) => c.id === parsedId);
            } else {
                contract = find(contracts, (c) => c.vehicle.draftVehicle) ?? null;
            }
            return contract;
        }
        return null;
    }

    const isDuplicateVehicle = (numberPlate) => {
        let contracts = get(_contracts, "usersPermissionsUser.data.attributes.contracts.data", null);
        if (contracts) {
            contracts = contracts.map(c => flatEntity(c));
            return find(contracts, (c) => !c.vehicle.draftVehicle && c.vehicle.numberPlate === numberPlate);
        }
    }

    const [createVehicle] = useMutation(CREATE_VEHICLE);
    const [createContract] = useMutation(CREATE_CONTRACT);
    const [updateVehicle] = useMutation(UPDATE_VEHICLE);
    const [updateContract] = useMutation(UPDATE_CONTRACT);
    const [updateUser] = useMutation(UPDATE_USER);

    const {form, onChange} = useForm({
        차량시세: 2500,
        예상월분담액_DAY_SELECT: '',
        예상월분담액_PRIORITY_HOST: '',
        예상월분담액_PRIORITY_GUEST: '',
        예상보험료: '',
        예상거리당관리비: '',
        예상거리당주유비: '',
        게스트주행거리: 1000,
        later: 'NOW',

        // USER
        phone: '',
        realname: '',
        nickname: '',
        userAddress: '',
        userDetailAddress: '',
        licenseCard: null,

        // VEHICLE
        numberPlate: '',
        brand: '',
        model: '',
        year: '',
        pictures: [],
        thumbnail: null,
        displacement: null,
        mileage: '',
        options: '',
        fuelType: '휘발유',
        availableDate: moment.tz('Asia/Seoul').add(1, 'day').format('YYYY-MM-DD'),

        // ADDITIONAL (VEHICLE)
        이벤트선택: '이벤트선택안함',
        소유자명: '',
        하이픈차량번호: null,
        하이픈차종: null,
        하이픈중고차가격: null,
        하이픈월고정비용: null,
        하이픈거리당비용: null,

        // CONTRACT
        address: '',
        dooriveTitle: '',
        dooriveDescription: '',
        longitude: null,
        latitude: null,
        useType: 'TEMP',
        doorivePriceTax: '',
        doorivePriceInsurance: '',
        doorivePriceMonth: '',
        doorivePriceKm: '',
        doorivePriceOil: '',

        // USE TYPE DETAIL (CONTRACT)
        우선예약권자: '호스트',
        요일선택: '',
        daySelect: '',
        useTypes: 'DAY_SELECT,PRIORITY_HOST,PRIORITY_GUEST',
        price_DAY_SELECT: '',
        price_PRIORITY_HOST: '',
        price_PRIORITY_GUEST: '',

        // MATCHING DETAIL (CONTRACT)
        게스트연령: '무관',
        최소연령제한: '',
        성별: '무관',
        흡연: '불가',
        반려동물탑승: '가능',
        "차량 내 짐 비치": '가능',

        // INSURANCE INFO (CONTRACT)
        보험사명: '',
        대인한도: '',
        대물한도: '',
        자차여부: '',
        거리당보험료: '',
        월보험료인상액: '',

        // OWNERSHIP INFO (CONTRACT)
        차량소유형태: '',
        공동명의자여부: '',
        공동명의자주민번호: '',
        저당권건수: '0',
        압류건수: '0',
    });

    useEffect(() => {
        let contracts = get(_contracts, "usersPermissionsUser.data.attributes.contracts.data", null);
        if (!contracts || loaded) {
            return;
        }

        let contract = getContract(_contracts);
        if (contract) {
            let vehicle = contract.vehicle;
            onChange([
                {id: 'brand', value: vehicle.brand ?? form.brand},
                {id: 'model', value: vehicle.model},
                {id: 'year', value: vehicle.year},
                {id: 'fuelType', value: vehicle.fuelType},
                {id: 'displacement', value: String(vehicle.displacement ?? '')},
                {id: 'numberPlate', value: vehicle.numberPlate},
                {id: 'pictures', value: vehicle.pictures},
                {id: 'thumbnail', value: vehicle.thumbnail},
                {id: 'mileage', value: String(vehicle.mileage ?? '')},
                {id: 'options', value: vehicle.options},
                {id: 'availableDate', value: vehicle.availableDate},
                {id: 'address', value: contract.address},
                {id: 'dooriveTitle', value: contract.dooriveTitle},
                {id: 'dooriveDescription', value: contract.dooriveDescription},
                {id: 'longitude', value: contract.longitude},
                {id: 'latitude', value: contract.latitude},
                {id: 'useType', value: contract.useType},
                {id: 'daySelect', value: contract.daySelect},
                {id: 'doorivePriceMonth', value: String(contract.doorivePriceMonth ?? '')},
                {id: 'doorivePriceKm', value: String(contract.doorivePriceKm ?? '')},
                {id: 'doorivePriceInsurance', value: String(contract.doorivePriceInsurance ?? '')},
                {id: 'doorivePriceOil', value: String(contract.doorivePriceOil ?? '')},
                {id: 'doorivePriceTax', value: contract.doorivePriceTax},
                {id: '차량시세', value: vehicle.newCarPrice},
            ], true);

            if (contract.useTypes) {
                onChange({
                    id: 'useTypes',
                    value: contract.useTypes.map(v => v.id).join(',')
                });
                for (let useType of contract.useTypes) {
                    onChange({
                        id: `price_${useType.id}`,
                        value: useType.price
                    });
                }
            }

            // if (contract.useType === 'PRIORITY') {
            //     onChange({id : '우선예약권자', value: contract?.useTypeDetail?.priority});
            // } else if (contract.useType === 'DAY_SELECT') {
            //     onChange({id : '요일선택', value: contract?.useTypeDetail?.useDay});
            // }

            if (contract.matchingDetail) {
                for (let key of [
                    "성별", "흡연", "반려동물탑승", "차량 내 짐 비치"]) {
                    if (contract.matchingDetail[key]) {
                        onChange({id: key, value: contract.matchingDetail[key]});
                    }
                }
                if (contract.matchingDetail.최소연령제한 === '무관') {
                    onChange({id: "게스트연령", value: "무관"});
                } else {
                    onChange({id: "게스트연령", value: "입력"});
                    onChange({id: "최소연령제한", value: contract.matchingDetail.최소연령제한});
                }
            }

            if (contract.insuranceInfo) {
                for (let key of [
                    "보험사명", "대인한도", "대물한도", "자차여부", "거리당보험료", "월보험료인상액"]) {
                    if (contract.insuranceInfo[key]) {
                        onChange({id: key, value: contract.insuranceInfo[key]});
                    }
                }
            }

            if (contract.ownershipInfo) {
                for (let key of [
                    "차량소유형태", "공동명의자여부", "공동명의자주민번호", "저당권건수", "압류건수"]) {
                    if (contract.ownershipInfo[key]) {
                        onChange({id: key, value: contract.ownershipInfo[key]});
                    }
                }
            }

            if (vehicle.additional) {
                if (vehicle.additional.이벤트선택) {
                    onChange({id: '이벤트선택', value: vehicle.additional.이벤트선택});
                }
                if (vehicle.additional.소유자명) {
                    onChange({id: '소유자명', value: vehicle.additional.소유자명});
                }
                if (vehicle.additional.하이픈차량번호) {
                    onChange({id: '하이픈차량번호', value: vehicle.additional.하이픈차량번호});
                }
                if (vehicle.additional.하이픈차종) {
                    onChange({id: '하이픈차종', value: vehicle.additional.하이픈차종});
                }
                if (vehicle.additional.하이픈중고차가격) {
                    onChange({id: '하이픈중고차가격', value: vehicle.additional.하이픈중고차가격});
                }
                if (vehicle.additional.하이픈월고정비용) {
                    onChange({id: '하이픈월고정비용', value: vehicle.additional.하이픈월고정비용});
                }
                if (vehicle.additional.하이픈거리당비용) {
                    onChange({id: '하이픈거리당비용', value: vehicle.additional.하이픈거리당비용});
                }
            }
            setUpdatedAt(contract.updatedAt);
            setContractId(contract.id);
            setVehicleId(vehicle.id);
            setDoorive(vehicle.doorive);
            setDraft(vehicle.draftVehicle);
        } else {
            setDoorive(true);
            setDraft(true);
        }
        setLoaded(true);
    }, [_contracts]);

    useEffect(() => {
        if (user) {
            onChange([
                {id: 'phone', value: user.phone},
                {id: 'realname', value: user.realname},
                {id: 'nickname', value: user.nickname},
                {id: 'licenseCard', value: user.licenseCard},
            ]);
            if (user.address) {
                onChange([
                    {id: 'userAddress', value: user.address.split('\t')[0]},
                    {id: 'userDetailAddress', value: user.address.split('\t')[1]},
                ]);
            }
        }
    }, [user]);

    useInterval(async () => {
        // if (isReady() && !updateLoading && !createLoading && !uploading) {
        if (isReady()) {
            reset();
            if (draft) {
                await save(false);
            }
        }
    }, 1000);

    const save = async (finish) => {
        if (!loaded) {
            return;
        }
        if (!user) {
            return;
        }
        if (finished) {
            return;
        }

        if (finish) {
            cancel();
            dispatch(loadingAction.loading(true));
        }

        const _form = cloneDeep(form);
        const vehicleDto = {
            numberPlate: _form.numberPlate,
            brand: _form.brand,
            model: _form.model,
            year: _form.year,
            pictures: _form.pictures.map((picture) => String(picture.id)),
            thumbnail: _form.thumbnail?.id ? String(_form.thumbnail?.id): null,
            displacement: _form.displacement? Number(_form.displacement): null,
            mileage: _form.mileage? Number(_form.mileage): null,
            options: _form.options,
            fuelType: _form.fuelType,
            availableDate: _form.availableDate? _form.availableDate: null,
            newCarPrice: _form.차량시세,
            additional: {
                이벤트선택: _form.이벤트선택,
                소유자명: _form.소유자명,
                하이픈차량번호: _form.하이픈차량번호,
                하이픈차종: _form.하이픈차종,
                하이픈중고차가격: _form.하이픈중고차가격,
                하이픈월고정비용: _form.하이픈월고정비용,
                하이픈거리당비용: _form.하이픈거리당비용,
            },
        }

        if (_form.hyphenResponse) {
            vehicleDto.hyphenResponse = _form.hyphenResponse;
        }

        const toNumber = (v) => {
            if (v === '') {
                return null;
            }
            if (isNullish(v)) {
                return null;
            } else {
                return Number(v);
            }
        }

        const contractDto = {
            // doorivePriceTax: toNumber(_form.doorivePriceTax),
            doorivePriceKm: toNumber(_form.doorivePriceKm),
            // doorivePriceOil: toNumber(_form.doorivePriceOil),
            // doorivePriceInsurance: toNumber(_form.doorivePriceInsurance),
            dooriveTitle: _form.dooriveTitle,
            dooriveDescription: _form.dooriveDescription,
            address: _form.address,
            longitude: _form.longitude,
            latitude: _form.latitude,
            daySelect: _form.daySelect,
            matchingDetail: {
                성별: _form.성별,
                흡연: _form.흡연,
                반려동물탑승: _form.반려동물탑승,
                "차량 내 짐 비치": _form["차량 내 짐 비치"],
                최소연령제한: _form.최소연령제한 ? _form.최소연령제한 : "무관",
            },
            insuranceInfo: {
                보험사명: _form.보험사명,
                대인한도: _form.대인한도,
                대물한도: _form.대물한도,
                자차여부: _form.자차여부,
                거리당보험료: _form.거리당보험료,
                월보험료인상액: _form.월보험료인상액,
            },
            ownershipInfo: {
                차량소유형태: _form.차량소유형태,
                공동명의자여부: _form.공동명의자여부,
                공동명의자주민번호: _form.공동명의자주민번호,
                저당권건수: _form.저당권건수,
                압류건수: _form.압류건수,
            }
        }

        const useTypeIds = _form.useTypes.split(',');
        const useTypes = [];
        for (let useType of useTypeIds) {
            let price = toNumber(_form[`price_${useType}`]);
            if (finish && price === null) {
                price = toNumber(_form[`예상월분담액_${useType}`]);
            }
            useTypes.push({
                id: useType,
                price,
            })
        }
        contractDto.useTypes = useTypes;

        if (useTypeIds.length && _form.useType === 'TEMP') {
            contractDto.useType = useTypeIds[0];
        }

        if (_form.later === 'LATER') {
            contractDto.useType = 'TEMP';
        }

        if (useTypeIds.length) {
            contractDto.doorivePriceMonth = toNumber(_form[`price_${useTypeIds[0]}`]);
            if (finish && contractDto.doorivePriceMonth === null) {
                contractDto.doorivePriceMonth = toNumber(_form[`예상월분담액_${useTypeIds[0]}`]);
            }
        }

        // if (contractDto.useType === 'DAY_SELECT') {
        //     contractDto.useTypeDetail = {useDay: _form.요일선택}
        // } else if (contractDto.useType === 'PRIORITY') {
        //     contractDto.useTypeDetail = {priority: _form.우선예약권자}
        // } else {
        //     contractDto.useTypeDetail = null;
        // }

        if (finish) {
            if (contractDto.doorivePriceKm === null) {
                contractDto.doorivePriceKm = Number(_form.예상거리당주유비);
            }
        }

        if (finish) {
            if (!isPhoneFormat(form.phone)) {
                dispatch(toastAction.toast({severity: 'error', 'message': '올바른 연락처를 입력해주세요.'}));
                dispatch(loadingAction.loading(false));
                return;
            }
        }

        if (updatedAt) {
            const {data} = await refetch({
            });
            let contract = getContract(data);
            if (contract) {
                if (contract?.updatedAt !== updatedAt) {
                    alert('다른 곳에서 동시에 작성을 진행하여 작성을 종료합니다.')
                    setFinished(true);
                    dispatch(loadingAction.loading(false));
                    return;
                }
            } else {
                alert('다른 곳에서 동시에 작성을 진행하여 작성을 종료합니다.')
                setFinished(true);
                dispatch(loadingAction.loading(false));
                return;
            }
        }

        if (finish) {
            cancel();
            window.fbq('track', 'SubmitApplication');
        }

        let _address = form.userAddress + '\t' + form.userDetailAddress;
        if (user.nickname !== form.nickname || user.phone !== form.phone || user.realname !== form.realname || user.licenseCard?.id !== form.licenseCard?.id || user.address !== _address) {
            updateUser({
                variables: {
                    id: tokenInfo.id,
                    data: {phone: form.phone, realname: form.realname, nickname: form.nickname, licenseCard: form.licenseCard?.id ?? null, address: _address},
                },
            }).then(() => {});
        }


        if (finish) {
            const res = await axios.post(SERVER_ADDRESS + '/api/vehicles/modify', {
                vehiclePayload: vehicleDto,
                contractPayload: contractDto,
                contractId: contractId,
            });
            dispatch(loadingAction.loading(false));
            if (res.data.result === 'OK') {
                setFinished(true);
                alert('저장되었습니다.');
                window.location.replace(`/vehicle/${vehicleId}?mode=doori`);
            } else {
                alert(res.data.message + '\n'
                    + '도움이 필요하시면 발송된 알림톡을 통해서 상담해주세요!\n'
                    + '누락항목 완성 후 검토요청을 클릭하시면 호스트 등록이 완료됩니다. ✨');
            }
        } else {
            let vehicleResponse = null;
            let contractResponse = null;
            let updatedAt = null;
            if (vehicleId && contractId) {
                if (doorive) {
                    const {data: vehicleData} = await updateVehicle({
                        variables: {
                            id: vehicleId,
                            data: vehicleDto,
                        },
                    });
                    vehicleResponse = flatEntity(get(vehicleData, "updateVehicle.data", null));
                }

                const {data: contractData} = await updateContract({
                    variables: {
                        id: contractId,
                        data: contractDto,
                    },
                });
                contractResponse = flatEntity(get(contractData, "updateContract.data", null));

                updatedAt = get(contractResponse, "updatedAt");
            } else {
                const {data: vehicleData} = await createVehicle({
                    variables: {
                        data: {
                            ...vehicleDto,
                            draftVehicle: true,
                            doorive: true,
                            visible: false,
                            owned: false,
                            publishedAt: moment().toISOString(),
                        },
                    },
                });
                vehicleResponse = get(vehicleData, "createVehicle.data");
                const newVehicleId = get(vehicleResponse, "id");

                const {data: contractData} = await createContract({
                    variables: {
                        data: {
                            ...contractDto,
                            vehicle: newVehicleId,
                            users_permissions_user: tokenInfo.id,
                            contractType: 'DOORICAR_WAITING',
                            publishedAt: moment().toISOString(),
                        },
                    },
                    onCompleted: async data => {
                    }
                });
                contractResponse = get(contractData, "createContract.data");

                const newContractId = get(contractResponse, "id");
                updatedAt = get(contractResponse, "updatedAt");

                setVehicleId(newVehicleId);
                setContractId(newContractId);
            }
            setUpdatedAt(updatedAt);
        }
        return;
    }

    const submit = async () => {
        await save(true);
    }

    const [page, setPage] = useState(-1);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);


    useEffect(() => {
        if (doorive && page === 1 && !parsedId) {
            if (form.numberPlate) {
                let contract = isDuplicateVehicle(form.numberPlate);
                if (contract) {
                    window.alert('이미 등록되어 있는 차량입니다. 차량 수정페이지로 이동합니다.');
                    window.location.replace(`/vehicle_modify?id=${contract.id}`);
                }
            }
        }
    }, [doorive, page]);


    useEffect(() => {
        if (page === -1 && form.year) {
            setPage(0);
        }
    }, [form.year]);

    useEffect(() => {
        if (doorive === false && page <= 0) {
            setPage(0);
        }
    }, [doorive]);

    useEffect(() => {
        if (page === -1 && form.year) {
            setPage(0);
        }
    }, [form.year]);

    useEffect(() => {
        let 세액 = calcTax(form.year, form.displacement);
        if (세액) {
            onChange({id: 'doorivePriceTax', value: 세액});
        } else {
            onChange({id: 'doorivePriceTax', value: ''});
        }
    }, [form.displacement, form.year]);

    if (!doorive) {
        return <PageWrap>
            {page === 0 && <VehicleUserInfo form={form} onChange={onChange} draft={draft} />}
            {page === 1 && <MatchingInfo form={form} onChange={onChange} draft={draft} doorive={doorive} />}
            {page === 2 && <PriceSet form={form} onChange={onChange} />}
            {page === 3 && <InsuranceSet form={form} onChange={onChange} doorive={doorive} />}
            {page === 4 && <Safety form={form} onChange={onChange} />}
            {page >= 0 &&
                <BottomNavigator page={page}
                                 onBefore={() => setPage((p) => p-1)}
                                 onNext={() => {
                                     if (page === 2) {
                                         if (!form.useTypes) {
                                             dispatch(toastAction.toast({severity: 'error', 'message': '매칭 유형을 1개 이상 선택해주세요.'}));
                                             return;
                                         }
                                     }
                                     setPage((p) => p+1);
                                 }}
                                 lastPage={4}
                                 onSubmit={async () => await submit()} />
            }
            <LoadingIndicator isVisible={!loaded || !user} />
        </PageWrap>
    } else {
        return <PageWrap>
            {page === -1 && <VehicleInfoSimple form={form} onChange={onChange} draft={draft} onNext={() => setPage(0)} />}
            {page === 0 && <VehicleInfo form={form} onChange={onChange} draft={draft} doorive={doorive} />}
            {page === 1 && <VehicleUserInfo form={form} onChange={onChange} />}
            {page === 2 && <PriceSet form={form} onChange={onChange} />}
            {page === 3 && <MatchingInfo form={form} onChange={onChange} draft={draft} doorive={doorive} />}
            {page === 4 && <InsuranceSet form={form} onChange={onChange} doorive={doorive} />}
            {page === 5 && <Safety form={form} onChange={onChange} />}
            {page >= 0 &&
                <BottomNavigator page={page}
                                 onBefore={() => setPage((p) => p-1)}
                                 onNext={() => {
                                     if (page === 2) {
                                         if (!form.useTypes) {
                                             dispatch(toastAction.toast({severity: 'error', 'message': '매칭 유형을 1개 이상 선택해주세요.'}));
                                             return;
                                         }
                                     }
                                     setPage((p) => p+1);
                                 }}
                                 lastPage={5}
                                 onSubmit={async () => await submit()} />
            }
            <LoadingIndicator isVisible={!loaded || !user} />
        </PageWrap>
    }
}

export default VehicleWritePage;

const PageWrap = styled.div`
  box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
`
