import styled, {css} from "styled-components";
import {FormComponent, PhotoComponent} from "./FormComponent";
import React, {useEffect, useState} from "react";
import BottomDrawer from "./BottomDrawer";
import {PositiveBtn} from "./BottomNavigator";

const InsuranceSet = ({form, onChange, doorive}) => {
    const [open, setOpen] = useState(false);

    /*

                보험사명: _form.보험사명,
                대인한도: _form.대인한도,
                대물한도: _form.대물한도,
                자차여부: _form.자차여부,
                거리당보험료: _form.거리당보험료,
                월보험료인상액: _form.월보험료인상액,
     */
    return <>
        <Wrap>
            <div className="forms">
                <h1>자동차보험</h1>

                <div>
                    <h2 style={{fontSize: '20px'}}>내 보험 정보 (선택)</h2>
                    <p className="big">계약 전까지 입력을 완료해주세요.</p>
                </div>
                <FormComponent id="보험사명" title="보험사명" form={form} onChange={onChange} />
                <FormComponent id="대인한도" title="대인 한도" form={form} onChange={onChange} />
                <FormComponent id="대물한도" title="대물 한도" form={form} onChange={onChange} />
                <FormComponent id="자차여부" title="자차보험 가입여부" form={form} onChange={onChange} />
                <FormComponent id="거리당보험료" title="거리당보험료 (캐롯만 해당)" helpBtn={true} description={"캐롯손해보험의 경우 호스트/게스트가 각자 주행한 만큼 거리당보험료를 부담합니다. 게스트 추가 시 거리당보험료가 변동되므로, 나중에 매칭이 되면 실제 변동된 값으로 입력해주세요."} form={form} onChange={onChange} />
                <FormComponent id="월보험료인상액" type="textarea" title="월보험료인상액" placeholder={"[게스트 연령 별 월보험료인상액 예시]\n" +
                    "28세 - 월 6만원 추가\n" +
                    "26세 - 월 12만원 추가\n" +
                    "23세 - 월 20만원 추가\n" +
                    "\n" +
                    "※ 게스트로부터 매칭요청이 들어올 때마다 해당 연령으로 조회한 값을 적어두시는 것을 추천드려요."} form={form} onChange={onChange} rows={8} />

                <div>
                    <h2>최소보험조건</h2>
                    <p className="big">매칭이 성사되면 아래 기준 이상으로 보험조건을 변경해주세요.</p>
                    <TextBox>
                        <p className="big bold">두리카 최소보험조건</p>
                        <p className="big bold">대인 한도: 무제한<br/>
                            대물 한도: 최소 3억 이상<br/>
                            자차: 가입 권장<br/>
                            운전자범위: "게스트를 추가운전자로 등록" 또는 "X세 이상 누구나 운전"<br/>
                        </p>
                        <p>
                            📌 추가운전자 지정 또는 누구나운전으로 변경 시 인상되는 보험료는 보통 월 5천원 수준입니다. 게스트 연령이 만 30세 미만일 경우 보험료 인상폭이 클 수 있으므로 매칭 조율 과정에서 월이용료를 추가하여 제안할 수 있습니다.
                        </p>
                        <p>
                            📌 공유 종료 후 다시 원래의 운전자 범위로 변경 시, 보험사에서 추가로 납부했던 보험료를 일할계산하여 환급해드립니다. (보험료 인상분을 일시납한 경우)
                        </p>
                    </TextBox>

                    <TextBox style={{marginTop: '12px'}}>
                        <p>
                            ⚠️ 게스트 사고시 보험처리 안내<br/><br/>
                            게스트는 호스트가 가입한 자동차보험의 보장을 받는 대신, 보험 처리 시 호스트에게 보험료 할증, 감가상각, 수리기간에 대한 손해 등을 포괄적으로 보상하는 '위로금'을 지급해야합니다. (자기부담금은 게스트가 보험사에 별도 납부)
                        </p>
                        <p>책임매니저가 단톡방에 상주하면서 사고 후 처리 과정을 전부 도와드리며, 게스트가 위로금을 납부하지 않을 경우 두리카에서 대신 먼저 지급해드리니 걱정마세요!</p>
                        <p>
                            <span className="link" onClick={() => setOpen(true)}>위로금 산정기준 상세보기></span>
                        </p>
                    </TextBox>
                </div>

                {/*{doorive && <div>*/}
                {/*    <h2>공유시작가능일</h2>*/}
                {/*    /!*<h3>[2023년 9월 1일] 부터 공유를 시작할 수 있어요.</h3>*!/*/}
                {/*    <FormComponent id="availableDate" type="date" placeholder="공유시작가능일 선택하기" form={form} onChange={onChange} />*/}
                {/*    <p>*/}
                {/*        ⚠️ 매칭 시작이 가능한 날짜를 입력해주세요. 매칭 시 최소 공유기간은 1개월이며, 약정기간이 매월 1개월씩 자동으로 연장됩니다. 연장을 중단하려면 최소 1개월 이전에 해지요청이 필요해요.*/}
                {/*    </p>*/}
                {/*</div>}*/}
                {/*<FormComponent id="dooriveTitle" title="제목" placeholder="예) G80 같이 타실 분 구해요!" form={form} onChange={onChange} />*/}
                {/*<div>*/}
                {/*    <h2>설명</h2>*/}
                {/*    <h3>게스트 이용시 주의사항, 매칭 조건 관련사항 등 게스트에게 추가적으로 하고 싶은 말을 자유롭게 적어주세요.</h3>*/}
                {/*    <FormComponent id="dooriveDescription" type="textarea" rows={6} placeholder="예) 손세차 필수, 스팀세차 불가, 평소 차를 깨끗하게 관리해서 상태가 아주 좋습니다. 주차장 24시간 출입가능해서 편리해요." form={form} onChange={onChange} />*/}
                {/*</div>*/}
            </div>
        </Wrap>
        <BottomDrawer open={open} onClose={() => setOpen(false)}>
            <Drawer1/>
            <PositiveBtn style={{marginTop: '24px', marginBottom: '14px'}} onClick={() => setOpen(false)}>확인</PositiveBtn>
        </BottomDrawer>
    </>
}

const Drawer1 = () => {
    return <>
        <h1>위로금 산정 기준</h1>
        <p style={{marginTop: '16px'}}>게스트는 보험처리 1건당 30만원의 위로금을 호스트에게 지급해야합니다. 아래의 경우 위로금이 추가로 할증됩니다.</p>
        <div style={{marginTop: '20px', marginBottom: '20px', borderBottom: '1px solid #D9D9D9'}}></div>
        <p style={{marginTop: '8px'}}>・ 게스트 이용기간 3개월 이내 보험처리시 : +15만원</p>
        <p style={{marginTop: '8px'}}>・ 신차 차량가액 4천만원 이상인 경우 : +20만원</p>
        <p style={{marginTop: '8px'}}>・ 신차 차량가액 7천만원 이상인 경우 : +30만원</p>
        <div style={{marginTop: '20px', marginBottom: '20px', borderBottom: '1px solid #D9D9D9'}}></div>
        <p>(예시1)<br/>
            <span className="--gray">호스트의 신차 차량가액 7천만원 이상의 차를 이용하다가 3개월 이내에 사고를 내서 보험 1건 처리를 한 경우 → 75만원의 위로금 발생</span>
        </p>
        <p style={{marginTop: '12px'}}>(예시2)<br/>
            <span className="--gray">호스트의 신차 차량가액 3천만원의 차를 1년이 된 시점에 사고를 내서 보험 1건 처리를 한 경우 → 30만원의 위로금 발생</span>
        </p>
        <p style={{marginTop: '12px'}}>(예시3)<br/>
            <span className="--gray">호스트의 신차 차량가액 4천만원의 차를 1개월이 된 시점에 사고를 내서 보험 3건 처리를 한 경우 → 125만원의 위로금 발생</span>
        </p>
        <p style={{marginTop: '12px'}}>
            ※ 대인/대물/자차 각 1건으로 취급됩니다.<br/>
            ※ 이용기간과 차량가액에 따른 할증은 중복적용됩니다.<br/>
            ※ 보험처리가 필요하지 않은 경미한 사고의 경우, 30만원의 위로금을 지급합니다. (수리비는 별도 부담)<br/>
            ※ 차량이 전손된 경우에는 보험사에 지급하는 전손보상액의 20%를 위로금으로 지급합니다.<br/>
            ※ 자차보험 미가입 차량의 경우, 자차 사고 시 게스트가 수리비 전액을 사비로 부담해야하며 위 기준과 동일한 금액의 위로금을 지급해야합니다. (전손 시에는 시가를 고려하여 두리카가 정하는 금액 보상)<br/>
            ※ 사고 시 게스트의 과실이 0인 경우 위로금이 발생하지 않습니다.
        </p>
    </>
}

const Wrap = styled.div`
  padding: 32px 20px 100px;

  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  h2 {
    color: var(--gray-scale-black, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  h3 {
    margin: 4px 0;
    color: var(--gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
  }
  
  p {
    margin-top: 6px;
    color: var(--gray-scale-gray-700, #939393);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }
  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`
const TextBox = styled.div`
  margin-top: 16px;
  padding: 16px 12px;
  border-radius: 8px;
  background: var(--gray-scale-gray-200, #F5F5F5);

  color: var(--gray-scale-gray-700, #939393);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  
  .thin {
    font-weight: 400;
  }
  .link {
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`

export default InsuranceSet;
