import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";

import SwiperCore, {Autoplay} from "swiper";
import 'swiper/swiper-bundle.css';
import * as queryString from "querystring";
import {RiArrowLeftSFill, RiArrowRightSFill} from "react-icons/ri";
import styled from "styled-components";
import {calcDistance, commaNum} from "../../../toolbox";
import {useSelector} from "react-redux";
import {distanceFormat} from "../../../toolbox/format";
import {filter, sortBy} from "lodash";
import {pathSelector} from "../../../toolbox/logic";
import {ListVehicle2} from "./ListVehicle2";

SwiperCore.use([Autoplay]);

const MyListVehicle = styled(ListVehicle2)`
    padding: 0;
`

const _VehicleSwipe = ({vehicles, className, exceptId}) => {
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const [swiper, setSwiper] = useState(null);
    const pathInfo = useSelector(({path}) => path);
    const userInfo = useSelector(({user}) => user.user);

    const path = pathSelector(userInfo, pathInfo);

    const vehicleSort = (vs) => {
        let filtered = filter(vs, (v) => v.id != exceptId);
        if (path.longitude && path.latitude) {
            return sortBy(filtered, (v) => calcDistance(v.latitude, v.longitude, path.latitude, path.longitude));
        }
        return filtered;
    }
    // useEffect(() => {
    //     if (vehicles) {
    //         swiper.update();
    //     }
    // }, [vehicles])

    return <div className={className}>
        <div className="header">
            <div className="--title">이런 차는 어때요</div>
            <div className="--navigator">
                <div className="--prev --btn" ref={navigationPrevRef}><RiArrowLeftSFill color="#C4C4C4" size={20}/></div>
                <div className="--next --btn" ref={navigationNextRef}><RiArrowRightSFill color="#C4C4C4" size={20}/></div>
            </div>
        </div>
        <Swiper className="swiper-container"
                onSwiper={setSwiper}
                slidesPerView={1}
                touchRatio={0}
            // autoplay={{delay: 5000}}
                breakpoints={{
                    768: {spaceBetween: 20},
                    0: {spaceBetween: 20},
                }}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
            //loop
        >
            {vehicles && vehicleSort(vehicles).map((v) =>
                <SwiperSlide key={v.id}>
                    <MyListVehicle key={v.id} vehicle={v} imageHide={true} />
                    {/*<Vehicle vehicle={v} />*/}
                </SwiperSlide>
            )}
        </Swiper>
    </div>
};

export const VehicleSwipe = styled(_VehicleSwipe)`
  width: 100%;
  overflow: hidden;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 28px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  border-top: 6px solid #EEEEEE;
  .header {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    .--title {
      
    }
    .--navigator {
      display: flex;
      justify-content: center;
      align-items: center;
      .--btn {
        border: 1px solid #c4c4c4;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`
