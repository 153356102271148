import styled, {css} from "styled-components";
import React from "react";

const _ProgressBar = ({className}) => {
    return <div className={className}>
        <div className="inner" />
    </div>
}

const ProgressBar = styled(_ProgressBar)`
  height: 4px;
  width: 18px;
  background-color: #F0F0F0;
  border-radius: 50px;
  
  > .inner {
    height: 100%;
    border-radius: inherit;
    background-color: #91D181;
    ${(props) => css`
      width: ${props.pct + '%'};
    `
}
  }
`

const _Mannerbox = ({className, liter}) => {
    return <div className={className}>
        <div className="bar">
            <div>
                {liter}L
            </div>
            <ProgressBar pct={liter} />
        </div>
        <div className="text">매너리터</div>
        {/*<div>😀</div>*/}
    </div>
}

export const Mannerbox = styled(_Mannerbox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  > .bar {
      display: flex;
      //flex-direction: column;
      align-items: center;
      font-weight: 700;
      font-size: 9px;
      color: #91D181;
      > :first-child {
        margin-right: 2px;
      }
    }
  
  > .text {
    margin-top: 2px;
    font-size: 10px;
    font-weight: 500;
    color: #878787;
  }

    //> :nth-child(2) {
    //  padding-top: 2px;
    //  margin-left: 4px;
    //  font-size: 20px;
    //  line-height: 20px;
    //}
`
