import {gql} from "@apollo/client";
import {VEHICLE_QUERY} from "./vehicleQuery";

const NOTIFICATIONS = `
  id
  attributes {
    notiType
    body
    link
    createdAt
    readedAt
    state
    sender {
      data {
        id
        attributes {
          contact
          realname
          nickname
          profile_image {
            data {
              id
              attributes {
                url
                width
                height
              }
            }
          }
        }
      }
    }
    contract {
      data {
        id
      }
    }
  }`;

const USER = `
  id
  attributes {
    realname
    nickname
    address
    email2
    phone
    lng
    lat
    introduce
    contact
    mapMarker
    matching_profile
    accountNumber
    userMemo
    notification_readed_at
    notifications(sort: "createdAt:desc") {
      data {
        ${NOTIFICATIONS}
      }
    }
    profile_image {
      data {
        id
        attributes {
          url
          width
          height
        }
      }
    }
    credit_cards {
      data {
        id
        attributes {
          name
        }
      }
    }
    licenseCard {
      data {
        id
        attributes {
          width
          height
          url
        }
      }
    }
    wallets {
      data {
        id
        attributes {
          title
          amount
          recordedAt
          createdAt
        }
      }
    }
  }
`

export const GET_ME = gql`
query getUser($id:ID!){
  usersPermissionsUser(id: $id) {
    data {
      ${USER}
    }
  }
}`;

export const GET_USER = gql`query getUser($id:ID!){
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        lng
        lat
        address
        introduce
        nickname
        realname
        phone
        verifies
        profile_image {
          data {
            id
            attributes {
              url
              width
              height
            }
          }
        }
        vehicle_likes(filters: {vehicle: {visible: {eq: true}}}) {
          data {
            id
            attributes {
              createdAt
              vehicle {
                data {
                  ${VEHICLE_QUERY}
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export const UPDATE_USER = gql`
mutation updateUser ($id: ID!, $data: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      ${USER}
    }
  }
}
`;

export const DELETE_CARD = gql`
mutation deleteCreditCard ($id: ID!) {
  deleteCreditCard(id: $id) {
    data {
      id
    }
  }
}
`;

export const MY_USED_VEHICLE = gql`
query getUserContracts($id:ID!){
  usersPermissionsUser(id: $id) {
    data {
      attributes {
        used_vehicles(filters: {finished: {ne: true}}) {
          data {
            id
            attributes {
              updatedAt
              brand
              model
              year
              displacement
              numberPlate
              mileage
              availableDate
              text
              title
              address
              description
              priceInsurance
              priceOnce
              priceMonth
              priceOil
              priceRepair
              priceKm
              priceKmEstimate
              priceTax
              longitude
              latitude
              options
              youngCharge
              additional
              fuelType
              useType
              useTypeDetail
              matchingDetail
              certificate {
                data {
                  id
                  attributes {
                    url
                    width
                    height
                  }
                }
              }
              pictures {
                data {
                  id
                  attributes {
                    url
                    width
                    height
                  }
                }
              }
              thumbnail {
                data {
                  id
                  attributes {
                    url
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export const GET_ALL_TAGS = gql`
query getAllTags {
  usersPermissionsUsers(filters: {lng: {gt: 0}, lat: {gt: 0}, mapMarker: {eq: true}}, pagination: {limit: -1}) {
    data {
      id
      attributes {
        address
        lng
        lat
        introduce
        nickname
        profile_image {
          data {
            id
            attributes {
              url
              width
              height
            }
          }
        }
      }
    }
  }
}
`

export const CREATE_USED_VEHICLE = gql`
mutation createUsedVehicle ($data: UsedVehicleInput!) {
  createUsedVehicle(data: $data) {
    data {
      id
      attributes {
        updatedAt
      }
    }
  }
}
`;

export const UPDATE_USED_VEHICLE = gql`
mutation updateUsedVehicle ($id: ID!, $data: UsedVehicleInput!) {
  updateUsedVehicle(id: $id, data: $data) {
    data {
      id
      attributes {
        updatedAt
      }
    }
  }
}
`;

export const CREATE_VEHICLE_LIKE = gql`
mutation createVehicleLike ($data: VehicleLikeInput!) {
  createVehicleLike(data: $data) {
    data {
      id
    }
  }
}
`;

export const DELETE_VEHICLE_LIKE = gql`
mutation deleteVehicleLike ($id: ID!) {
  deleteVehicleLike(id: $id) {
    data {
      id
    }
  }
}
`;

export const GET_PAYMENTS_BY_USER = gql`
query getPaymentRequestByUser($id:ID!) {
  paymentRequests(filters: {users_permissions_user: {id: {eq: $id}}}) {
    data {
      id
      attributes {
        name
        amount
        status
        startAt
        createdAt
        description
        issueCode
        walletUses {
          data {
            id
            attributes {
              amount
            }
          }
        }
        contract {
          data {
            id
          }
        }
        toss_payment_histories {
          data {
            id
            attributes {
              receiptUrl
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PAYMENTS_BY_CONTRACT = gql`
query getPaymentRequestByUser($id:ID!) {
  paymentRequests(filters: {contract: {id: {eq: $id}}}) {
    data {
      id
      attributes {
        name
        amount
        status
        startAt
        createdAt
        description
        issueCode
        contract {
          data {
            id
          }
        }
        walletCharges {
          data {
            id
            attributes {
              title
              amount
              recordedAt
              createdAt
              user {
                data {
                  id
                  attributes {
                    realname
                  }
                }
              }
              
              contract {
                data {
                  id
                }
              }
            }
          }
        }
        walletUses {
          data {
            id
            attributes {
              title
              amount
              recordedAt
              createdAt
              user {
                data {
                  id
                  attributes {
                    realname
                  }
                }
              }
              contract {
                data {
                  id
                }
              }
            }
          }
        }
        toss_payment_histories {
          data {
            id
            attributes {
              receiptUrl
            }
          }
        }
      }
    }
  }
}
`;

export const CREATE_WALLET = gql`
mutation createWallet ($data: WalletInput!) {
  createWallet(data: $data) {
    data {
      id
    }
  }
}
`;

export const GET_WALLETS_BY_USER = gql`
query getWalletsByUser($id:ID!) {
  wallets(filters: {user: {id: {eq: $id}}}) {
    data {
      id
      attributes {
        title
        amount
        recordedAt
        createdAt
        description
        contract {
          data {
            id
            attributes {
              users_permissions_user {
                data {
                  id
                  attributes {
                    realname
                  }
                }
              }
              vehicle {
                data {
                  id
                  attributes {
                    model
                    numberPlate
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_WALLETS_BY_CONTRACT = gql`
query getWalletsByContract($id:ID!) {
  wallets(filters: {contract: {id: {eq: $id}}}) {
    data {
      id
      attributes {
        title
        amount
        recordedAt
        createdAt
        description
        paymentCharge {
          data {
            id
            attributes {
              contract {
                data {
                  id
                }
              }
            }
          }
        }
        contract {
          data {
            id
            attributes {
              users_permissions_user {
                data {
                  id
                  attributes {
                    realname
                  }
                }
              }
              vehicle {
                data {
                  id
                  attributes {
                    model
                    numberPlate
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_WALLETS_BY_ADMIN = gql`
query getWalletsByAdmin {
  wallets {
    data {
      id
      attributes {
        title
        amount
        recordedAt
        createdAt
        description
        contract {
          data {
            id
            attributes {
              users_permissions_user {
                data {
                  id
                  attributes {
                    realname
                  }
                }
              }
              vehicle {
                data {
                  id
                  attributes {
                    model
                    numberPlate
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const UPDATE_NOTIFICATION = gql`
mutation updateNotification ($id: ID!, $data: NotificationInput!) {
  updateNotification(id: $id ,data: $data) {
    data {
      id
    }
  }
}
`

export const MY_CONTRACTS_FOR_VEHICLE_MODIFY = gql`
query getUserContracts($id:ID!){
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        contracts {
          data {
            id
            attributes {
              startDate
              endDate
              monthlyCharge
              monthlyChargeDuo
              kmCharge
              contractType
              status
              parkingInfo
              address
              parkingInfo
              longitude
              latitude
              dooriveDescription
              dooriveTitle
              doorivePriceKm
              doorivePriceOil
              doorivePriceMonth
              doorivePriceTax
              doorivePriceInsurance
              useType
              useTypeDetail
              useTypes
              daySelect
              matchingDetail
              insuranceInfo
              ownershipInfo
              updatedAt
              manner_actions {
                data {
                  attributes {
                    actionType
                    likes {
                      data {
                        id
                      }
                    }
                  }
                }
              }
              vehicle {
                data {
                  id
                  attributes {
                    brand
                    model
                    year
                    mileage
                    options
                    fuelType
                    numberPlate
                    displacement
                    availableDate
                    bakchaAdditionalInfo
                    doorive
                    visible
                    draftVehicle
                    newCarPrice
                    additional
                    thumbnail {
                      data {
                        id
                        attributes {
                          url
                        }
                      }
                    }
                    pictures {
                      data {
                        id
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const MY_CONTRACTS = gql`
query getUserContracts($id:ID!){
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        contracts {
          data {
            id
            attributes {
              startDate
              endDate
              monthlyCharge
              monthlyChargeDuo
              kmCharge
              contractType
              status
              address
              parkingInfo
              longitude
              latitude
              dooriveDescription
              dooriveTitle
              doorivePriceKm
              doorivePriceMonth
              doorivePriceTax
              doorivePriceInsurance
              useType
              useTypeDetail
              matchingDetail
              manner_actions {
                data {
                  attributes {
                    actionType
                    likes {
                      data {
                        id
                      }
                    }
                  }
                }
              }
              vehicle {
                data {
                  ${VEHICLE_QUERY}
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
