import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {ThumbSwipe2} from "./ThumbSwipe2";
import {commaNum, distanceFormat, getLoginLink, getUseTypeTxt} from "../../../toolbox/format";
import {calcDistance} from "../../../toolbox";
import {Link, useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Mannerbox} from "./Mannerbox";
import {pathSelector} from "../../../toolbox/logic";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_VEHICLE_LIKE, DELETE_VEHICLE_LIKE} from "../../../query/userQuery";
import {GET_DOORI_VEHICLE} from "../../../query/vehicleQuery";
import {find, isEmpty} from "lodash";
import {toastAction} from "../../../redux/toastReducer";
import {authAction} from "../../../redux/authReducer";

const _Mannerbox = styled(Mannerbox)`
  position: absolute;
  right: 0;
  top:-4px;
  background-color: white;
  padding: 2px;
`

const _LikeButton = ({className, vehicle}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const vehicles = useSelector(({vehicles}) => vehicles.payload);

    const {data, refetch} = useQuery(GET_DOORI_VEHICLE, {variables: {id: vehicle.id}, skip: true});
    const [vehicleLike, {loading: loading1}] = useMutation(CREATE_VEHICLE_LIKE);
    const [deleteLike, {loading: loading2}] = useMutation(DELETE_VEHICLE_LIKE);
    const [isLike, setIsLike] = useState(find(vehicle.likes, (l) => l.user.id === tokenInfo?.id));

    useEffect(() => {
        let _vehicle = find(vehicles, (v) => v.id === vehicle.id);
        setIsLike(find(_vehicle?.likes, (l) => l.user.id === tokenInfo?.id));
    }, [vehicles]);

    const handleLike = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (isEmpty(tokenInfo)) {
            alert("로그인이 필요합니다.")
            const to = getLoginLink(location);
            navigate(to);
            return;
        }
        if (loading1 || loading2) {
            return;
        }
        let _vehicle = find(vehicles, (v) => v.id === vehicle.id);
        let like = find(_vehicle? _vehicle.likes: vehicle.likes, (l) => l.user.id === tokenInfo.id);
        if (isLike && like) {
            setIsLike(false);
            await deleteLike({
                variables: {
                    id: like.id,
                },
                onCompleted: data => {
                    refetch();
                    if (!isEmpty(tokenInfo)) {
                        dispatch(authAction.refresh());
                    }
                }
            });
        } else if (!isLike && !like){
            setIsLike(true);
            dispatch(toastAction.toast({severity: 'success', 'message': '관심 차량 목록에 추가되었습니다.'}));
            await vehicleLike({
                variables: {
                    data: {
                        user: tokenInfo.id,
                        vehicle: vehicle.id,
                    },
                },
                onCompleted: data => {
                    refetch();
                    if (!isEmpty(tokenInfo)) {
                        dispatch(authAction.refresh());
                    }
                }
            });
        }
    }

    return <img className={className} src={(!isEmpty(tokenInfo) && isLike)? "/vehicle/liked.png": "/vehicle/like.png"} onClick={handleLike}
         style={{cursor: 'pointer', width: '36px', height: '36px', position: 'absolute', top: '4px', right: '4px'}} />
}

export const LikeButton = styled(_LikeButton)`
  cursor: pointer;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 4px;
  right: 4px;
`

const _ListVehicle = ({className, vehicle, key, isSolo, imageHide, isList}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const pathInfo = useSelector(({path}) => path);
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const [_imageHide, setImageHide] = useState(imageHide ? true: false);
    const path = pathSelector(userInfo, pathInfo);
    const link = useRef();
    const bottomObserver = useRef();

    const getTag = () => {
        const tags = [];
        if (vehicle.owned) {
            tags.push("⚡️즉시출고");
        }
        if (vehicle.year && vehicle.year.endsWith('23년형')) {
            tags.push("✨신형");
        }
        if (tags.length === 0) {
            tags.push("🔥인기");
        }
        return tags.join(" ");
    }

    useEffect(() => {
        let observer;
        if (bottomObserver && _imageHide) {
            observer = new IntersectionObserver(onIntersect, {
                threshold: 0.1,
                rootMargin: '450px',
            });
            observer.observe(bottomObserver.current);
        }
        return () => observer && observer.disconnect();
    }, [bottomObserver, _imageHide]);

    const onIntersect = async ([entry], observer) => {
        if (entry.isIntersecting) {
            setImageHide(false);
            observer.unobserve(entry.target);
        }
    };

    return <div key={key} className={className}>
        <div className="header-box" ref={bottomObserver}>
            {_imageHide ? <div style={{width: '1px', height: '270px'}} onClick={() => {link.current.click();}}/>
                : <ThumbSwipe2 images={vehicle.pictures} onClick={() => {link.current.click();}}/>}
            <LikeButton vehicle={vehicle} />
        </div>
        <div className="content" onClick={() => {link.current.click();}}>
            {!isSolo && <_Mannerbox liter={36} />}
            {/*<_Mannerbox liter={36} />*/}
            <p className="car">
                {vehicle.brand} {vehicle.model} ({vehicle.year})
                {vehicle.hostContract.useTypes.map((u) => <span className="user-tag">{getUseTypeTxt(u.id)}</span>)}

                {/*{getUseTypeTxt(vehicle.useType, vehicle.useTypeDetail) && <span className="user-tag">{getUseTypeTxt(vehicle.useType, vehicle.useTypeDetail)}</span>}*/}
            </p>
            {/*<p className="address">*/}
            {/*    {vehicle.address}{path.longitude ? ` (${distanceFormat(calcDistance(vehicle.latitude, vehicle.longitude, path.latitude, path.longitude))})`: ''}*/}
            {/*</p>*/}
            {!isSolo &&
                <p className="address">
                    {(path.longitude && vehicle.hostContract.longitude) ? `거리 : ${distanceFormat(calcDistance(vehicle.hostContract.latitude, vehicle.hostContract.longitude, path.latitude, path.longitude))} (${vehicle.hostContract.address})`: vehicle.hostContract.address}
                </p>}
            {isSolo && <p className="address">
                {getTag()}
            </p>}
            {vehicle.hostContract?.dooriveTitle &&
                <p className="title">❝ {vehicle.hostContract.dooriveTitle} ❞</p>}
            <p className="price">
                {isList ? `${commaNum(find(vehicle.hostContract.useTypes, (u) => u.id === 'PRIORITY_GUEST').price)} 원 / 월`: `${commaNum(Math.min(...vehicle.hostContract.useTypes.map(u => u.price)))}원 / 월`}
                {/*{(isSolo && !vehicle.guestId) ? `${commaNum(vehicle.priceSolo)}원 / 월`: `${commaNum(vehicle.priceDuo)}원 / 월`}*/}
            </p>
            <a href={`/vehicle/${vehicle.id}?mode=doori`} target="_blank" ref={link} />
            {/*<Link to={`/vehicle/${vehicle.id}?mode=doori`} ref={link}/>*/}
        </div>
    </div>
}

export const ListVehicle = styled(_ListVehicle)`
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  padding: 12px 22px;
  > .header-box {
    position: relative;
  }
  > .content {
    position: relative;
    > a {
      display: none;
    }
    > p {
      line-height: 22px;
    }
    > .car {
      word-break: keep-all;
      margin-right: 48px;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      
      .user-tag {
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        padding: 3px 5px;
        margin-left: 6px;
        border-radius: 4px;
        white-space: nowrap;
        color: #F16767;
        background: #FFEAEA;
      }
    }
    > .address {
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
      color: #333333;
      margin-bottom: 4px;
    }
    > .price {
      font-size: 14px;
      font-weight: 600;
      text-align: right;
      padding-right: 4px;
    }
    > .title {
      word-break: break-all;
      font-size: 16px;
      line-height: 120%;
      margin: 10px 0 6px;
      padding: 4px;
      color: #444444;
      font-weight: 600;
      background-color: #DDDDDD;
      text-align: center;
      border-radius: 4px;
    }
  }
  
  & > :nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
