import {useDispatch, useSelector} from "react-redux";
import styled, {css} from "styled-components";
import {useLocation} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {calcDistance, useForm} from "../../toolbox";
import {cloneDeep, filter, find, get, isEmpty, sortBy} from "lodash";
import {pathAction} from "../../redux/pathReducer";
import {convertDooricar, fillUseTypes, filterVehicles, getGuestVehicles} from "../../toolbox/format";
import {VehicleMap} from "./component/VehicleMap";
import {isCollision} from "../../toolbox/calculate";
import {VehicleThumbnail} from "./component/VehicleThumbnail";
import {EventSwipe} from "../event/component/EventSwipe";
import {ListVehicle} from "./component/ListVehicle";
import {isAdmin, openAddress, pathSelector} from "../../toolbox/logic";
import {useMutation, useQuery} from "@apollo/client";
import {GET_ALL_TAGS, UPDATE_USER} from "../../query/userQuery";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import {flatEntity} from "../../toolbox/query";
import {vehiclesAction} from "../../redux/vehiclesReducer";
import LoadingIndicator from "../../layout/LoadingIndicator";
import {ListVehicle2} from "./component/ListVehicle2";
import moment from "moment-timezone";
import queryString from "query-string";
import * as Hangul from 'hangul-js';
import {FormComponent} from "./VehicleAdd2/FormComponent";

const MyThumbnail = styled(VehicleThumbnail)`
  width: 100%;
  min-width: 100%;
  height: 270px;
  margin: 0 0 8px 0;
`

const MyDivider = styled.div`
  width: 100%;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-left: 22px;
  margin-right: 22px;
`
const _AddressRequest = ({className, children, onClick}) => {
    return <div className={className} onClick={onClick}>
        <>{children}</>
    </div>
}

const AddressRequest = styled(_AddressRequest)`
  cursor: pointer;
  margin: 8px 22px;
  padding: 6px;
  display: flex;
  align-items: center;
  //box-sizing: border-box;
  //width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(180,180,180);
  color: rgb(80,80,80);
  word-break: keep-all;
  
  > img {
    width: 20px;
    margin-right: 8px;
  }
`

const Vehicles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
`

// const VehicleFilter = ({className, vehicles, form, onChange, reset}) => {
//     const initialState = [
//         {
//             title: "브랜드",
//             path: "brand",
//             allValues: [],
//             values: undefined,
//             children: ["모델", "연식"],
//         },
//         {
//             title: "모델",
//             path: "model",
//             allValues: [],
//             values: undefined,
//             parents: ["브랜드"],
//             children: ["연식"],
//         },
//         {
//             title: "연식",
//             path: "year",
//             allValues: [],
//             values: undefined,
//             parents: ["모델", "브랜드"],
//         },
//         {
//             title: "연료",
//             path: "fuelType",
//             allValues: [],
//         },
//         // {
//         //     title: "변속기",
//         //     path: "transmissionType",
//         //     allValues: [],
//         // },
//     ];
//     const [filterInfo, setFilterInfo] = useState(initialState);
//
//     useEffect(() => {
//         initFilter();
//     }, [vehicles]);
//
//     const initFilter = () => {
//         const newFilterInfo = cloneDeep(initialState);
//         for (let fi of newFilterInfo) {
//             const values = {};
//             for (let v of vehicles) {
//                 const value = get(v, fi.path);
//                 values[value] = null;
//             }
//             fi.allValues = Object.keys(values);
//         }
//         setFilterInfo(newFilterInfo);
//         reset();
//     }
//
//     const onChangeFilter = (e) => {
//         const newFilterInfo = cloneDeep(filterInfo);
//         const fi = find(newFilterInfo, (fi) => fi.title === e.target.id);
//         if (fi.children) {
//             if (e.target.value !== fi.title) {
//                 const victims = filter(vehicles, (v) => get(v, fi.path) === e.target.value);
//                 for (let child of fi.children) {
//                     const _fi = find(newFilterInfo, (fi) => fi.title === child);
//                     const values = {};
//                     for (let v of victims) {
//                         const value = get(v, _fi.path);
//                         values[value] = null;
//                     }
//                     _fi.values = Object.keys(values);
//                     if (!find(_fi.values, (v) => v === form[_fi.path])) {
//                         onChange({id : _fi.path, value: undefined})
//                     }
//                 }
//             } else {
//                 for (let child of fi.children) {
//                     const fi = find(newFilterInfo, (fi) => fi.title === child);
//                     fi.values = undefined;
//                 }
//             }
//         }
//         setFilterInfo(newFilterInfo);
//         if (e.target.value === fi.title) {
//             onChange({id: fi.path, value: undefined});
//         } else {
//             onChange({id: fi.path, value: e.target.value});
//         }
//     }
//     return <div className={className}>
//         {filterInfo.map((fi) => <select key={fi.title} id={fi.title} value={form[fi.path] ?? fi.title} onChange={onChangeFilter}>
//             <option value={undefined}>{fi.title}</option>
//             {(fi.values || fi.allValues).map((v) => <option key={fi.title+v} value={v}>{v}</option>)}
//         </select>)}
//     </div>
// }
//
// const StyledFilter = styled(VehicleFilter)`
//   padding: 8px;
//     & > select {
//       margin-right: 4px;
//
//       :last-child {
//         margin-right: 0;
//       }
//     }
// `

const VehicleFilter = ({form, onChange, vehicles}) => {
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const pathInfo = useSelector(({path}) => path);
    const userInfo = useSelector(({user}) => user.user);
    const path = pathSelector(userInfo, pathInfo);

    const sorters = [
        {
            id: "가까운거리순",
            label: "가까운거리순",
            predicate: (vs) => sortBy(vs, (v) => calcDistance(v.latitude, v.longitude, path.latitude, path.longitude)),
        },
        {
            id: "가격낮은순",
            label: "가격낮은순",
            predicate: (vs) => sortBy(vs, (v) => getPrice(v)),
        },
        {
            id: "가격높은순",
            label: "가격높은순",
            predicate: (vs) => sortBy(vs, (v) => getPrice(v)).reverse(),
        },
        // {
        //     id: "추천순",
        //     label: "추천순",
        //     predicate: (vs) => sortBy(vs, (v) => v?.rating ?? 0).reverse(),
        // },
        {
            id: "관심높은순",
            label: "관심높은순",
            predicate: (vs) => sortBy(vs, (v) => v.likes.length).reverse(),
        },
        {
            id: "최신연식순",
            label: "최신연식순",
            predicate: (vs) => sortBy(vs, (v) => v.year).reverse(),
        }
    ]

    if (!isEmpty(tokenInfo)) {
        sorters.push({
            id: "내가찜한차량",
            label: "내가찜한차량",
            predicate: (vs) => sortBy(vs, (v) =>
                (find(v.likes, (l) => l.user.id == tokenInfo?.id)? 10000: 0) + v.likes.length).reverse(),
        });
    }

    const filters = [
        {
            id: "PRIORITY_GUEST",
            label: "게스트우선형",
            predicate: (vs) => vs.filter((v) => find(v.hostContract.useTypes, (u) => u.id === 'PRIORITY_GUEST')),
        },{
            id: "PRIORITY_HOST",
            label: "호스트우선형",
            predicate: (vs) => vs.filter((v) => find(v.hostContract.useTypes, (u) => u.id === 'PRIORITY_HOST')),
        },{
            id: "DAY_SELECT",
            label: "요일지정형",
            predicate: (vs) => vs.filter((v) => find(v.hostContract.useTypes, (u) => u.id === 'DAY_SELECT')),
        // }, {
        //     id: "100만원이하",
        //     label: "100만원이하",
        //     predicate: (vs) => vs.filter((v) => getPrice(v) < 1000000),
        // }, {
        //
        //     id: "50만원이하",
        //     label: "50만원이하",
        //     predicate: (vs) => vs.filter((v) => getPrice(v) < 500000),
        }
    ];

    const vehicleSearcher = (str) => {
        let searcher = new Hangul.Searcher(str.replace(' ', '').toLowerCase());
        return (vs) => vs.filter((v) => searcher.search(`${v.brand.replace(' ', '').toLowerCase()}${v.model.replace(' ', '').toLowerCase()}`) >= 0);
    }

    const getPrice = (v) => {
        let price = Math.min(...v.hostContract.useTypes.map(u => u.price));
        if (form.tags.includes("게스트우선형만")) {
             price = find(v.hostContract.useTypes, (u) => u.id === 'PRIORITY_GUEST')?.price;
        }

        if (v.eventPrice === false) {
            return price;
        }
        return price * 0.9;
    }

    const toggleFilter = (id) => {
        let tags = [];
        if (form["tags"]) {
            tags = form["tags"].split(',');
        }
        if (tags.includes(id)) {
            tags = filter(tags, (i) => i !== id);
        } else {
            tags = [id];
            // tags.push(id);
            // if (id === '100만원이하') {
            //     tags = filter(tags, (i) => i !== "50만원이하");
            // } else if (id === '50만원이하') {
            //     tags = filter(tags, (i) => i !== "100만원이하");
            // }
        }
        onChange({id: "tags", value: tags.join(',')});
    }

    const selectSorter = (id) => {
        onChange({id: "sorter", value: id});
    }

    useEffect(() => {
        if (vehicles) {
            onChange({id: "visibleVehicles", value: applyFilter(form.tags.split(','), form.sorter, form.searchText)});
        }
    }, [vehicles, form.tags, form.sorter, form.searchText]);

    const applyFilter = (tags, sort, searchText) => {
        let newVehicles = [...vehicles];
        for (let tag of tags) {
            if (!tag) {
                continue;
            }
            const filter = find(filters, (f) => f.id === tag);
            newVehicles = filter.predicate(newVehicles);
        }
        if (sort) {
            const sorter = find(sorters, (s) => s.id === sort);
            newVehicles = sorter.predicate(newVehicles);
        }
        if (searchText) {
            newVehicles = vehicleSearcher(searchText)(newVehicles);
        }
        return newVehicles;
    }

    return <div>
        <FilterDiv>
            {filters.map((f) => <FilterBtn onClick={() => toggleFilter(f.id)} selected={form.tags.split(',').includes(f.id)}>
                {f.label}
            </FilterBtn>)}
        </FilterDiv>
        <FilterDiv>
            {sorters.map((s) => <FilterBtn onClick={() => selectSorter(s.id)} selected={form.sorter === s.id}>
                {s.label}
            </FilterBtn>)}
        </FilterDiv>
        <div style={{padding: '6px 22px', position: 'relative'}}>
            <FormComponent id="searchText" placeholder="차량 검색어 입력" form={form} onChange={onChange}/>
            <img src="/vehicle/search.svg" style={{position: 'absolute', right: '34px', top: '50%', transform: 'translateY(-50%)', userSelect: 'none', pointerEvents: 'none'}}/>
        </div>
    </div>
}

const FilterDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 22px;
  gap: 8px;
`

const FilterBtn = styled.div`
  border: 1px solid rgb(218,220,224);
  border-radius: 80px;
  color: rgb(32,33,36);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  line-height: 40px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  
  &:hover {
    background-color: rgb(232,234,237);
  }
  
  ${props => props.selected && css`
    border-color: rgb(232,240,254);
    background-color: rgb(232,240,254);
    color: #1558d6;
  `}
`

const _VehicleListPage = ({className}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;

    const pathInfo = useSelector(({path}) => path);
    const userInfo = useSelector(({user}) => user.user);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);

    const { form, onChange, resetValue } = useForm({
        tags: 'PRIORITY_GUEST',
        sorter: '가까운거리순',
        visibleVehicles: [],
        searchText: '',
    });

    const [vehicles, setVehicles] = useState(null);
    const [userTags, setUserTags] = useState(null);
    const [filteredVehicles, setFilteredVehicles] = useState([]);

    const [updateUser] = useMutation(UPDATE_USER);

    const path = pathSelector(userInfo, pathInfo);

    const [mapBound, setMapBound] = useState();
    const [mapOpen, setMapOpen] = useState(null);
    const [showVehicle, setShowVehicle] = useState(false);

    const hasPath = () => {
        return path.longitude;
    }

    const needPath = () => {
        return !hasPath();// && ('geolocation' in window?.navigator)
    }

    useEffect(() => {
        axios.get(SERVER_ADDRESS + '/api/vehicles/mapVehicles2', {}).then((res) => {
            const {data} = res;

            const _vehicles = data.vehicles.map(v => {
                if (!v.thumbnail && v.bakchaAdditionalInfo) {
                    const bakchaInfo = v.bakchaAdditionalInfo;
                    if (bakchaInfo.Photo) {
                        v.thumbnail = {url: bakchaInfo.Photo[0]};
                        v.pictures = bakchaInfo.Photo.map((p) => ({url: p}));
                    }
                }
                if (!v.thumbnail || !v.pictures) {
                    v.thumbnail = {url: "/vehicle/dummy.png"};
                    v.pictures = [{url: "/vehicle/dummy.png"}];
                }

                let updatedAt = moment.tz(v.updatedAt, 'Asia/Seoul');
                if (updatedAt.format('YYYY-MM-DD') <= '2024-01-06') {
                    fillUseTypes(v.hostContract);
                } else {
                }

                v.hostContract.useTypes = sortBy(v.hostContract.useTypes, (u) => u.price);
                return ({...v, mapId: `v${v.id}`, type: 'vehicle',
                    latitude: v.hostContract.latitude, longitude: v.hostContract.longitude,
                    price: Math.min(...v.hostContract.useTypes.map(u => u.price)),
                })});
            setVehicles(_vehicles);
            // onChange({
            //     id: 'tags',
            //     value: '게스트우선형만',
            // });
            onChange({
                id: 'visibleVehicles',
                value: _vehicles.filter((v) => find(v.hostContract.useTypes, (u) => u.id === 'PRIORITY_GUEST'))
            });
            onChange({
                id: 'visibleVehicles',
                value: _vehicles
            });
            setUserTags(data.userTags.map(u =>
                ({...u, mapId: `u${u.id}`, type: 'user', latitude: u.lat, longitude: u.lng})));

            setFilteredVehicles(data.filteredVehicles.map(v => {
                if (!v.thumbnail && v.bakchaAdditionalInfo) {
                    const bakchaInfo = v.bakchaAdditionalInfo;
                    if (bakchaInfo.Photo) {
                        v.thumbnail = {url: bakchaInfo.Photo[0]};
                        v.pictures = bakchaInfo.Photo.map((p) => ({url: p}));
                    }
                }
                if (!v.thumbnail || !v.pictures) {
                    v.thumbnail = {url: "/vehicle/dummy.png"};
                    v.pictures = [{url: "/vehicle/dummy.png"}];
                }
                v.hostContract.dooriveTitle = '이미 매칭된 차량입니다.';
                v.hostContract.useTypes = sortBy(v.hostContract.useTypes, (u) => u.price);
                return ({...v, mapId: `v${v.id}`, type: 'matched',
                    latitude: v.hostContract.latitude, longitude: v.hostContract.longitude,
                    price: Math.min(...v.hostContract.useTypes.map(u => u.price)),
                })}));
            // setUserTags(data.userTags.map(u =>
            //     ({...u, mapId: `u${u.id}`, type: 'user', latitude: u.lat, longitude: u.lng})));
        });
    }, []);

    useEffect(() => {
        // if (location.hash && dooriVehicles.length > 0) {
        //     setMapOpen((prev) => true);
        // }
    }, [location.hash, vehicles]);

    useEffect(() => {
        if (vehicles !== null && userTags !== null && mapOpen === null) {
            if (parsed?.isList) {
                setShowVehicle(true);
            } else {
                setMapOpen((prev) => true);
            }
        }
    }, [vehicles]);

    // useEffect(() => {
    //     let result = dooriVehicles.slice();
    //     result = filter(result, (v) => get(v, "longitude"));
    //     result = filter(result, (v) => get(v, "latitude"));
    //     result = filter(result, (v) => get(v, "address"));
    //     if (mapBound) {
    //         result = filter(result, (v) => isCollision(mapBound, v));
    //     }
    //     setFilteredVehicles(result);
    // }, [vehicles, mapBound]);

    const filterVehicle = () => {
        if (!form.visibleVehicles) {
            return [];
        }
        return sortVehicle(form.visibleVehicles);
        // let result = sortVehicle(visibleVehicles).slice();
        // return filter(result, (v) => find(v.hostContract.useTypes, (u) => u.id === 'PRIORITY_GUEST'));
    }

    const sortVehicle = (vs) => {
        if (form.sorter === '가까운거리순' && path.longitude && path.latitude) {
            return sortBy(vs, (v) => calcDistance(v.latitude, v.longitude, path.latitude, path.longitude));
        }
        return vs;
    }

    const getCurrentPosition = () => {
        openAddress(callbackAddress);
    }

    const isHost = () => {
        if (isAdmin(tokenInfo?.id)) {
            return true;
        }
        const vehicle = find(vehicles, (v) => (tokenInfo?.id == v.hostUser.id));
        return vehicle;
    }

    const callbackAddress = (address, longitude, latitude) => {
        dispatch(pathAction.update({
            address: address,
            longitude: longitude,
            latitude : latitude,
            isGps : false,
        }));

        if (!isEmpty(tokenInfo)) {
            updateUser({
                variables: {
                    id: tokenInfo.id,
                    data: {
                        lng: longitude,
                        lat: latitude,
                        address: `${address}\t`,
                    }
                }
            }).then(res => {
            });
        };
    }

    const getUseType = () => {
        let tags = form.tags.split(',');
        if (tags.length === 1 && tags[0]) {
            return tags[0];
        }
        return null;
    }

    return <div className={className}>
        {/*<StyledFilter vehicles={filteredVehicles} form={form} onChange={onChange} reset={reset}></StyledFilter>*/}

        <AddressRequest onClick={getCurrentPosition}>
            <img src="/vehicle/my_position.svg"/>
            {needPath()? '이곳을 눌러 주소를 설정하면 가까운 차량을 먼저 볼 수 있습니다.': `${path.address} 에서 가까운 차량 순으로 표시됩니다. ` }
        </AddressRequest>

        <EventBanner>
            <EventSwipe images={[{link: '/guide', url: '/event/guest_banner2.png'}]} />
        </EventBanner>

        <VehicleFilter form={form} onChange={onChange} vehicles={vehicles} />

        {/*{form.tags.includes('PRIORITY_GUEST') && <DescriptionBox>*/}
        {/*    ‘게스트 우선형’은 내 주차장에 두고 탈 수 있는 차량입니다. 거리 상관 없이, 맘에 드는 차를 골라 매칭을 요청해보세요!*/}
        {/*</DescriptionBox>}*/}
        <DescriptionBox>
            ‘게스트 우선형’은 내 주차장에 두고 탈 수 있는 차량입니다. 거리 상관 없이, 맘에 드는 차를 골라 매칭을 요청해보세요!
        </DescriptionBox>

        <Vehicles>
            {showVehicle && vehicles && filterVehicle().map((v, idx) =>
                (<ListVehicle2 key={v.id} vehicle={v} imageHide={true} useType={getUseType()}/>)
            ).reduce((acc, x, idx2) => acc === null ? [x] : [acc,
                <MyDivider />,
                x], null)}
        </Vehicles>

        {window?.kakao?.maps && vehicles && userTags && (!tokenInfo || userInfo) && !mapOpen &&
            <div className="map-btn" onClick={() => setMapOpen((prev) => true)}>지도 표시하기</div>
        }

        {(!vehicles || (tokenInfo && !userInfo)) && <LoadingIndicator isVisible={true}/>}

        {window?.kakao?.maps && vehicles && userTags && (!tokenInfo || userInfo) && <VehicleMap vehicles={vehicles} filteredVehicles={filteredVehicles} guestVehicles={[]} guestTags={isHost()? userTags :[]} setMapBound={setMapBound} open={mapOpen} onClose={() => {
            setMapOpen(() => false);
            setShowVehicle(true);
            if (window.history.replaceState) {
                window.history.replaceState(null, null, '/');
            }
        }}/>}

    </div>
}

const DescriptionBox = styled.div`
  word-break: keep-all;
  font-size: 16px;
  line-height: 140%;
  margin: 4px 22px 8px;
  padding: 8px;
  color: #444444;
  font-weight: 500;
  background-color: #DDDDDD;
  text-align: center;
  border-radius: 4px;
`

const VehicleListPage = styled(_VehicleListPage)`
  font-family: 'Pretendard',sans-serif;
  > .map-btn {
    display: flex;
    align-items: center;
    justify-content: center;


    background: #5ECDC0;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 999px;
    
    color: white;
    cursor: pointer;

    padding: 11px 16px;

    position: fixed;
    bottom: 10px;
    z-index: 50;
    left:50%;
    transform:translateX(-50%);
  }
`

export default VehicleListPage;

const EventBanner = styled.div`
    margin: 0 22px;
`
