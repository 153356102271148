import moment from "moment";

export const getNextMonthLastDay = () => {
    let now = moment.tz('Asia/Seoul');
    now.add(2, 'month');
    now.date(1);
    now.hour(0);
    now.minute(0);
    now.second(0);
    now.millisecond(0);
    now.subtract(1, 'day');
    return now;
}

const holiday = [
    '01-01', // 신정
    '03-01', // 삼일절
    '05-05', // 어린이날
    '06-06', // 현충일
    '08-15', // 광복절
    '10-03', // 개천절
    '10-09', // 한글날
    '12-25', // 성탄절
];

const customHoliday = [
    '2022-06-01', // 2022 지방선거
    '2022-09-09', // 2022 추석연휴
    '2022-09-10', // 2022 추석
    '2022-09-11', // 2022 추석연휴
    '2022-09-12', // 2022 추석대체휴일
    '2022-10-10', // 2022 한글날대체휴일
    '2023-01-21', // 2023 설날연휴
    '2023-01-22', // 2023 설날
    '2023-01-23', // 2023 설날연휴
    '2023-01-24', // 2023 설날대체휴일
    '2023-05-27', // 2023 부처님오신날
    '2023-09-28', // 2023 추석연휴
    '2023-09-29', // 2023 추석
    '2023-09-30', // 2023 추석연휴
    '2024-09-16', // 2024 추석연휴
    '2024-09-17', // 2024 추석
    '2024-09-18', // 2024 추석연휴
];

export const isWeekend = (dateStr) => {
    const date = moment.tz(dateStr, 'Asia/Seoul');
    const day = date.day();
    if (day === 0 || day === 6) {
        return true;
    } else if (holiday.includes(date.format('MM-DD'))) {
        return true;
    } else if (customHoliday.includes(date.format('YYYY-MM-DD'))) {
        return true;
    }
    return false;
}
