import {useEffect, useLayoutEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useWindowSize} from "react-use";
import {useSelector} from "react-redux";

const _VehicleThumbnail = ({className, image, children}) => {
    const size = useWindowSize();
    const imgWrap = useRef();
    const img = useRef();
    const [vehicleRatio, setVehicleRatio] = useState(null);
    const [imgClassName, setImgClassName] = useState('');

    useLayoutEffect(() => {
        if (!imgClassName) {
            const _vehicleRatio = img?.current?.width / img?.current?.height;
            setVehicleRatio(_vehicleRatio);
        }
    }, [img?.current?.width]);

    useLayoutEffect(() => {
        if (vehicleRatio) {
            const containerRatio = imgWrap?.current?.clientWidth / imgWrap?.current?.clientHeight;
            const name = vehicleRatio > containerRatio ? 'height-fix': 'width-fix';
            setImgClassName(name);
        }
    }, [vehicleRatio, size])

    return <div className={className} ref={imgWrap}>
        <img className={imgClassName} ref={img} src={image.url} />
        <>{children}</>
    </div>
};
export const VehicleThumbnail = styled(_VehicleThumbnail)`
  position: relative;
  box-sizing: border-box;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  margin-right: 8px;
  overflow: hidden;
  height: 120px;
  width: 140px;
  > img {
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
  }

  > .width-fix {
    width: 100%;
  }
  > .height-fix {
    height: 100%;
  }
`
