import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ImageIcon from '@mui/icons-material/Image';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useCallback, useEffect, useRef, useState} from "react";
import {filter, find, isEmpty, isEqual} from "lodash";
import {getLoginLink} from "../../toolbox/format";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import moment from "moment-timezone";
import 'moment/locale/ko';
import {loadingAction} from "../../redux/loadingReducer";
import {isAdmin} from "../../toolbox/logic";
import {Fullscreen} from "@mui/icons-material";
import {FullScreen} from "../../layout/FullScreen";

export const ChatDetailPage = () => {
    const params = useParams();
    const roomId = params.id;

    const pollInterval = useRef(null);

    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const location = useLocation();
    const navigate = useNavigate();
    const [messages, setMessages] = useState(null);
    const [readAt, setReadAt] = useState(null);
    const [users, setUsers] = useState(null);
    const [contract, setContract] =useState(null);
    const [loaded, setLoaded] =useState(false);
    const [manager, setManager] = useState(false);

    const messagesEndRef = useRef(null);

    let myId = tokenInfo?.id;
    if (manager) {
        myId = 'MANAGER';
    }

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    const getOtherUser = () => {
        if (!users) {
            return null;
        }
        const otherUsers = filter(users, u => String(u.id) !== String(myId));
        if (otherUsers.length) {
            return otherUsers[0];
        }
        return null;
    }

    const getOtherUsers = () => {
        if (!users) {
            return null;
        }
        const otherUsers = filter(users, u => String(u.id) !== String(myId));
        return otherUsers;
    }

    const getChatRoomLink = () => {
        if (!loaded) {
            return '';
        }
        const otherUser = getOtherUser();
        if (contract?.users_permissions_user?.id) {
            if (String(myId) !== String(contract.users_permissions_user.id)) {
                return "/vehicle/" + contract.vehicle.id;
            }
        }
        if (otherUser) {
            return "/user/" + otherUser.id;
        }
        return null;
    }

    const getChatRoomTitle = () => {
        if (!loaded) {
            return '';
        }
        const otherUser = getOtherUser();
        if (contract?.users_permissions_user?.id) {
            if (String(myId) !== String(contract.users_permissions_user.id)) {
                return <>
                    <ProfileImage src={contract.users_permissions_user?.profile_image?.url ?? '/layout/char_doori.svg'} />
                    <span>{contract.vehicle.brand} {contract.vehicle.model} 호스트 ({contract.vehicle.numberPlate})</span>
                </>
            }
        }
        if (otherUser && contract) {
            return <>
                <ProfileImage src={otherUser?.profile_image?.url ?? '/layout/char_doori.svg'} />
                <span>{otherUser.nickname} 게스트 ({contract?.vehicle?.brand} {contract?.vehicle?.model})</span>
            </>
        }

        const otherUsers = getOtherUsers();
        if (!otherUsers) {
            return '';
        }

        if (otherUsers.length === 1) {
            return <>
                <ProfileImage src={otherUsers[0].profile_image?.url ?? '/layout/char_doori.svg'} />
                <span>{otherUsers[0].nickname} 게스트</span>
            </>
        }

        if (otherUsers.length) {
            return <>
                <span>{otherUsers.map(u => u.nickname).join(', ')}</span>
            </>
        }
        return <>
            <ProfileImage src={'/layout/logo.png'} />
            <span>두리카</span>
        </>
    }

    const refetch = (init) => {
        axios.post(SERVER_ADDRESS + '/api/chat-rooms/getMessages', {
            roomId: roomId,
            isRead: !document.hidden,
        }).then((res) => {
            const room = find(res.data.users, u => String(u.id) === String(tokenInfo.id));
            if (!room && !isAdmin(tokenInfo.id)) {
                alert('채팅방이 존재하지 않습니다.');
                return;
            }

            if (!room && isAdmin(tokenInfo.id)) {
                setManager(true);
            }

            if (!isEqual(res.data.messages, messages)) {
                setMessages(res.data.messages);
            }
            setContract(res.data.contract);
            setReadAt(res.data.readAt);
            setUsers(res.data.users);
            setLoaded(true);

            if (document.hidden && res.data.messages.length > 0) {
                let myReadAt = null;
                for (let id in res.data.readAt) {
                    if (String(id) === String(myId)) {
                        myReadAt = res.data.readAt[id];
                    }
                }

                let unread = filter(res.data.messages, m => myReadAt === null || m.sentAt > myReadAt);
                if (unread.length > 0) {
                    document.title = `두리카 (${unread.length})`;
                } else {
                    document.title = `두리카`;
                }
            }
        });
    }
    useEffect(() => {
        if (roomId) {
            setMessages(null);
            setReadAt(null);
            setContract(null);
            setUsers(null);
            setLoaded(false);
            setManager(false);
            refetch();
        }
    }, [roomId]);


    const startPolling = useCallback((interval) => {
        clearInterval(pollInterval.current);
        pollInterval.current = setInterval(() => {
            refetch();
        }, interval);
    }, [refetch]);

    const handleVisibilityChange = () => {
        if (document.hidden) {
            startPolling(60000);
        } else {
            document.title = `두리카`;
            startPolling(2000);
        }
    };

    useEffect(() => {
        startPolling(2000);
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            clearInterval(pollInterval.current);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [startPolling]);

    useEffect(() => {
        // 컴포넌트가 마운트되거나 메시지가 업데이트될 때 스크롤을 맨 아래로 이동
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleSend = (message) => {
        let api = 'sendMessage';
        if (manager) {
            api = 'sendMessageAdmin';
        }
        axios.post(SERVER_ADDRESS + '/api/chat-rooms/' + api, {
            chatRoomId: roomId,
            message: {
                type: 'text',
                content: message,
            },
        }).then((res) => {
            refetch();
        });
    }

    const handleUpload = async (file) => {
        const data = new FormData();
        data.append('files', file);
        data.append('ref', 'api::chat-room.chat-room');
        data.append('refId', roomId);
        data.append('field', 'uploaded');

        dispatch(loadingAction.loading(true));
        const res = await axios.post(SERVER_ADDRESS + '/api/upload', data);
        let api = 'sendMessage';
        if (manager) {
            api = 'sendMessageAdmin';
        }
        await axios.post(SERVER_ADDRESS + '/api/chat-rooms/' + api, {
            chatRoomId: roomId,
            message: {
                type: 'image',
                content: res.data[0].url,
            },
        });
        refetch();
        dispatch(loadingAction.loading(false));
    }

    return <FullScreen>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {getChatRoomLink() && <Link to={getChatRoomLink()}>
                <div style={{fontSize: '20px', padding: '16px 12px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px'}}>
                    {getChatRoomTitle()}
                </div>
            </Link>}
            {!getChatRoomLink() && <div style={{fontSize: '20px', padding: '16px 12px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px'}}>
                {getChatRoomTitle()}
            </div>}
            <Wrap>
                {messages && messages.map((m, idx) => {
                    const prev = idx > 0 ? messages[idx - 1]: null;
                    const sent = moment.tz(m.sentAt, 'Asia/Seoul');
                    const isMy = String(m.sender) === String(myId);
                    const sentTxt = sent.format('A h:mm');
                    let sender = find(users, u => String(u.id) === String(m.sender));
                    if (m.sender === 'MANAGER') {
                        sender = {
                            'nickname': '두리카 매칭매니저',
                            'profile_image': {url: '/layout/logo.png'},
                        }
                    }
                    if (getOtherUsers().length === 0) {
                        sender = {
                            'nickname': '두리카',
                            'profile_image': {url: '/layout/logo.png'},
                        }
                    }

                    let hideNickname = false;
                    if (prev && prev.sender === m.sender && sent.format('YYYY-MM-DD HH:mm') === moment.tz(prev.sentAt, 'Asia/Seoul').format('YYYY-MM-DD HH:mm')) {
                        hideNickname = true;
                    }

                    let isRead = null;
                    const otherUsers = getOtherUsers();
                    if (isMy && otherUsers.length >= 1) {
                        let count = 0;
                        for (let id in readAt) {
                            let otherUser = find(otherUsers, u => String(u.id) === String(id));
                            if (otherUser) {
                                if (readAt[id] >= m.sentAt) {
                                    count += 1;
                                }
                            }
                        }
                        if (count) {
                            if (otherUsers.length === 1) {
                                isRead = '읽음';
                            } else {
                                isRead = `읽음 ${count}`;
                            }
                        }
                    }
                    if (!prev || sent.format('YYYY-MM-DD') !== moment.tz(prev.sentAt, 'Asia/Seoul').format('YYYY-MM-DD')) {
                        return <>
                            <SystemMessage text={sent.format('YYYY년 M월 D일 dd')} />
                            <UserMessage isOwnMessage={isMy} prev={prev && prev.sender === m.sender} senderId={m.sender} type={m.type} text={m.content} hideNickname={hideNickname} isRead={isRead} nickname={sender?.nickname} time={sentTxt} profileImage={sender?.profile_image?.url} />
                        </>
                    }
                    return <UserMessage isOwnMessage={isMy} prev={prev && prev.sender === m.sender} senderId={m.sender} type={m.type} text={m.content} hideNickname={hideNickname} isRead={isRead} nickname={sender?.nickname} time={sentTxt} profileImage={sender?.profile_image?.url} />
                })}
                <div ref={messagesEndRef}></div>
            </Wrap>
            {isAdmin(tokenInfo?.id) && <div><input type="checkbox" checked={manager} onChange={() => setManager(!manager)} /> 매칭매니저 모드</div>}
            <ChatInput onSend={handleSend} roomId={roomId} onUpload={handleUpload} />
        </div>
    </FullScreen>
}

const Wrap = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-grow: 1;
  height: 100%;
  //height: calc(100vh - 300px);
  overflow-y: auto;
`

const ChatInputContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
`;

const TextInput = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  align-items: center;

  &:focus {
    outline: none;
    border-color: #888;
  }
  
  .image {
    padding: 10px 10px 6px;
    height: 100%;
    flex-grow: 1;
    cursor: pointer;
  }
  
  textarea {
    font-size: 14px;
    padding: 10px 10px 10px 0;
    width: 100%;
    resize: none;
    overflow-y: auto;
    border: none;
    max-height: 26px;
    &:focus {
      outline: none;
      border-color: #888;
    }
  }
`;

const SendButton = styled.button`
  margin-left: 10px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ChatInput = ({ onSend, onUpload }) => {
    const [message, setMessage] = useState('');
    const textareaRef = useRef(null);
    const [isComposition, setIsComposition] = useState(false);
    const fileRef = useRef();

    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const handleKeyDown = (e) => {
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        if (!isMobile && e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };


    const handleInput = () => {
        textareaRef.current.style.height = 'auto'; // 초기화
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight - 18}px`;
    };

    const handleSend = () => {
        if (message.trim() && !isComposition) {
            onSend(message.trim());
            setMessage('');
        }
    };

    const handleImage = async (e) => {
        for (let file of e.target.files) {
            onUpload(file);
        }
        e.target.value = null;
    }

    return (
        <ChatInputContainer>
            <TextInput>
                <div className="image" onClick={() => fileRef.current.click()}>
                    <AddCircleIcon/>
                </div>
                <textarea
                    ref={textareaRef}
                    value={message}
                    onChange={handleChange}
                    onInput={handleInput}
                    onCompositionStart={() => setIsComposition(true)}
                    onCompositionEnd={() => setIsComposition(false)}
                    rows={1}
                    onKeyDown={handleKeyDown}
                    placeholder="메시지를 입력하세요..." />
            </TextInput>
            <SendButton onClick={handleSend}>전송</SendButton>
            <input style={{display: 'none'}} type="file" accept="image/jpeg, image/png" onChange={handleImage} ref={fileRef} multiple={true}/>
        </ChatInputContainer>
    );
};

const SystemMessageContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;

  > div {
    line-height: 120%;
    text-align: center;
    white-space: pre-wrap;
    padding: 4px 12px;
    border-radius: 12px;
    background-color: #a8a8a8;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  gap: 6px;
  ${(props) => (props.isOwnMessage ? 'flex-direction: row-reverse;' : 'justify-content: row;')}
  ${(props) => (props.topMargin ? 'margin-top: 8px;' : '')}
`;

const ContentContainer = styled.div`
  max-width: 75%;
  display: flex;
  flex-direction: column;
  ${(props) => (props.profileHide ? 'margin-left: 46px;' : '')}
`

const ContentContainer2 = styled.div`
  display: flex;
  gap: 6px;
  ${(props) => (props.isOwnMessage ? 'flex-direction: row-reverse;' : 'justify-content: row;')}
`

const ImageContent = styled.div`
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  
  img {
    width: 100%;
    display: block;
  }
`;

const MessageContent = styled.div`
  padding: 12px;
  border-radius: 10px;
  background-color: ${(props) => (props.isOwnMessage ? '#dcf8c6' : '#bcd6ff')};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  width: fit-content;
`;

const MessageText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 130%;
  flex-shrink: 1;
`;

const MessageInfo = styled.div`
  font-size: 12px;
  color: #888;
  margin-top: 5px;
  text-align: right;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Nickname = styled.span`
  margin-top: 4px;
  font-weight: bold;
  margin-left: 2px;
  margin-bottom: 5px;
  display: block;
`;

const ReadStatus = styled.div`
  margin-bottom: 2px;
`;

const SystemMessage = ({text}) => {
    return <SystemMessageContainer>
        <div>{text}</div>
    </SystemMessageContainer>
}
const UserMessage = ({ isOwnMessage, hideNickname, profileImage, senderId, nickname, type, text, time, isRead, prev }) => {
    let link = null;
    if (senderId !== 'MANAGER') {
        link = '/user/' + senderId;
    }
    return (
        <MessageContainer isOwnMessage={isOwnMessage} topMargin={(!hideNickname && !isOwnMessage) || (isOwnMessage && !prev)}>
            {!isOwnMessage && (hideNickname? null:
                    link? <>
                        <div style={{width: '40px', height: '40px'}}>
                            <Link to={link}>
                                <ProfileImage src={profileImage ?? '/layout/char_doori.svg'} alt={`${nickname}'s profile`} />
                            </Link>
                        </div>
                    </>:<>
                        <ProfileImage src={profileImage ?? '/layout/char_doori.svg'} alt={`${nickname}'s profile`} />
                    </>
            )}
            {/*{!isOwnMessage && (hideNickname? null: <ProfileImage src={profileImage ?? '/layout/char_doori.svg'} alt={`${nickname}'s profile`} />)}*/}
            <ContentContainer profileHide={hideNickname && !isOwnMessage}>
                {!isOwnMessage && (hideNickname? null: link?
                    <Nickname><Link to={link}><span>{nickname}</span></Link></Nickname>:
                    <Nickname><span>{nickname}</span></Nickname>)}

                <ContentContainer2 isOwnMessage={isOwnMessage} >
                    {type === 'image' && <ImageContent isOwnMessage={isOwnMessage}>
                        <img src={text} />
                    </ImageContent>}
                    {type === 'text' && <MessageContent isOwnMessage={isOwnMessage}>
                        <MessageText>{text}</MessageText>
                    </MessageContent>}
                    <div style={{whiteSpace: 'nowrap', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginTop: 'auto'}}>
                        <MessageInfo isOwnMessage={isOwnMessage}>
                            {(isOwnMessage && isRead) && <ReadStatus>{isRead}</ReadStatus>}
                            <div>
                                <span>{time}</span>
                            </div>
                        </MessageInfo>
                    </div>
                </ContentContainer2>
                {/*<MessageContent isOwnMessage={isOwnMessage} profileHide={hideNickname && !isOwnMessage}>*/}
                {/*    {type === 'image' && <img style={{width: '100%', borderRadius: '4px'}} src={text} />}*/}
                {/*    {type === 'text' && <MessageText>{text}</MessageText>}*/}
                {/*</MessageContent>*/}
            </ContentContainer>
        </MessageContainer>
    );
};
