
export const GUEST_TAGS = [
    {
        title: "어떤 차가 필요하신가요? (복수선택 가능)",
        tags: [
            {id: "매니저님이랑 상담 후 결정할게요.", tag: "잘 모르겠어요. 매니저님이랑 상담 후 결정할게요.", unique: false, default: true},
            {id: "주말에만 탈 차가 필요해요.", tag: "주말에만 탈 차가 필요해요."},
            {id: "평일에만 탈 차가 필요해요.", tag: "평일에만 탈 차가 필요해요."},
            {id: "매주 탈 요일을 고정해서 타고 싶어요.", tag: "매주 탈 요일을 고정해서 타고 싶어요."},
            {id: "미리 정하지 않고 한달에 10~15일 정도 탈 차가 필요해요.", tag: "미리 정하지 않고 한달에 10~15일 정도 탈 차가 필요해요."},
            {id: "렌터카처럼 몇달 간 혼자 탈 차가 필요해요.", tag: "렌터카처럼 몇달 간 혼자 탈 차가 필요해요."},
            {id: "차가 괜찮으면 호스트의 이용패턴에 맞춰볼게요.", tag: "차가 괜찮으면 호스트의 이용패턴에 맞춰볼게요."},
        ]
    },
];
