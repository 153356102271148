import styled, {css} from "styled-components";
import {Mannerbox} from "./Mannerbox";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const _ProgressBar = ({className}) => {
    return <div className={className}>
        <div className="inner" />
    </div>
}

const ProgressBar = styled(_ProgressBar)`
  height: 6px;
  width: 40px;
  background-color: #e0e0de;
  border-radius: 50px;
  
  > .inner {
    height: 100%;
    border-radius: inherit;
    background-color: green;
    ${(props) => css`
      width: ${props.pct + '%'};
    `
    }
  }
`

const _HostIntroduce = ({vehicle, className, tag, children}) => {
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    // const testUser = {
    //     nickname : '변성환',
    //     address: '서울 역삼동',
    //     profile_image: {
    //         url: 'https://dooricar.s3.ap-northeast-2.amazonaws.com/preset/aaaac_78ac212c66.jpeg'
    //     }
    // }
    return <div className={className}>
        {vehicle?.user?.id?
            // <Link to={"/user/" + vehicle?.user?.id}>
                <div className="--left_box">
                    <div className="--image_wrap">
                        <img src={vehicle?.user?.profile_image?.url ?? '/layout/char_doori.svg'}></img>
                    </div>
                    <div className="--name_box">
                        <div>{vehicle?.user?.nickname} {tag? `[${tag}]`: ''}</div>
                        <div>{vehicle?.address}</div>
                    </div>
                </div>
            // </Link>
            :
            <div className="--left_box">
                <div className="--image_wrap">
                    <img src={vehicle?.user?.profile_image?.url ?? '/layout/char_doori.svg'}></img>
                </div>
                <div className="--name_box">
                    <div>{vehicle?.user?.nickname} {tag? `[${tag}]`: ''}</div>
                    <div>{vehicle?.address}</div>
                </div>
            </div>}
        {vehicle?.user?.id &&
            <div className="manner-liter">
                {vehicle?.user?.id === tokenInfo?.id &&
                    <Link to={`/vehicle_modify?id=${vehicle.contract}`}>
                        <div style={{fontSize: '14px', cursor: 'pointer', borderRadius: '4px', border: '1px solid black', padding: '2px 4px'}}>
                            {/*<img src="/my/edit_black.svg"/>*/}
                            정보 수정하기
                        </div>
                    </Link>}
                <Mannerbox liter={36} />
            </div>}
    </div>
}

const HostIntroduce = styled(_HostIntroduce)`
  position: relative;
  display: flex;
  box-shadow: 0px -6px 20px -9px rgba(0, 0, 0, 0.18);
  justify-content: space-between;
  .--left_box {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    
    .--image_wrap {
      width: 48px;
      height: 48px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        //border: 1px solid black;
      }
    }

    .--name_box {
      //box-sizing: border-box;
      //display: flex;
      //flex-direction: column;
      //justify-content: space-between;
      padding-left: 12px;
      //height: 100%;
      > :first-child {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 6px;
      }
      > :nth-child(2) {
        color: #666666;
        font-size: 14px;
        margin-top: 6px;
      }
    } 
  }
  
  > .manner-liter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 12px;
  }
`;

export default HostIntroduce;
