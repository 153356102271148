import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {find, get, isEmpty} from "lodash";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {commaNum} from "../../../toolbox";
import {useQuery} from "@apollo/client";
import {HelpBtn} from "../../../toolbox/UtilComponent";
import {loadingAction} from "../../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../../index";
import {convertDooricar, getLoginLink, getPricePlan, getUseTypeId} from "../../../toolbox/format";
import queryString from "query-string";
import {GET_MATCHING_REQUEST} from "../../../query/vehicleQuery";
import {flatEntity} from "../../../toolbox/query";
import {FormComponent} from "../VehicleAdd2/FormComponent";
import moment from "moment-timezone";
import {DisabledBtn, PositiveBtn} from "../VehicleAdd2/BottomNavigator";
import {isAdmin} from "../../../toolbox/logic";
import {TinyVehicle} from "../component/ListVehicle2";
import {CollapseBtn} from "../VehiclePage";
import ReactMarkdown from "react-markdown";

const MatchingViewPage = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const guestId = parsed?.guestId;
    const matchingId = params.id;
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const user = useSelector(({user}) => user.user);

    const {data, refetch, loading} = useQuery(GET_MATCHING_REQUEST, {variables: {id: matchingId}});
    const [vehicle, setVehicle] = useState(null);
    const [matchingRequest, setMatchingRequest] = useState(null);
    const [collapse, setCollapse] = useState(false);

    useEffect(() => {
        if (data) {
            const payload = flatEntity(get(data, "matchingRequest.data", null));
            const vehicle = get(payload, "contract.vehicle", null);
            if (!payload || !vehicle) {

            } else {
                if (!isAdmin(tokenInfo.id) && ((payload.receiver.id !== tokenInfo.id) && (payload.sender.id !== tokenInfo.id))) {
                    alert('존재하지 않는 요청입니다.');
                    navigate("/")
                } else {
                    if (payload) {
                        setMatchingRequest(payload);
                    }
                    if (vehicle) {
                        let v = convertDooricar(vehicle, true, true);
                        if (v) {
                            setVehicle(v);
                        } else {
                            alert('이미 매칭이 되었거나 제거된 차량입니다.');
                            navigate("/");
                        }
                    } else {
                        alert('이미 매칭이 되었거나 제거된 차량입니다.');
                        navigate("/");
                    }
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    const getOriginPrice = () => {
        const form = matchingRequest?.detail;
        const useTypeTxt = form?.매칭유형;
        const useType = find(vehicle.useTypes, (u) => u.id === getUseTypeId(useTypeTxt));
        if (useType) {
            return useType.price;
        }
        return '가격 정보 삭제됨';
    }

    const getPrice = () => {
        const isEvent = getIsEvent();
        let originPrice = getOriginPrice();
        if (isEvent) {
            originPrice = originPrice * 0.9;
        }
        return originPrice;
    }

    const getIsEvent = () => {
        const form = matchingRequest?.detail;
        return vehicle.eventPrice !== false && form["6개월 약정 여부"] === '적용'
    }

    const getPriceTxt = () => {
        if (!matchingRequest) {
            return '';
        }
        const form = matchingRequest?.detail;
        let originPrice = getPrice();
        const plan = getPricePlan({
            isSocarEvent: false,
            originMonthlyCharge: originPrice,
            startDate: form["시작 희망일"],
            endDate: form["예상 이용기간"],
        });

        let result = [];
        for (let month of plan) {
            let paymentDate = moment.tz(month.paymentDate, 'Asia/Seoul');
            let startDate = moment.tz(month.startDate, 'Asia/Seoul');
            let endDate = moment.tz(month.endDate, 'Asia/Seoul');
            let charge = month.charge;
            let monthTxt = [
                `${paymentDate.month() + 1}월 수익금 적립 예정금액`,
                `${startDate.month() + 1}월 이용료: ${commaNum(charge)}원 (${startDate.format('YY. M. D.')} ~ ${endDate.format('YY. M. D.')})`
            ]
            monthTxt = monthTxt.concat([
                `수수료(7%): -${commaNum(charge - (Math.round(charge * 0.93 / 10 ) * 10))}원`,
                `합계: ${commaNum(Math.round(charge * 0.93 / 10 ) * 10)}원\n`,
            ]);
            result = result.concat(monthTxt);
        }
        return result.join('\n');
    }

    const getPriceTxt2 = () => {
        if (!matchingRequest) {
            return '';
        }
        const form = matchingRequest?.detail;
        const isEvent = getIsEvent();
        let originPrice = getPrice();
        const deposit = getOriginPrice() * 3;
        const plan = getPricePlan({
            isSocarEvent: false,
            originMonthlyCharge: originPrice,
            startDate: form["시작 희망일"],
            endDate: form["예상 이용기간"],
        });

        let result2 = [];
        for (let month of plan) {
            let paymentDate = moment.tz(month.paymentDate, 'Asia/Seoul');
            let startDate = moment.tz(month.startDate, 'Asia/Seoul');
            let endDate = moment.tz(month.endDate, 'Asia/Seoul');
            let charge = month.charge;
            let monthTxt = [
                `${paymentDate.month() + 1}월 결제예정금액`,
                `${startDate.month() + 1}월 이용료: ${commaNum(charge)}원 (${startDate.format('YY. M. D.')} ~ ${endDate.format('YY. M. D.')})`
            ]

            if (result2.length === 0) {
                if (isEvent) {
                    monthTxt = monthTxt.concat([
                        `보증금: ${commaNum(deposit)}원`,
                        '장기계약할인금액: -100,000원',
                        `합계: ${commaNum(charge + deposit - 100000)}원\n`,
                    ]);
                } else {
                    monthTxt = monthTxt.concat([
                        `보증금: ${commaNum(deposit)}원`,
                        `합계: ${commaNum(charge + deposit)}원\n`,
                    ]);
                }
            } else {
                monthTxt = monthTxt.concat([
                    `합계: ${commaNum(charge)}원\n`,
                ]);
            }
            result2 = result2.concat(monthTxt);
        }
        return result2.join('\n');
    }

    const submit = () => {
        dispatch(loadingAction.loading(true));
        axios.post(SERVER_ADDRESS + '/api/vehicles/matchingRequestReply', {
            thread: matchingRequest?.thread_ts,
            payload: "매칭 수락",
        }).then((res) => {
            if (res.data) {
                alert('매칭 요청이 수락되었어요. 🎉\n' +
                    '담당 매칭매니저가 세부사항 조율을 위해 영업시간(평일 10:00 ~ 19:00) 내에 개별적으로 연락을 드릴 예정이오니 조금만 기다려주세요.\n' +
                    '매칭이 먼저 성사된 차량부터 순차적으로 연락드리고 있으며, 이에 따라 시간이 다소 소요될 수 있는 점 양해부탁드립니다.\n' +
                    '감사합니다.');
                refetch().then(() => {
                    dispatch(loadingAction.loading(false));
                    window.scrollTo(0, 0);
                });
            } else {
                alert('이미 처리되었거나 제거된 요청입니다.');
                dispatch(loadingAction.loading(false));
                navigate('/');
            }
        });
    }

    const 차고지 = () => {
        if (matchingRequest.detail.매칭유형 === '게스트우선형') {
            return {차고지: `${matchingRequest.sender.address.split('\t')[0]} (게스트차고지)`};
        }
        return {차고지: `${vehicle.address} (호스트차고지)`};
    }

    const 호스트토큰 = () => {
        if (matchingRequest.detail.매칭유형 === '게스트우선형') {
            return 0;
        } else if (matchingRequest.detail.매칭유형 === '호스트우선형') {
            return '무제한';
        }
        return 15;
    }

    const 게스트토큰 = () => {
        if (matchingRequest.detail.매칭유형 === '게스트우선형') {
            return '무제한';
        } else if (matchingRequest.detail.매칭유형 === '호스트우선형') {
            return 10;
        }
        return 15;
    }

    if (!matchingRequest) {
        return <div></div>
    }

    return <Wrap>
        <div className="forms">

            {matchingRequest.status === 'ACCEPT'?
                <h3>{matchingRequest.receiver.id === tokenInfo.id? `${matchingRequest?.sender?.nickname}님의`: `${vehicle.brand} ${vehicle.model} (${vehicle?.numberPlate}) 차량에 대한`} 매칭 요청이 수락되었습니다. 🙌<br/>
                    담당 매칭매니저가 세부사항 조율을 도와드립니다. <HelpBtn title={"매칭매니저 안내"} contents={"\n담당 매칭매니저가 세부사항 조율을 위해 영업시간(평일 10:00 ~ 19:00) 내에 개별적으로 연락을 드립니다.\n\n" +
                        "매칭이 먼저 성사된 차량부터 순차적으로 연락드리고 있으며, 이에 따라 시간이 다소 소요될 수 있는 점 양해부탁드립니다."} /><br/><br/>
                    호스트 : {matchingRequest?.receiver?.nickname}<br/>게스트 : {matchingRequest?.sender?.nickname}
                </h3>:
                <h3>{matchingRequest?.sender?.nickname}님이 {matchingRequest?.receiver?.nickname}님의 차량({vehicle?.numberPlate})에 매칭을 요청하셨습니다. 🙌</h3>

            }

            {(isAdmin(tokenInfo?.id) || matchingRequest.status === 'ACCEPT')? <>
                <div>
                    <h2>차량 정보</h2>
                    <TinyVehicle vehicle={vehicle} imageHide={false} hideManner={true}/>
                    <div>
                        <h3 style={{marginTop: '16px', marginLeft: '8px', fontSize: '16px', marginBottom: '10px'}}>매칭 게시글 내용</h3>
                        <TextBox>
                            {vehicle.title}<br/><br/>
                            {vehicle.description}
                        </TextBox>
                    </div>

                    <div>
                        <h3 style={{marginTop: '16px', marginLeft: '8px', fontSize: '16px', marginBottom: '10px'}}>보험 정보</h3>
                        <TextBox>
                            {matchingRequest?.contract?.insuranceInfo && Object.entries(matchingRequest?.contract?.insuranceInfo).map(([k, v]) => {
                                    if (typeof(v) === 'string') {
                                        return v?.includes('\n')? <>
                                            <br/>{k}<br/>{v? v: '미입력'}
                                        </>: <>
                                            {k} : {v? v: '미입력'}<br/>
                                        </>;
                                    }
                                    return <>
                                        {k} : {v? v: '미입력'}<br/>
                                    </>;
                                }
                            )}
                            {!matchingRequest?.contract?.insuranceInfo && "미입력"}
                        </TextBox>
                    </div>
                </div>

                <div>
                    <h2>매칭 신청 내용</h2>
                </div>
                <FormComponent disabled id="생년월일" title="생년월일" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="성별" title="성별" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="흡연여부" title="흡연여부" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="반려동물 탑승 방법" title="반려동물 탑승방법" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="예상 운행거리" title="예상 월운행거리 (km)" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="추가운전자" title="희망 추가운전자" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled rows={8} type="textarea" id="호스트에게 한마디" title="호스트에게 한마디" form={matchingRequest?.detail} onChange={() => {}} /><FormComponent disabled id="매칭유형" title="매칭유형" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="시작 희망일" title="시작 희망일" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="예상 이용기간" title="종료일" form={matchingRequest?.detail} onChange={() => {}} />

                <div>
                    <h2>매칭 세부 사항</h2>
                </div>
                <FormComponent disabled id="차고지" title="차고지" form={차고지()} onChange={() => {}} />

                {vehicle.eventPrice !== false && <FormComponent disabled id="a" title="장기할인 여부" form={{a: getIsEvent()? '적용 (6개월이상계약)': '미적용 (6개월미만계약)'}} onChange={() => {}} />}
                {getIsEvent()? <>
                        <FormComponent disabled id="a" title="월이용료 정상가 (장기할인 미적용)" form={{a: getOriginPrice()}} onChange={() => {}} />
                        <FormComponent disabled id="a" title="월이용료 할인가 (장기할인 10% 적용)" form={{a: getPrice()}} onChange={() => {}} />
                    </>:
                    <>
                        <FormComponent disabled id="a" title="월이용료" form={{a: getPrice()}} onChange={() => {}} />
                    </>}
                <FormComponent disabled id="a" title="보증금" form={{a: getOriginPrice() * 3}} onChange={() => {}} />
                <FormComponent disabled id="a" title={`거리당주유비 / 납부자: ${matchingRequest.detail.매칭유형 === '게스트우선형'? '호스트': '게스트'}`} form={{a: vehicle.kmCharge}} onChange={() => {}} />
                <FormComponent disabled id="a" title={`초과주행료 (월 2,000km 초과 시) / 납부자: 게스트`} form={{a: 100}} onChange={() => {}} />
                <FormComponent disabled id="a" title="유료예약권 1개당 금액" form={{a: getPrice() / 10}} onChange={() => {}} />
                <FormComponent disabled id="a" title="호스트 무료예약권 개수" form={{a: 호스트토큰()}} onChange={() => {}} />
                <FormComponent disabled id="a" title="게스트 무료예약권 개수" form={{a: 게스트토큰()}} onChange={() => {}} />

                <div>
                    <h3 style={{marginBottom: '16px', fontSize: '16px', fontWeight: '600'}}>게스트 월요금 일정</h3>
                    <TextBox>
                        {getPriceTxt2()}
                    </TextBox>
                </div>
                <div>
                    <h3 style={{marginBottom: '16px', fontSize: '16px', fontWeight: '600'}}>호스트 월수익 일정</h3>
                    <TextBox>
                        {getPriceTxt()}
                    </TextBox>
                </div>

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px'}}>
                    <h2>이용 정책 및 상세 규정</h2>
                    <CollapseBtn open={collapse} onCollapse={() => setCollapse(!collapse)} />
                </div>

                {collapse && <>

                    <div>
                        <h3 style={{marginBottom: '16px', fontSize: '16px', fontWeight: '600'}}>주유</h3>
                        <TextBox>
                            <ReactMarkdown children={"**호스트우선형 / 요일지정형:** 호스트가 직접 주유하며, 게스트는 주행한만큼 호스트에게 거리당주유비를 납부합니다. 게스트가 거리당주유비를 납부하는 대신, 호스트는 게스트가 운행을 할 때 문제가 없도록 본인의 계산으로 연료를 항상 충분한 상태로 유지해야합니다. 게스트는 연료가 부족할 경우 본인 계산으로 주유(또는 전기충전) 후 호스트에게 비용을 청구할 수 있습니다. (영수증 첨부 필수)\n" +
                                "\n" +
                                "**게스트 우선형:** 게스트가 직접 주유하며, 호스트는 주행한만큼 게스트에게 거리당주유비를 납부합니다. 호스트가 거리당주유비를 납부하는 대신, 게스트는 호스트가 운행을 할 때 문제가 없도록 본인의 계산으로 연료를 항상 충분한 상태로 유지해야합니다. 호스트는 연료가 부족할 경우 본인 계산으로 주유(또는 전기충전) 후 게스트에게 비용을 청구할 수 있습니다. (영수증 첨부 필수) 호스트는 계약 시작 시 연료를 가득 채운 상태로 게스트에게 차를 인도해야하며, 게스트는 계약 종료 시 연료를 가득 채운 상태로 반납해야합니다. (차량 반납을 위해 이동 중 소실되는 정도의 연료는 불가피함)\n" +
                                "\n" +
                                "**공통사항:** 통상 가격의 범위 내에 있는 일반 휘발유를 주유하는 것이 원칙이며, 만약 고급유만 주유해야하는 차량의 경우 호스트님께서 미리 말씀해주세요. 반대로 게스트님이 고급유 주유를 희망하실 경우에는 호스트님의 사전 승인이 필요합니다. (무단 결제 시 해당 결제금액은 게스트님 전액 부담임.)"}>
                            </ReactMarkdown>
                        </TextBox>
                    </div>


                    <div>
                        <h3 style={{marginBottom: '16px', fontSize: '16px', fontWeight: '600'}}>세차</h3>
                        <TextBox>
                            <ReactMarkdown children={"**호스트우선형 / 요일지정형**: 호스트가 세차를 이행하며 비용을 부담합니다. 게스트는 최소 월 1회 세차를 요구하거나 호스트가 미이행 시 직접 세차 후 비용을 청구할 수 있습니다. (월 1회를 초과하여 세차 요구 시 게스트가 비용 부담)\n" +
                                "\n" +
                                "**게스트우선형**: 게스트가 세차를 이행하며 비용을 부담합니다. 호스트는 최소 월 1회 세차를 요구하거나 게스트가 미이행 시 직접 세차 후 비용을 청구할 수 있습니다. (월 1회를 초과하여 세차 요구 시 호스트가 비용 부담)\n" +
                                "\n" +
                                "**공통사항**: 통상의 기계식 세차 외에 손세차 등 특수한 세차가 필요한 경우, 호스트가 이를 사전에 고지해야합니다."} />
                        </TextBox>
                    </div>

                    <div>
                        <h3 style={{marginBottom: '16px', fontSize: '16px', fontWeight: '600'}}>정비</h3>
                        <TextBox>
                            <ReactMarkdown children={"게스트가 납부하는 월이용료에는 차량의 유지에 필요한 관리비가 포함되어 있습니다. 따라서, 호스트는 정비, 소모품교체 등 차량의 관리를 직접 이행하며 관련 비용을 부담해야합니다. 단, ‘게스트우선형’의 경우 호스트가 차량 관리를 직접 이행하기 어려우므로, 게스트가 이행하는 것이 원칙입니다. 이 경우 게스트는 반드시 호스트에게 매번 사전 승인을 받아야합니다. (호스트가 미리 포괄적 사전 승인 및 위임을 한 경우는 건 별 사전 승인 불요) 이때 게스트는 본인의 계산으로 이행 후 호스트에게 비용을 청구할 수 있습니다.\n" +
                                "\n" +
                                "만약 호스트가 차량 관리를 지연 또는 거부하여 게스트가 차를 정상적으로 이용하는 것이 어렵다고 판단(ex. 계기판 경고등 표시, 엔진오일교환시기 도래 등)되거나, 긴급하게 정비가 필요한 경우(ex. 타이어 펑크, 심각한 공기압 이상 등)에는 게스트가 사전고지 후 직접 정비를 진행하는 등 적당한 조치를 취할 수 있으며, 이후 호스트에게 비용을 청구할 수 있습니다. \n" +
                                "\n" +
                                "차를 이용하기 어려운 정도인지, 긴급한 상황인지 여부 등에 대한 판단은 두리카의 차량관리팀에서 결정하며, 호스트와 게스트는 두리카의 판단을 따라야합니다."} />
                        </TextBox>
                    </div>

                    <div>
                        <h3 style={{marginBottom: '16px', fontSize: '16px', fontWeight: '600'}}>위로금</h3>
                        <TextBox>
                            <ReactMarkdown children={"게스트는 보험처리 시 호스트에게 보험료 할증, 감가상각, 수리기간 동안 차를 이용하지 못하는 손해 등에 대한 포괄적인 보상으로 ‘위로금’을 지급해야합니다. (보험사에 납부하는 자기부담금은 게스트가 별도로 납부)\n" +
                                "\n" +
                                "게스트는 보험처리 1건당 30만원의 위로금을 호스트에게 지급합니다. 아래의 경우 위로금이 추가로 가산됩니다.\n" +
                                "▶︎ 게스트 이용기간 6개월 이내 보험처리시 : +15만원 \n" +
                                "▶︎ 신차 차량가액 4,000만원 이상인 경우 : +20만원\n" +
                                "▶︎ 신차 차량가액 7,000만원 이상인 경우 : +30만원\n" +
                                "\n" +
                                "※ 대인/대물/자차 각 1건으로 취급됩니다. \n" +
                                "※ 이용기간과 차량가액에 따른 할증은 중복적용됩니다. \n" +
                                "※ 보험처리가 필요하지 않은 경미한 사고의 경우, 30만원의 위로금을 지급합니다. (수리비는 별도 부담) \n" +
                                "※ 차량이 전손된 경우에는 보험사에 지급하는 전손보상액의 20%를 위로금으로 지급합니다.\n" +
                                "※ 자차보험 미가입 차량의 경우, 자차 사고 시 게스트가 수리비 전액을 사비로 부담해야하며 위 기준과 동일한 금액의 위로금을 지급해야합니다. (전손 시에는 시가를 고려하여 두리카가 정하는 금액 보상)\n" +
                                "※ 사고 시 게스트의 과실이 0인 경우 위로금이 발생하지 않습니다.\n" +
                                "※ 실제 감가여부와 무관하게 지급의무가 발생하며 차량 수리 시기와 상관없이 보험접수가 확정되는 때 즉시 호스트에게 위로금을 지급해야합니다."}/>
                        </TextBox>
                    </div>
                    <div>
                        <h3 style={{marginBottom: '16px', fontSize: '16px', fontWeight: '600'}}>운행 전후 차량사진 촬영의무</h3>
                        <TextBox>
                            <ReactMarkdown children={"**호스트우선형, 요일지정형**: 차량에 원인을 파악할 수 없는 손상(ex. 휠스크래치, 문콕 등)이 발생할 경우 게스트의 책임으로 간주되며, 게스트는 본인의 마지막 운행 전후에 해당 손상이 존재하지 않았다는 사실을 입증하는 경우에 한하여 면책될 수 있습니다. 이를 위하여 게스트는 운행 시작 전과 종료 후에 차량의 사진(또는 동영상)을 촬영(각 대각선 4면에서 차량이 전체적으로 나오게)하여 보관해야하며, 이외에 다른 입증방식으로는 면책이 불가합니다. ※ 호스트는 운행 전후 촬영 의무가 없으며 게스트 면책 시 최종적으로 책임이 귀속됩니다.\n" +
                                "\n" +
                                "**게스트우선형**: 차량에 원인을 파악할 수 없는 손상(ex. 휠스크래치, 문콕 등)이 발생할 경우 호스트의 책임으로 간주되며, 호스트는 본인의 마지막 운행 전후에 해당 손상이 존재하지 않았다는 사실을 입증하는 경우에 한하여 면책될 수 있습니다. (공유기간 동안 호스트의 운행이력이 전혀 없는 경우 항상 게스트의 책임으로 귀속됨.) 이를 위하여 호스트는 운행 시작 전과 종료 후에 차량의 사진(또는 동영상)을 촬영(각 대각선 4면에서 차량이 전체적으로 나오게)하여 보관해야하며, 이외에 다른 입증방식으로는 면책이 불가합니다. ※ 게스트는 운행 전후 촬영 의무가 없으며 호스트 면책 시 최종적으로 책임이 귀속됩니다.\n" +
                                "\n" +
                                "**차량 사진으로 확인 불가한 원인불명의 손상**: 차량 하부 등 사진촬영으로 확인이 어려운 기타 손상의 경우, 호스트가 차량의 소유자로서 책임을 집니다. (매칭유형무관)"} />
                        </TextBox>
                    </div>
                    <div>
                        <h3 style={{marginBottom: '16px', fontSize: '16px', fontWeight: '600'}}>결제</h3>
                        <TextBox>
                            <ReactMarkdown children={"월이용료: 전달 25일 결제 (선불)\n" +
                                "\n" +
                                "거리당주유비, 하이패스, 유료예약권: 다음달 8일 결제 (후불)\n" +
                                "\n" +
                                "보증금: 미납금액 정산, 차량 및 차키의 반납, 1% 지분등기 원상복구가 모두 완료된 후 매월 10일에 게스트에게 반환됩니다. (계약 종료 시기 무관)\n" +
                                "\n" +
                                "게스트가 납부하는 모든 비용(하이패스 제외)은 수수료(7%) 차감 후 호스트의 월렛에 적립됩니다.  \n" +
                                "\n" +
                                "지분이전: 계약일로부터 6개월이 경과하였음에도 게스트에게 1%지분 미이전 시 호스트는 수익금을 원화로 출금할 수 없습니다. (렌터카, 리스차인 경우에는 지분이전여부 무관 원화 출금 가능)\n" +
                                "\n" +
                                "월렛에 적립된 잔액은 매월 10일에 호스트의 은행 계좌(리스차인 경우 리스비 결제계좌)로 출금됩니다. (이외 날짜 출금불가)\n" +
                                "\n" +
                                "월렛의 잔액은 최소 '1개월치 월이용료' 이상으로 항상 유지되어야합니다. 이 금액은 계약 종료 시 정산 후 호스트의 은행 계좌로 출금 처리됩니다.\n" +
                                "\n" +
                                "결제방법: 게스트는 모든 비용을 두리카의 계좌로 입금해야합니다. (카드결제 불가) 이는 회계처리기준 상 당사의 매출이 아닌 보관금으로서, 카드결제가 불가하며 현금영수증 발급대상이 아니므로 비용처리가 불가능합니다.\n" +
                                "\n" +
                                "입금계좌: 우리은행 1005-604-413896 (예금주명: 원투유니콘주식회사)"} />
                        </TextBox>
                    </div>
                    <div>
                        <h3 style={{marginBottom: '16px', fontSize: '16px', fontWeight: '600'}}>중도해지 / 위약금</h3>
                        <TextBox>
                            <ReactMarkdown children={"**게스트의 중도해지**\n" +
                                "\n" +
                                "단기계약은 중도해지가 불가능하며, 장기계약의 경우 6개월 도과 시점부터는 전달에 통지하면 위약금 없이 다음달 말일에 중도해지가 가능합니다. (ex. 24년 10월 31에 해지하려면 늦어도 24년 9월 30일 전까지 통지 필요)\n" +
                                "\n" +
                                "**호스트의 중도해지**\n" +
                                "\n" +
                                "장/단기계약 구분 없이 전달에 통지하면 위약금 없이 다음달 말일에 중도해지가 가능합니다. (ex. 24년 10월 31에 해지하려면 늦어도 24년 9월 30일 전까지 통지 필요) \n" +
                                "\n" +
                                "단, 계약기간이 아직 만료되지 않았더라도 상대방이 비매너행동, 연락두절, 결제금액 미납 등 공동사용규칙을 중대하게 위반하여 공유관계를 더 이상 지속하는 것이 바람직하지 않다고 판단되는 경우 두리카는 계약을 즉시 종료할 수 있으며 이 경우 상대방에게 위약금을 지급할 필요가 없습니다. (상대방은 위약금 지급필요) 공동사용규칙 중대위반 여부는 두리카 운영팀에서 결정하는 판단에 따라야합니다. \n" +
                                "\n" +
                                "※ 본 규정은 호스트, 게스트 모두에게 동일하게 적용됩니다.\n" +
                                "\n" +
                                "**위약금**\n" +
                                "\n" +
                                "중도해지 시 발생하는 위약금은 ‘1개월치 월이용료’입니다."} />
                        </TextBox>
                    </div>

                    <div>
                        <h3 style={{marginBottom: '16px', fontSize: '16px', fontWeight: '600'}}>이용관련 유의사항</h3>
                        <TextBox>
                            <ReactMarkdown children={"과속: 시속 160km 초과 시 게스트에게 10만원의 벌칙금이 부과됩니다. (두리플러그로 측정된 속도를 기준으로 합니다.)\n" +
                                "\n" +
                                "공회전: 공회전 10분 이상 시 분당 1km로 계산하여 거리당주유비가 부과됩니다.\n" +
                                "\n" +
                                "과태료 할증: 게스트는 과태료 부과 시 아래 기준에 따른 금액을 과태료 금액 외에 별도로 호스트에게 지급해야합니다. (주차위반, 속도위반 등 모든 종류의 과태료 포함) [1 ~ 3회차 : 패널티 없음 / 4 ~ 10회차 : 패널티 = 과태료 금액의 20% / 11회차부터 : 패널티 = 과태료 금액의 50%] ※ 년 단위로 누적계산(매년 1월 1일부터 12월 31일까지)되며 과태료 단속일을 기준으로 합니다.\n" +
                                "\n" +
                                "일상주행 외 용도 사용금지: 써킷 주행 등 차량을 일상주행 외 용도로 사용 시에는 계약이 즉시 해지되며 위약금으로 1개월 치 월이용료를 호스트에게 지급합니다."}/>
                        </TextBox>
                    </div>

                    <div>
                        <h3 style={{marginBottom: '16px', fontSize: '16px', fontWeight: '600'}}>미납 시 조치</h3>
                        <TextBox>
                            <ReactMarkdown children={"미납 시 차량반납\n" +
                                "\n" +
                                "결제기한 내에 결제금액 납부가 이뤄지지 않을 경우 차량을 그 다음날까지 호스트의 차고지로 반납해야하며, 미반납 시 하루 당 10,000원의 무단사용료가 청구됩니다.\n" +
                                "\n" +
                                "미납에 의한 자동해지\n" +
                                "\n" +
                                "누적 미납금이 ‘1개월치 월이용료’ 이상이 되는 시점부터 14일 경과 시 계약이 자동으로 해지되며, 이 경우 상대방에게 위약금을 지급해야합니다.\n" +
                                "\n" +
                                "미납 시 지연손해금\n" +
                                "\n" +
                                "결제기한 다음날부터 미납금액 완납 시까지 연 20%의 지연손해이자가 발생합니다.\n" +
                                "\n" +
                                "차량 미반납 시 조치\n" +
                                "\n" +
                                "계약이 해지되면 차량을 즉시 호스트에게 반납해야합니다. 차량 반납 지연 시 경찰에 형사고발조치 및 차량인도소송 절차가 진행되며, 관련 소송비용도 전액 청구됩니다. 아울러, 차량 정상 반납 시까지 1일당 월이용료의 20%가 청구되오니 반드시 유의하시기 바랍니다."} />
                        </TextBox>
                    </div>


                </>}
            </>:<>
                <div>
                    <h3>매칭 신청 내용</h3>
                </div>
                <FormComponent disabled id="생년월일" title="생년월일" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="성별" title="성별" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="흡연여부" title="흡연여부" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="반려동물 탑승 방법" title="반려동물 탑승방법" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="예상 운행거리" title="예상 월운행거리 (km)" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="추가운전자" title="희망 추가운전자" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled rows={8} type="textarea" id="호스트에게 한마디" title="호스트에게 한마디" form={matchingRequest?.detail} onChange={() => {}} /><FormComponent disabled id="매칭유형" title="매칭유형" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="시작 희망일" title="시작 희망일" form={matchingRequest?.detail} onChange={() => {}} />
                <FormComponent disabled id="예상 이용기간" title="종료일" form={matchingRequest?.detail} onChange={() => {}} />

                {(isAdmin(tokenInfo?.id) || matchingRequest.sender.id === tokenInfo?.id) && <div>
                    <h3 style={{marginBottom: '16px'}}>게스트 월요금 일정</h3>
                    <TextBox>
                        {getPriceTxt2()}
                    </TextBox>
                </div>}

                {(isAdmin(tokenInfo?.id) || matchingRequest.receiver.id === tokenInfo?.id) && <div>
                    <h3 style={{marginBottom: '16px'}}>호스트 월수익 일정</h3>
                    <TextBox>
                        {getPriceTxt()}
                    </TextBox>
                </div>}
            </>}

            {isAdmin(tokenInfo?.id) && <>
                <h1>어드민 참고</h1>
                <div>
                    <h3>차량 정보</h3>
                    <a href={"/vehicle/" + vehicle.id} target="_blank"><p>차량 상세페이지 열기</p></a>
                    <a href={"https://dry-river-37620.herokuapp.com/admin/content-manager/collection-types/api::vehicle.vehicle/" + vehicle.id} target="_blank"><p>스트라피 차량 열기</p></a>
                    <p>ID : {vehicle.id}</p>
                    <p>차종 : {vehicle.brand} {vehicle.model}</p>
                    <p>차량번호 : {vehicle.numberPlate}</p>
                    <p>연료타입 : {vehicle.fuelType}</p>
                </div>
                <div>
                    <h3 style={{marginBottom: '10px'}}>차량 소유 정보</h3>
                    <TextBox>
                        {matchingRequest?.contract?.ownershipInfo && Object.entries(matchingRequest?.contract?.ownershipInfo).map(([k, v]) => {
                            if (typeof(v) === 'string') {
                                return v?.includes('\n')? <>
                                    <br/>{k}<br/>{v? v: '미입력'}
                                </>: <>
                                    {k} : {v? v: '미입력'}<br/>
                                </>;
                            }
                            return <>
                                {k} : {v? v: '미입력'}<br/>
                            </>;
                            }
                        )}
                        {!matchingRequest?.contract?.ownershipInfo && "미입력"}
                    </TextBox>
                </div>
                <div>
                    <h3>호스트 정보</h3>
                    <a href={"https://dry-river-37620.herokuapp.com/admin/content-manager/collection-types/plugin::users-permissions.user/" + matchingRequest.receiver.id} target="_blank"><p>스트라피 호스트 열기</p></a>
                    <a href={"https://dry-river-37620.herokuapp.com/admin/content-manager/collection-types/api::contract.contract/" + matchingRequest.contract.id} target="_blank"><p>스트라피 호스트 계약 열기</p></a>
                    <p>ID : {matchingRequest?.receiver?.id}</p>
                    <p>이름 : {matchingRequest?.receiver?.realname}</p>
                    <p>닉네임 : {matchingRequest?.receiver?.nickname}</p>
                    <p>전화번호 : {matchingRequest?.receiver?.phone}</p>
                    <p>이메일 : {matchingRequest?.receiver?.email2}</p>
                    <p>주민등록번호 : {matchingRequest?.receiver?.residenceNumber}</p>
                    <p>거주지주소 : {matchingRequest?.receiver?.address}</p>
                    <p>계좌번호 : {matchingRequest?.receiver?.accountNumber}</p>
                    {matchingRequest?.receiver?.licenseCard?.url ? <a href={matchingRequest?.receiver?.licenseCard?.url} target="_blank"><p>면허증 사진 보기</p></a>:
                        <p>면허증 사진 없음</p>}
                </div>
                <div>
                    <h3>게스트 정보</h3>
                    <a href={"https://dry-river-37620.herokuapp.com/admin/content-manager/collection-types/plugin::users-permissions.user/" + matchingRequest.sender.id} target="_blank"><p>스트라피 게스트 열기</p></a>
                    <p>ID : {matchingRequest?.sender?.id}</p>
                    <p>이름 : {matchingRequest?.sender?.realname}</p>
                    <p>닉네임 : {matchingRequest?.sender?.nickname}</p>
                    <p>전화번호 : {matchingRequest?.sender?.phone}</p>
                    <p>이메일 : {matchingRequest?.sender?.email2}</p>
                    <p>주민등록번호 : {matchingRequest?.sender?.residenceNumber}</p>
                    <p>거주지주소 : {matchingRequest?.sender?.address}</p>
                    <p>계좌번호 : {matchingRequest?.sender?.accountNumber}</p>
                    {matchingRequest?.sender?.licenseCard?.url ? <a href={matchingRequest?.sender?.licenseCard?.url} target="_blank"><p>면허증 사진 보기</p></a>:
                        <p>면허증 사진 없음</p>}
                </div>
                <div>
                    <a href={"/admin/manual_match"} target="_blank"><p>스트라피 게스트 계약 생성 페이지</p></a>
                    <p>※ 입금계좌: 우리은행 1005-604-413896 (예금주명: 원투유니콘 주식회사)</p>
                </div>
            </>}
            {matchingRequest?.receiver?.id === tokenInfo?.id && matchingRequest?.status === 'NONE' && <PositiveBtn onClick={submit}>수락하기</PositiveBtn>}
            {matchingRequest?.receiver?.id === tokenInfo?.id && matchingRequest?.status === 'HOLDING' && <PositiveBtn onClick={submit}>수락하기</PositiveBtn>}
            {matchingRequest.status === 'DECLINE' && <DisabledBtn>매칭 거절됨</DisabledBtn>}
            {matchingRequest.status === 'ACCEPT' && <DisabledBtn>매칭 수락됨</DisabledBtn>}
            {matchingRequest.status === 'TIMEOUT' && <DisabledBtn>매칭 거절됨 (시간초과)</DisabledBtn>}
            {/*{(matchingRequest?.receiver?.id === tokenInfo.id) && <PositiveBtn onClick={submit}>수락하기</PositiveBtn>}*/}
        {/*{page === 0 && <UserInfo form={form} onChange={onChange} />}*/}
        {/*{page === 1 && <MatchInfo form={form} onChange={onChange} vehicle={vehicle} />}*/}
        {/*{page === 2 && <ToHost form={form} onChange={onChange} />}*/}
        {/*<BottomNavigator page={page}*/}
        {/*                 onBefore={() => setPage((p) => p-1)}*/}
        {/*                 onNext={() => {*/}
        {/*                     setPage((p) => p+1);*/}
        {/*                 }}*/}
        {/*                 lastPage={2}*/}
        {/*                 onSubmit={save} />*/}
        {/*<LoadingIndicator isVisible={loading || !user} />*/}
        </div>
    </Wrap>
}

export default MatchingViewPage;

const Wrap = styled.div`
  padding: 20px 20px 40px;
  > h1 {
    padding: 32px 0;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  > .forms {
    display: flex;
    flex-direction: column;
    gap: 32px;

    > h3 {
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      margin: 4px 0;
    }

    p {
      color: var(--gray-scale-gray-700, #939393);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin: 4px 0;
    }
  }
`

const TextBox = styled.div`
  padding: 16px 12px;
  border-radius: 8px;
  background: var(--gray-scale-gray-200, #F5F5F5);

  color: var(--gray-scale-gray-700, #939393);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  white-space: pre-wrap;
`
